import {Component, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild} from '@angular/core';
import {Thumbnail} from '../../../models/thumbnail';
import {EventService} from '../../../core/services/event.service';
import {MediaFileItemComponent} from '../media-files-gallery/media-file-item/media-file-item.component';

@Component({
  selector: 'app-static-slider',
  templateUrl: './static-slider.component.html',
  styleUrls: ['./static-slider.component.scss']
})
export class StaticSliderComponent implements OnInit {

  /*isDown = false;
  startX: number;
  scrollLeft: any;*/
  private slidesIndex = 0;

  // @ViewChild('slider', { static: true }) slider: ElementRef;
  @ContentChildren(MediaFileItemComponent, { read: ElementRef }) items: QueryList<ElementRef<HTMLDivElement>>;
  @ViewChild('slider', { static: true }) slidesContainer: ElementRef<HTMLDivElement>;

  @Input() images: Thumbnail[];

  @Output() arrivedToEnd = new EventEmitter();

  constructor(private eventService: EventService) {}

  get currentItem(): ElementRef<HTMLDivElement> {
    return this.items.find((item, index) => index === this.slidesIndex);
  }

  ngOnInit(): void {
    this.eventService.on('previousSlider', () => {
      this.slidesContainer.nativeElement.scrollLeft -= this.currentItem.nativeElement.offsetWidth;

      if (this.slidesIndex > 0) {
        this.slidesIndex--;
      }
    });

    this.eventService.on('nextSlider', () => {
      this.slidesContainer.nativeElement.scrollLeft += this.currentItem.nativeElement.offsetWidth;

      if (this.slidesIndex < this.items.length - 1) {
        this.slidesIndex++;
      }
    });
  }
}

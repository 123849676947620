export class Pageable {
    public page?: number;
    public size?: number;
    public sortColumn?: string;
    public order?: number;
    private simpleView?: boolean;

    public setAll?(): Pageable {
        this.size = 10000000;
        return this;
    }

    public setSimpleView?(): Pageable {
        this.simpleView = true;
        return this;
    }

}

  <div class="home-new-project-container">
    <div class="buttons-container">
      <div class="button" routerLink="/new-project/new">
        <img src="/assets/images/new-project/new-project-pc.svg" *ngIf="!isSmallSize">
        <div class="text-box">
          <p class="first">{{'new' | i18n}}</p>
          <p class="second">{{'new2' | i18n}}</p>
        </div>
      </div>
      <div class="button disable" (click)="createRevaluationDialog()">
        <img src="/assets/images/new-project/revaluation-pc.svg" *ngIf="!isSmallSize">
        <div class="text-box">
          <p class="first">{{'revaluation' | i18n}}</p>
          <p class="second">{{'revaluation2' | i18n}}</p>
        </div>
      </div>
      <div class="button button-send disable-send" routerLink="/new-project/load">
        <img src="/assets/images/new-project/send-link-orange.svg" *ngIf="!isSmallSize">
        <div class="text-box">
          <p class="first">{{'sendLink' | i18n}}</p>
          <p class="second">{{'sendLink2' | i18n}}</p>
        </div>
      </div>
    </div>
    <!--<div class="button" (click)="openVerifyingInfoDialog()">
      <p>Verificando info</p>
    </div> --->
    <!-- <div class="ads-container">
      <ng-adsense style="overflow-x: hidden;"
                  [adClient]="'ca-pub-4671591524487127'"
                  [adSlot]="5488837076"
                  [display]="'inline-block'"
                  [width]="728"
                  [height]="90"
                  adFormat="bar">
      </ng-adsense>
    </div> -->
    <div class="home-gallery-container">
      <div class="home-gallery-subtitle-container">
        <p class="home-gallery-title" *ngIf="!isSmallSize">{{'myProjects' | i18n}}</p>
        <p class="home-gallery-title" *ngIf="isSmallSize">{{'projects' | i18n}}</p>
        <a class="home-gallery-subtitle-show-all" routerLink="/gallery">
          {{'showAll' | i18n}}
          <img class="d-sm-flex" src="/assets/images/home/button-show-all-icon-1920x960.svg">
        </a>
      </div>
      <div class = "home-gallery">
        <app-gallery-slider [images]="sliderImages"></app-gallery-slider>
      </div>
      <div class = "no-projects-container" *ngIf="sliderImages && sliderImages.length === 0">
        <img src="/assets/images/home/no-projects.svg" alt="no projects">
        <div class="vertical-line"></div>
        <div class="no-projects-text-container">
          <p class="main-text">{{'noProjectsMainText' | i18n}}</p>
          <p class="secondary-text">{{'noProjectsSecondaryText' | i18n}}</p>
        </div>
      </div>
    </div>
  </div>

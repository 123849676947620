<footer>
  <div id="footer">
    <div class="footer-actions-container">
      <a class="footer-action" routerLink="/home" routerLinkActive="active">
        <img class="image-non-active" src="/assets/images/navbar/footer-action-home.png">
        <img class="image-active" src="/assets/images/navbar/footer-action-home-activated.png">
        <p class="footer-action-text">{{'home' | i18n}}</p>
      </a>
      <a class="footer-action" routerLink="/new3d" routerLinkActive="active">
        <img class="image-non-active" src="/assets/images/navbar/footer-action-new3d.png">
        <img class="image-active" src="/assets/images/navbar/footer-action-new3d-activated.png">
        <p class="footer-action-text">{{'new3d' | i18n}}</p>
      </a>
      <a class="footer-action" routerLink="/gallery" routerLinkActive="active">
        <img class="image-non-active" src="/assets/images/navbar/footer-action-gallery.png">
        <img class="image-active" src="/assets/images/navbar/footer-action-gallery-activated.png">
        <p class="footer-action-text">{{'gallery' | i18n}}</p>
      </a>
      <a class="footer-action" routerLink="/profile/mobile-profile-menu" routerLinkActive="active">
        <img class="image-non-active" src="/assets/images/navbar/footer-action-account.png">
        <p class="footer-action-text">{{'profile' | i18n}}</p>
      </a>
    </div>
    <div class="none sm-none md-flex white-footer">
      <div class="logos">
        <img class="footer-logo none lg-flex" src="assets/images/footer/footer_an_idea_by_ecapture3d.svg">
      </div>
      <div class="white-footer-right-side footer-descarga-social">
        <div class="lg-flex app-links div-descarga-social-footer">
          <p class="ocultar">{{'downloadApp' | i18n}}:</p>
          <div class="div-descargar-para-movil">
            <!-- <a href="https://apps.apple.com/es/app/eyescloud3d/id1479032117" class="mr-1" target="__blank"><img
              src="assets/images/footer/app-store.png"></a> -->
            <a href="https://play.google.com/store/apps/details?id=com.ecapture3d.eyescloud3d" target="__blank"><img
              src="assets/images/footer/google-play.png"></a>
          </div>
        </div>
        <div class="social-networks div-descarga-social-footer">
          <p class="d-none d-lg-flex">{{'followUsIn' | i18n}}:</p>
          <a href="https://www.facebook.com/ecaptureDtech"><img src="assets/images/footer/facebook-social-network.svg"></a>
          <a href="https://twitter.com/ecaptureDtech"><img src="assets/images/footer/twitter-social-network.svg"></a>
          <a href="https://www.linkedin.com/company/ecapturedtech"><img src="assets/images/footer/linkedin-social-network.svg"></a>
          <!-- <a href="https://www.instagram.com/eyesnroad/?hl=es"><img src="assets/images/footer/instagram-social-network.svg"></a> -->
        </div>
        <app-language-selector [footer]=true class="language-selector"></app-language-selector>
      </div>

    </div>

    <!-- <div class="opciones"> -->

    <div class="none sm-none md-flex blue-footer footer-terminos-uso">
      <p class="footer-text">© {{year}} {{'footerText' | i18n}}.</p>
      <div class="footer-links-container">
        <a routerLink="/terms-of-use">{{'termsOfUse' | i18n}}</a>
        <div class="footer-divider"></div>
        <a routerLink="/privacy-policy">{{'privacyPolicy' | i18n}}</a>
        <div class="footer-divider"></div>
        <a routerLink="/cookie-policy">{{'cookiePolicies' | i18n}}</a>
      </div>

    </div>
    <!-- </div> -->
  </div>
</footer>


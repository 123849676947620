import * as moment_ from "moment";
import {Injectable} from "@angular/core";
const moment = moment_;

@Injectable({
    providedIn: "root",
})
export class DateService {
    public static currentDate(): Date {
        return moment().toDate();
    }

    public static dateIsPresentInRange(date: Date, startDate: Date, endDate: Date): boolean {
        return DateService.dateIsSameOrAfter(date, startDate) && DateService.dateIsSameOrBefore(date, endDate);
    }

    public static getIsoStringDatePart(date: Date): string {
        return date.toISOString().slice(0, 10);
    }

    public static getIsoString(date: Date): string {
        return date.toISOString();
    }

    public static getDateFromIsoString(dateIsoString: string | Date): Date {
        return moment(dateIsoString, "YYYY-MM-DD").toDate();
    }

    public static cloneDate(date: Date): Date {
        return moment(date).toDate();
    }

    public static dateIsBefore(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService.getIsoStringDatePart(DateService.getDate(date1))).isBefore(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    public static datetimeIsBefore(date1: Date|string, date2: Date|string): boolean {
        return moment(date1).isBefore(date2);
    }

    public static dateIsSameOrBefore(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService.getIsoStringDatePart(DateService.getDate(date1))).isSameOrBefore(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    public static dateIsAfter(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService.getIsoStringDatePart(DateService.getDate(date1))).isAfter(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    public static dateIsSameOrAfter(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService.getIsoStringDatePart(DateService.getDate(date1))).isSameOrAfter(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    public static dateIsSame(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService.getIsoStringDatePart(DateService.getDate(date1))).isSame(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    public static addDays(date: Date, days: number): Date {
        return moment(date).add(days, "days").toDate();
    }

    public static addMilliseconds(date: Date, milliseconds: number): Date {
        return moment(date).add(milliseconds, "milliseconds").toDate();
    }

    public static subtractDays(date: Date, days: number): Date {
        return moment(date).subtract(days, "days").toDate();
    }

    public static addMonths(date: Date, months: number): Date {
        return moment(date).add(months, "month").toDate();
    }

    public static formatDate(date: Date|string, format: string = "DD-MM-YYYY", locale: string = "en"): string {
        return moment(date).locale(locale).format(format);
    }

    public static getTimeAgoStringFromDate(date: Date, locale: string): string {
        return moment(date).locale(locale).fromNow();
    }

    public static getFullDateStringFromDate(date: Date, locale: string): string {
        return moment(date).toDate().toLocaleString(locale);
    }

    public formatDateTime(date: Date| string, format: string = "YYYY-MM-DD HH:mm:ss"): string {
        return DateService.formatDate(typeof date === "string" ? new Date(date) : date, format);
    }

    public static nightsBetweenDates(startDate: Date, endDate: Date): number {
        return moment(endDate).diff(moment(startDate), "days");
    }

    public static getFirstDateOfMonthIsoString(): Date {
        return moment().startOf("month").toDate();
    }

    public static getEndDateOfMonthIsoString(): Date {
        return moment().endOf("month").toDate();
    }

    public static getDate(date: Date| string): Date {
        return typeof date === "string" ? new Date(date) : date;
    }
}

import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {NgModel} from '@angular/forms';
import {PatternConfig} from './pattern-config';

@Directive({
    selector: '[appRestrictedPattern]',
    providers: [NgModel],
})
export class RestrictedPatternDirective {
    @Input()
    public restrictedPattern: PatternConfig;

    constructor(private el: ElementRef, public model: NgModel) {}

    @HostListener('keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent) {
        if (this.restrictedPattern) {
            if ([46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
                    // Allow: Ctrl+A
                    (event.keyCode === 65 && event.ctrlKey === true) ||
                    // Allow: Ctrl+C
                    (event.keyCode === 67 && event.ctrlKey === true) ||
                    // Allow: Ctrl+V
                    (event.keyCode === 86 && event.ctrlKey === true) ||
                    // Allow: Ctrl+X
                    (event.keyCode === 88 && event.ctrlKey === true) ||
                    // Allow: home, end, left, right
                    (event.keyCode >= 35 && event.keyCode <= 39)) {
                // let it happen, don't do anything
                return;
            }

            const ch = event.key;
            const regEx = new RegExp(this.restrictedPattern.getPattern());
            const valueToTest = this.model.value ? this.model.value.toString() + ch : ch;
            if (regEx.test(valueToTest) === false) {
                event.preventDefault();
            }
        }
    }
}

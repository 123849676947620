import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
// tslint:disable: max-line-length
export class HelpComponent implements OnInit {

  public options = ['Novedades', 'FAQ', 'Guía de uso', 'Consejos de captura', 'Ayuda', 'Actualizaciones'];
  public faqs = [{
    question: '¿Qué dispositivos de captura puedo usar para tomar fotos?',
    answer: 'Puede usar cualquier dispositivo, desde una cámara réflex a la cámara de un teléfono móvil. El único requisito es usar una lente estándar. Actualmente, la plataforma aún no admite imágenes ojo de pez o gran angular, estamos trabajando para incorporarlas en el futuro. También necesita usar imágenes JPG.'
  }, {
    question: '¿Cómo deben tomarse las imágenes?',
    answer: 'El objeto a capturar no debe estar en movimiento y las imágenes deben estar nítidas, con el objeto centrado en la imagen y con una superposición igual o mayor al 66% entre las imágenes (la misma parte del objeto debe mostrarse al menos 3 imágenes). Siempre debe haber un cambio entre las capturas. Tomar dos imágenes del mismo punto puede causar que el modelo no se genere. Para ver más información sobre cómo capturar fotos, visite Capture Tips.'
  }, {
    question: '¿Cómo generar un modelo 3D?',
    answer: `Para generar un nuevo modelo 3D, siga estos pasos:

    Haga clic en Nueva 3D en la barra de navegación superior.
    Ingrese el nombre y tipo de modelo o elija la categoría. Seleccione el tipo de modelo (normal, cara) y la calidad (estándar o premium).
    Haga clic en el cuadro de inserción de imágenes, seleccione las imágenes y haga clic en el botón Siguiente para obtener un informe sobre su proyecto y método de pago.
    Finalmente, haga clic en Continuar y espere la notificación por correo electrónico.
    `
  }, {
    question: '¿Qué opciones se usan por defecto?',
    answer: "Por defecto, los modelos se generarán en calidad 'estándar' y con el tipo de modelo 'normal'."
  }, {
    question: '¿Qué tipo de resultados se obtienen?',
    answer: `Se obtienen tres tipos de resultados:

    La nube de puntos del modelo 3D en formato PLY binario.
    El modelo de malla texturizada con textura basada en el color de la nube de puntos.
    El modelo de malla texturizada con textura basada en fotografías (mayor calidad de textura).`
  }, {
    question: '¿Cómo puedo reclamar un modelo 3D cuando no estoy satisfecho con el resultado?',
    answer: 'Para esa propuesta, puede usar el enlace disponible en la página del modelo 3D. Cuando haces un reclamo, se abre un espacio para tu clame para seguir su estado. Verificaremos su clama en nuestro departamento de soporte y le responderemos menos de 48 hous. Si el problema de su reclamo es responsabilidad de eCapture3D, le daremos el mismo crédito para crear un nuevo modelo 3D.'
  }, {
    question: '¿Pueden los resultados ser compartidos?',
    answer: "Puede compartir sus modelos 3D con su URL ya sea como un enlace único o a través de diferentes redes sociales (Facebook, Whatsapp, LinkedIn, Twitter y Google+). Just activate the 'public' option within the options of the 3D model. Puede activar la opción 'pública' en modelos 3D y todos los usuarios de la plataforma pueden verlos. Los usuarios de la plataforma Othes no pueden descargar sus modelos 3D. Incluso, puede vender su modelo 3D incluidos sus modelos en nuestra base de datos modelo 3D. Usted tiene un beneficio de su modelo 3D del 70% de todas las ventas obtenidas por plataforma."
  }, {
    question: '¿Cuál es el sistema de coordenadas de salida?',
    answer: 'Los modelos se generan en un sistema de coordenadas relativo. Normalmente, el punto de origen del sistema de coordenadas (0, 0, 0) estará ubicado en uno de los puntos de captura. Si conoce las coordenadas absolutas de su modelo, puede usar cualquier software de edición de modelos 3D para realizar la georreferenciación del modelo descargado.'
  }, {
    question: '¿Cómo se puede escalar el modelo?',
    answer: 'Para realizar la escala directamente en el proceso de generación del modelo 3D, es necesario utilizar un objetivo (descargar el objetivo aquí). Debe imprimirse en dimensiones reales y colocarse al lado del objeto que se va a fotografiar, para que aparezca en las imágenes. El modelo resultante se escala automáticamente. Otra opción es generar el modelo y luego escalarlo con cualquier software de edición de modelos 3D utilizando una distancia real conocida.'
  }, {
    question: '¿Qué significa establecer mi modelo como "Visible"?',
    answer: 'Si está utilizando una cuenta proporcionada por su empresa, marcar el modelo como visible hará que tanto el jefe del departamento con el que está asociada la cuenta como el administrador de la compañía lean y editen el acceso al modelo.'
  }, {
    question: '¿Qué significa configurar mi modelo "A la venta"?',
    answer: 'Un modelo con la opción de venta activada puede ser vendido por eCapture3D en nuestra base de datos modelo 3D. El usuario fijará el precio final del modelo y obtendrá el 70% del mismo. El eCapture3D descontará el 30% restante para la comisión de administración y ventas.'
  }];

  public news = [`<h2> Novedades Agosto 2019 </h2>
  <br>
  <p style="font-size: 20px;"><b> Nueva herramienta de unión</b></p>
  <p> eyesCloud3D lanza una nueva herramienta con la que podrás unir varios modelos 3D. </p>
  <p> Simplemente abra el modelo que desea añadir, seleccione la herramienta de unión y seleccione 3 puntos de unión. </p>
  <p> El siguiente paso es abrir el modelo al que quieres unir el anterior y, utilizando la misma herramienta, seleccionar de nuevo 3 puntos de unión. </p>
  <p> eyesCloud3D unirá los modelos a través de las coincidencias entre los puntos seleccionados. </p>
  <img class="img-responsive" src="assets/images/help/join.png"><br>
  <br><br>
  <p style="font-size: 20px;"><b> Descarga tus modelos 3D recortados</b></p>
  <p> Se ha mejorado la herramienta de mapa de elevación en malla. <br> Si se realizan recortes en los modelos, se ajustará el mapa de elevación para asociar los límites correctamente. </p>
  <img class="img-responsive" src="assets/images/help/elevacion2.png"><br>
  <br><br>
  <p style="font-size: 20px;"><b> Nuevo portfolio</b></p>
  <p> The eyesCloud3D portfolio has been modified to adapt it to the new global design of the platform. You can access it by clicking on the first icon in the navigation bar. </p>
  <p> Podrás ver un total de 15 modelos seleccionados previamente para que puedas ver las infinitas posibilidades que ofrecemos. </p>
  <img class="img-responsive" src="assets/images/help/portfolio.png"><br>
  <br><br>`,
    `<h2> Novedades Junio 2019 </h2>
  <br>
  <p style="font-size: 20px;"><b> Nueva herramienta tour virtual </b></p>
  <p> Hemos añadido una nueva funcionalidad a la plataforma. El tour virtual te permite seleccionar posiciones del modelo y generar un recorrido virtual con animaciones automáticas. </p>
  <img class="img-responsive" src="assets/images/help/tour.png"><br>
  <p> El funcionamiento es muy sencillo: elige las posiciones deseadas que tendrá tu modelo, elige la velocidad y el tiempo de espera hasta comenzar el siguiente movimiento. Dale al play y disfruta de tu tour virtual. </p>
  <br>
  <p style="font-size: 20px;"><b> Herramienta mapa de elevación para malla </b></p>
  <p> El mapa de elevación se añade al visualizador de malla. Esta herramienta te permite conocer los puntos más altos y mas bajos de tus modelos gracias al sistema de colores. </p>
  <img class="img-responsive" src="assets/images/help/elevacion.png"><br>
  <p> Para un correcto funcionamiento de esta herramienta, recomendamos geolocalizar el modelo 3D con anterioridad. </p>`,
    'El resto de items'];
  public selectedOption = 0;
  counter = Array;

  constructor() {
  }

  ngOnInit() {
  }


  numberReturn(length) {
    return new Array(length);
  }
}

import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import { Subscription } from 'rxjs';
import { Record } from '../../models/records/record';

@Component({
  selector: 'app-current-project-processing',
  templateUrl: './current-project-processing.component.html',
  styleUrls: ['./current-project-processing.component.scss']
})

export class CurrentProjectProcessingComponent implements OnChanges, OnDestroy {

  subscriptionDecreaseSeconds: Subscription = null;
  timeStamp;
  src = '';
  avatarSrc = '';

  @Input() record: Record;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('record')) {
      this.timeStamp = (new Date()).getTime();
      this.avatarSrc = this.record.avatarURL;
      this.src = this.record.previewURL;
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptionDecreaseSeconds) {
      this.subscriptionDecreaseSeconds.unsubscribe();
    }
  }

  setDefaultImage(event) {
    event.target.src = '/assets/images/blue-background.png';
  }

  setDefaultAvatar(event) {
    event.target.src = '/assets/images/gallery-item-default-avatar.png';
  }

}

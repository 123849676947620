<div class="damage-assessment">
    <p class="rate">{{'damage' | i18n}}</p>
    <div class="hits">
        <!-- <p class="tittle-damage"><span>{{'selector_daños_data1' | i18n}}</span><span>{{'selector_daños_data2' |
                i18n}}</span><span>{{'selector_daños_data3' | i18n}}</span></p>
        <div class="separator line2"></div>

        <div class="damage-info" *ngFor="let damage of damages; let i = index">
            <div class="damage-line">
                <label (click)="activateDamage(i)" class="check-damage"><input type="checkbox" id="cbox1"
                        value="first_checkbox"></label>
                <p class="damage-item">{{damage.part_name}}{{'   '}}{{ parserCarDamage(damage.damage_name)}}<a
                        class="damage-price">{{roundNumber(damage.surface) }}{{'mm'}}<sup>2</sup></a></p>
            </div> -->

        <table mat-table [dataSource]="damages?.damages">

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleCheckbox(row, $event)"
                [checked]="isChecked(row.id)"></mat-checkbox>
            </td>
          </ng-container>

            <ng-container matColumnDef="part">
              <th mat-header-cell *matHeaderCellDef> {{'selector_daños_data1' | i18n}} </th>
              <td mat-cell *matCellDef="let element"> {{element.part_name | i18n}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="damage-type">
                <th mat-header-cell *matHeaderCellDef>{{'selector_daños_data2' | i18n}}</th>
                <td mat-cell *matCellDef="let element"> {{element.damage_name | i18n}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="surface">
                <th mat-header-cell *matHeaderCellDef>{{'selector_daños_data6' | i18n}} </th>
                <td mat-cell *matCellDef="let element"> {{element.prices.totalPrice}}{{' €'}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row (click)="activateDamage(row)" [class.row-is-clicked]="damageActive.has(row)"
                *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <div class="separator line1"></div>
    <div class="total-cost">{{ 'selector_daños_data5' | i18n }}{{ totalCost }}€</div>
    <p class="damage-frame">{{'damageFrames' | i18n}}</p>
    <div class="slider-container">
        <img class="arrow-rigt arrow" src="/assets/images/viewproject/scroll-left.svg" (click)="moveLeft()">
        <div class="sliders" #slider id="sliders" (mousedown)="mouseDown($event)" (mouseup)="mouseUp($event)"
            (mousemove)="mouseMove($event)">
            <img id="damage" *ngFor="let frame of showFrames" src={{frame}} (click)="openFile(frame)">
        </div>
        <img class="arrow" src="/assets/images/viewproject/scroll-left.svg" (click)="moveRight()">
    </div>
</div>

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  constructor() {}

  ngOnInit() {
    (window as any).hsConversationsSettings = {
      loadImmediately: true,
      inlineEmbedSelector: '',
      enableWidgetCookieBanner: false,
      disableAttachment: false
    };
  }
}

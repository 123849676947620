import { Component } from '@angular/core';
import { EventData } from 'src/app/core/models/event-data';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-viewproject',
  templateUrl: './viewproject.component.html',
  styleUrls: ['./viewproject.component.scss']
})

export class ViewprojectComponent {
  constructor(private eventService: EventService) { }

  highlightDamage() {
    console.log('Resaltando daño...');
    this.eventService.emit(new EventData('highlightCarDamage', null));
  }

}


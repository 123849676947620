<div class="profile-container">
  <div class="profile-menu-container" *ngIf="show">
    <div class="user-photo-container">
      <div class="user-photo">
        <img [src]="avatarUrl" />
      </div>
      <div class="user-photo-edit-button" (click)="avatarInput.click()">
        <img class="d-none d-lg-block" src="/assets/images/profile/edit-photo-ico.png">
        <img class="d-none d-md-block d-lg-none" src="/assets/images/profile/tablet-edit-photo-ico.png">
        <input type="file" #avatarInput hidden (change)="onUploadAvatarChanged($event)" onclick="this.value = null">
      </div>
    </div>
    <div class="user-info-profile">
      <p class="name" *ngIf="user">{{user.department_id ? user.company.name : user.profile.name}}</p>
      <p class="type">{{userType}}</p>
      <div class="line"></div>
      <div class="stadistics">
        <div class="stadistics-columns">
          <p class="stadistics-title">{{'records' | i18n}}</p>
          <p class="stadistics-value" *ngIf="user">{{countProjects}}</p>
        </div>
        <div class="stadistics-columns">
          <p class="stadistics-title downloads-stadistics-title">{{'downloads' | i18n}}</p>
          <p class="stadistics-value downloads-stadistics-value">0</p>
        </div>
      </div>
    </div>
    <div class="profile-menu-button" routerLink="/profile/personal-data" routerLinkActive="active" (click)="refreshPersonalData()">
      <img *ngIf="profileOption !== 2" class="personal-data-ico none d-lg-block" src="/assets/images/profile/personal-data-ico.png">
      <img *ngIf="profileOption === 2" class="personal-data-ico none d-lg-block" src="/assets/images/profile/personal-data-ico-white.png">
      <img class="personal-data-ico d-none d-md-block d-lg-none"
        src="/assets/images/profile/tablet-personal-data-ico.png">
      <p>{{'userData' | i18n}}</p>
    </div>
    <div class="profile-menu-button" routerLink="/profile/change-password" routerLinkActive="active">
      <img *ngIf="profileOption !== 3" class="password-ico d-none d-lg-block" src="/assets/images/profile/password-ico.png">
      <img *ngIf="profileOption === 3" class="password-ico d-none d-lg-block" src="/assets/images/profile/password-ico-white.svg">
      <p>{{'password' | i18n}}</p>
    </div>
    <div class="profile-menu-button" routerLink="/profile/company-data"
      *ngIf="user && user.department_id && user.role_id == 2" routerLinkActive="active">
      <img class="company-data-ico d-none d-lg-block" src="/assets/images/profile/company-data-ico.png">
      <img class="company-data-ico d-none d-md-block d-lg-none"
        src="/assets/images/profile/tablet-company-data-ico.png">
      <p>{{ 'companyDataText' | i18n }}</p>
    </div>
    <div class="profile-menu-button" *ngIf="user && user.department_id && user.role_id == 2"
      routerLink="/profile/company-control-panel" routerLinkActive="active">
      <img class="control-panel-ico d-none d-lg-block" src="/assets/images/profile/control-panel-ico.png">
      <img class="control-panel-ico d-none d-md-block d-lg-none"
        src="/assets/images/profile/tablet-control-panel-ico.png">
      <p>{{'controlPanel' | i18n}}</p>
    </div>
  </div>
  <div class="profile-content-container">
    <router-outlet></router-outlet>
  </div>
  <!-- <div class="profile-help-container" *ngIf="show">
    <p>{{'help' | i18n}}</p>
    <div class="profile-help-content-container">
      <div class="news-container">
        <div class="news-header-container">
          <p class="news-title">{{'news' | i18n}}</p>
        </div>
        <div class="news-text">
          <p>{{'newsText' | i18n}}</p>
          <p>{{'noNews' | i18n}}</p>
        </div>
        <div class="all-news">
          <p></p>
        </div>
      </div>
      <div class="help-buttons-container">
        <div class="help-button disable" (click)="goToTips()">
          <p>{{'captureTips' | i18n}}</p>
          <img class="d-none d-lg-block" src="/assets/images/profile/arrow-ico.png">
          <img class="d-none d-md-block d-lg-none" src="/assets/images/profile/tablet-arrow-ico.png">
        </div>
        <div class="help-button disable" (click)="goToFaqs()">
          <p>FAQ</p>
          <img class="d-none d-lg-block" src="/assets/images/profile/arrow-ico.png">
          <img class="d-none d-md-block d-lg-none" src="/assets/images/profile/tablet-arrow-ico.png">
        </div>
        <div class="help-button disable" (click)="goToCustomerSupport()">
          <p>{{'customerService' | i18n}}</p>
          <img class="d-none d-lg-block" src="/assets/images/profile/arrow-ico.png">
          <img class="d-none d-md-block d-lg-none" src="/assets/images/profile/tablet-arrow-ico.png">
        </div>
        <div class="help-center">
          <p href="">{{'helpCenter' | i18n}}</p>
        </div>

      </div>
    </div>
  </div> -->
</div>

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {LoaderService} from '../../../../core/services/loader.service';

@Component({
  selector: 'app-upload-user-avatar-dialog',
  templateUrl: './upload-user-avatar-dialog.component.html',
  styleUrls: ['./upload-user-avatar-dialog.component.scss']
})
export class UploadUserAvatarDialogComponent {

  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(public dialogRef: MatDialogRef<UploadUserAvatarDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private loaderService: LoaderService) {
    this.imageChangedEvent = data.file;
    loaderService.showLoader();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
  }
  imageLoaded() {
    this.loaderService.hideLoader();
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}

import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpCancelService} from './http-cancel-service.service';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpCancelInterceptorService {

  constructor(private httpCancelService: HttpCancelService) { }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
  }
}

import {Pageable} from '../pageable';

export class IncidenceSearchCriteria extends Pageable {
    public id?: number;
    public model_id?: number;
    public open_date?: Date;
    public close_date?: Date;
    public user_id?: number;
    public content?: string;
}

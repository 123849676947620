import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Record } from '../../../models/records/record';
import { ProjectThumbnail } from '../../../models/project-thumbnail';
import { clone } from 'lodash';

@Component({
  selector: 'app-gallery-grid',
  templateUrl: './gallery-grid.component.html',
  styleUrls: ['./gallery-grid.component.scss']
})
export class GalleryGridComponent implements OnInit, OnChanges {

  constructor(private router: Router) { }

  searchValue = '';
  images: ProjectThumbnail[] = [];
  searchImages: ProjectThumbnail[] = [];
  searching = false;
  @Input() projects: Record[] = [];
  @Input() showSearch = false;
  @Input() showGoogleAdsense = false;
  @Input() gridItemPercent: number;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('projects') && !changes.projects.isFirstChange()) {
      let projectsToShow = [];
      if (this.projects.length > 32) {
        projectsToShow = this.projects.slice(0, 32);
      } else {
        projectsToShow = clone(this.projects);
      }
      projectsToShow.forEach(sliderProject => {
        if (sliderProject) {
          this.images.push({
            name: sliderProject.name,
            src: sliderProject.url,
            project: sliderProject
          });
        }
      });
      console.log(this.projects);
      console.log(this.images);


    }
  }

  onSliderClick(code: string) {
    this.router.navigate(['/projects', code]);
  }

  filterChange(value: string) {
    if (value !== '') {
      this.searching = true;
      this.searchValue = value.toLowerCase();
      console.log(this.searchValue);

      let searchProjects = clone(this.projects);
      searchProjects = searchProjects.filter(project => project.name.toString().includes(value.toLowerCase()));
      this.searchImages = [];

      if (searchProjects.length > 0) {
        searchProjects.forEach(searchProject => {
          this.searchImages.push({
            name: searchProject.name.toString(),
            src: searchProject.videoURL,
            project: searchProject
          });
          console.log(this.searchImages);

        });
      }
    } else {
      this.closeSearch();
    }

  }

  closeSearch() {
    this.searchValue = '';
    this.searchImages = [];
    this.searching = false;
  }
}

import { Component, Input } from '@angular/core';
import { Record } from '../../../../app/models/records/record';

@Component({
  selector: 'app-project-progress-bar',
  templateUrl: './project-progress-bar.component.html',
  styleUrls: ['./project-progress-bar.component.scss']
})
export class ProjectProgressBarComponent {

  @Input() record: Record;
  @Input() height: number;
  @Input() percent: string;
  completion = 0;

  constructor() {
  }
}

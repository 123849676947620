<div class="gallery-container">
  <div class="search-container" *ngIf="showSearch" [ngClass]="{'container-searching': searching}">
    <div class="search-header">
      <div class="search-input-container">
        <p class="d-none d-md-block">{{'searcher' | i18n}}</p>
        <input type="text" placeholder="{{'search_text' | i18n}}" (input)="filterChange($event.target.value)"
          value="{{searchValue}}">
      </div>
      <div class="close-button-container" *ngIf="searchValue.length > 0">
        <p>{{'searcherExit' | i18n}}:</p>
        <a (click)="closeSearch()"><img src="/assets/images/gallery/close-search-icon.png"></a>
      </div>
    </div>
    <p class="search-text" *ngIf="searchValue !== ''">{{'searchResultText' | i18n}} “ {{searchValue}} ”</p>
    <div class="gallery-images search-content" *ngIf="searchValue !== '' && searchImages && searchImages.length > 0 && searching">
      <app-populars-item *ngFor="let image of searchImages" [image]="image" (itemClick)="onSliderClick($event)">
      </app-populars-item>
    </div>
    <div class="not-found-results" *ngIf="searchImages.length == 0 && searchValue !== '' && searching">
      <img src="/assets/images/gallery/search-ico.png">
      <span class="vertical-line"></span>
      <p>{{'noResultsFound' | i18n}} “{{searchValue}}”</p>
    </div>

  </div>
  <div class="gallery-images-container">
    <div class="gallery-images" *ngIf="images && images.length > 0">
      <app-populars-item *ngFor="let image of images" [image]="image" (itemClick)="onSliderClick($event)">
      </app-populars-item>
    </div>
  </div>
</div>

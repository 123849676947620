import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RecordRemoteService } from 'src/app/core/services/remote/record/record-remote.service';
import { Record } from 'src/app/models/records/record';
import { RecordStorageRemoteService } from 'src/app/services/file-storage/record-storage-remote.service';

@Component({
  selector: 'app-view-file-dialog',
  templateUrl: './view-file-dialog.component.html',
  styleUrls: ['./view-file-dialog.component.scss']
})
export class ViewFileDialogComponent implements OnInit {
  recordId = -1;
  urlVideo = '';
  urlImg = '';
  record: Record = new Record();
  constructor(
    public dialogRef: MatDialogRef<ViewFileDialogComponent>,
    private recordStorageRemoteService: RecordStorageRemoteService,
    private recordRemoteService: RecordRemoteService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data);

    if (data.record) {
      this.recordId = data.record.id;

      this.recordStorageRemoteService.getDropzoneVideoPreview(this.recordId).subscribe(url => {
        this.urlVideo = url;
      });

    } else if (data.damage) {
      this.urlImg = data.damage;
    }

  }

  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-revaluation-dialog',
  templateUrl: './revaluation-dialog.component.html',
  styleUrls: ['./revaluation-dialog.component.scss']
})
export class RevaluationDialogComponent {

  submitted: true;

  constructor() { }

  ngOnInit(): void {
  }

  revaluation(){

  }

}

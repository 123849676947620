import { Component, OnInit, AfterViewChecked } from '@angular/core';
import {PaymentRemoteService} from '../../../core/services/remote/payment/payment-remote.service';

declare let paypal: any;

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit, AfterViewChecked { // https://developer.paypal.com/docs/checkout/integrate/

  constructor(private paymentsRemoteService: PaymentRemoteService) { }
  precioPaypal = 0.01;
  moneda = 'EUR';
  paypalScript = false;
  private nombreUsuarioAPI = 'http://gerencia_api1.ecapture.es';
  private contrasenaAPI = '52VMNXAVWSR6QADB';
  private firmaAPI = 'AUPxck9Az77Rbx1zM3-5ULxDlXv2ADEleB-y6BOHjrEOo.HakUjknEOi';

  private clientID = this.firmaAPI; // No tengo claro qué parámetro hay que enviar

  paypalconfig = {
    env: 'sandbox',
    client: {
      sandbox: this.clientID,
      production: '<your-production-key>'
    },
    commit: true,
    payment: (data, actions) => {
      return actions.payment.create({
        payment: {
          transactions: [{
            amount: {
              total: this.precioPaypal,
              currency: this.moneda
            }
          }]
        }
      });
    },
    onAuthorize: (data, actions) => {
      return actions.payment.execute().then((payment) => {
        // TODO
        // Incluir el código de cuando ya se ha completado el pago
      })
    },
    style: {// https://developer.paypal.com/docs/archive/checkout/how-to/customize-button/#customization-example
      size: 'large',
      color: 'blue',
      shape: 'rect',
      label: 'buynow',
      // branding:true,//Si layout es vertical, no funciona
      tagline: false,
      layout: 'vertical',
      fundingicons: false,// Si layout es vertical, no puede estar en true
    }
  };

  ngAfterViewChecked(): void {
    if (!this.paypalScript) {
      this.addPaypalScript().then(() => {
        paypal.Button.render(this.paypalconfig, '.paypal-button-container');
      });
    }
  }

  addPaypalScript() {
    this.paypalScript = true;
    return new Promise((resolve, reject) => {
      const scripttagElement = document.createElement('script');
      scripttagElement.src = 'https://www.paypalobjects.com/api/checkout.js';// 'https://www.paypal.com/sdk/js?client-id='+this.client_ID //La nueva versión
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
  }

  ngOnInit() {

  }

}

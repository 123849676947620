import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {I18nService} from '../../core/services/i18n.service';
import {InfoRemoteService} from 'src/app/services/info-remote.service';
import {LoaderService} from 'src/app/core/services/loader.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-information-layout',
  templateUrl: './information-page.component.html',
  styleUrls: ['./information-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InformationPageComponent implements OnInit {

  currentOption = null;
  mobile = true;
  options = [];
  text: any;

  constructor(private router: Router,
              private i18nService: I18nService,
              private infoRemoteService: InfoRemoteService,
              private loaderService: LoaderService,
              private sanitizer: DomSanitizer) {
    this.options = [
      {
        url: '/terms-of-use',
        title:  this.i18nService.getText('termsOfUse'),
        imageWhite: '/assets/images/information/mobile/white/usage-conditions.png',
        imageBlue: '/assets/images/information/mobile/blue/usage-conditions.png'
      },
      {
        url: '/privacy-policy',
        title:  this.i18nService.getText('privacyPolicy'),
        imageWhite: '/assets/images/information/mobile/white/privacy-policy.png',
        imageBlue: '/assets/images/information/mobile/blue/privacy-policy.png'
      },
      {
        url: '/cookie-policy',
        title:  this.i18nService.getText('cookiePolicies'),
        imageWhite: '/assets/images/information/mobile/white/cookies-policy.png',
        imageBlue: '/assets/images/information/mobile/blue/cookies-policy.png'
      },
    ];
  }

  ngOnInit() {
    this.loaderService.showLoader();
    this.currentOption = this.options.find(option => option.url === this.router.url);
    this.infoRemoteService.get(this.currentOption.url).subscribe(info => {
        this.text = this.sanitizer.bypassSecurityTrustHtml(info.text) ;
        this.loaderService.hideLoader();
    }, () => {
      this.text = '';
      this.loaderService.hideLoader();
    });
  }

  changeInfo(option) {
    this.router.navigateByUrl(option.url);
  }

}

import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-faqs-dialog',
  templateUrl: './faqs-dialog.component.html',
  styleUrls: ['./faqs-dialog.component.scss']
})
export class FaqsDialogComponent {

  options = { autoHide: false};

  constructor(public dialogRef: MatDialogRef<FaqsDialogComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

<div mat-dialog-content>
  <div class="header-container">
    <p>{{'cropPhoto' | i18n}}</p>
    <a (click)="onNoClick()">X</a>
  </div>

  <div class="avatar-container">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="4 / 4"
      [resizeToWidth]="256"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>
</div>
<div mat-dialog-actions>
  <button class="save" mat-button [mat-dialog-close]="{ file: croppedImage }" cdkFocusInitial>{{'save' | i18n}}</button>
  <button class="cancel" mat-button (click)="onNoClick()">{{'cancel' | i18n}}</button>
</div>

import {NgModule} from '@angular/core';
import {I18nService} from './services/i18n.service';
import {RateRemoteService} from './services/remote/rate-remote.service';
import {RedsysRemoteService} from './services/remote/payment/redsys-remote.service';
import {PaymentRemoteService} from './services/remote/payment/payment-remote.service';
import {LoaderService} from './services/loader.service';
import {ThumbnailRemoteService} from '../services/thumbnail-remote.service';
import {UserRemoteService} from './services/remote/user/user.remote.service';
import {SectorRemoteService} from './services/remote/common/sector-remote.service';
import {ResponsiveService} from './services/responsive.service';
import {ResponsiveViewerService} from './services/responsive-viewer.service';
import {HttpCancelInterceptorService} from './services/api-access/http-cancel-interceptor.service';
import {HttpCancelService} from './services/api-access/http-cancel-service.service';
import { AppConfigService } from '../services/config/app-config.service';
import { AppConfigLoaderService } from '../services/config/app-config-loader.service';
import { AuthenticationService } from '../services/security/authentication.service';
import { AuthService } from 'angularx-social-login';
import { StorageService } from '../models/storage/storage.service';
import {RecordFilesRemoteService} from './services/remote/record/record-files-remote.service';
import {RegionRemoteService} from './services/remote/common/region-remote.service';
import {Ec2RemoteService} from './services/remote/amazon/ec2-remote.service';


@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [
    I18nService,
    RateRemoteService,
    RedsysRemoteService,
    PaymentRemoteService,
    LoaderService,
    ThumbnailRemoteService,
    UserRemoteService,
    SectorRemoteService,
    ResponsiveService,
    ResponsiveViewerService,
    HttpCancelInterceptorService,
    HttpCancelService,
    AppConfigService,
    AppConfigLoaderService,
    AuthenticationService,
    AuthService,
    StorageService,
    RecordFilesRemoteService,
    RegionRemoteService,
    Ec2RemoteService
  ]
})
export class CoreModule { }

import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../shared/models/user/user';

@Component({
  selector: 'app-mark-unmark-model-as-favorite',
  templateUrl: './mark-unmark-model-as-favorite.component.html',
  styleUrls: ['./mark-unmark-model-as-favorite.component.scss']
})
export class MarkUnmarkModelAsFavoriteComponent implements OnInit {

  // @Input() model: model;
  @Input() user: User;

  constructor() { }

  ngOnInit() {
  }

  /*
  markAsFavorite(event) {
    event.stopPropagation();
    const userModel = {
      model_id: this.model.id,
      user_id: this.user.id,
      is_favorite: !this.model.is_favorite
    };
    this.modelRemoteService.markUnmarkAsFavorite(userModel).subscribe(response => {
      this.model.is_favorite = response.is_favorite;
    });
  }
*/
}

import { Injectable } from '@angular/core';
import { Language } from '../../models/language';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  private currentLanguageCode: string;
  private i18nEntriesObjects: any[] = [];

  constructor() {
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeEs, 'es');
    try {
      this.currentLanguageCode = I18nService.getSupportedLanguages().find(lang => navigator.language.includes(lang.code)).code;
    } catch (e) {
      this.currentLanguageCode = I18nService.getSupportedLanguages()[0].code;
    }
    // this.i18nEntriesObjects.push(I18N_ENTRIES);
  }

  public static getSupportedLanguages(): Language[] {
    return [
      new Language('es', 'Español', 'es-ES,es;q=0.8'),
      new Language('en', 'English', 'en-EN,es;q=0.8')
    ];
  }

  public static getValueI18nKey(value: string): string {
    return value[0].toLowerCase() + value.slice(1);
  }

  public setCurrentLanguage(languageCode: string): void {
    const isCodeSupported = I18nService.getSupportedLanguages().some((x) => x.code === languageCode);

    if (!isCodeSupported) {
      languageCode = 'es';
    }

    this.currentLanguageCode = languageCode;
  }

  public getCurrentLanguage(): Language {
    return I18nService.getSupportedLanguages()
      .find((x) => x.code === this.currentLanguageCode);
  }

  public getText(key: string, keyParams?: object): string {
    if (!key) {
      return '';
    }
    const keyFirstCharLowered = I18nService.getValueI18nKey(key);
    const i18nEntriesObject = this.i18nEntriesObjects.find((x) => x[keyFirstCharLowered] !== undefined);

    if (!i18nEntriesObject) {
      if (!i18nEntriesObject) {
        throw new Error(`Inexistent i18n entry '${key}'.`);
      }
    }

    const entry = i18nEntriesObject[keyFirstCharLowered];
    let text = entry[this.currentLanguageCode];

    if (!text) {
      throw new Error(`Inexistent language '${this.currentLanguageCode}' for i18n entry '${key}'.`);
    }

    if (keyParams) {
      Object.keys(keyParams).forEach((keyParam) => text = text.replace(`$${keyParam}`, keyParams[keyParam]));
    }
    return text;
  }

  public addI18nEntriesObject(i18nEntriesObject: any): void {
    this.i18nEntriesObjects.push(i18nEntriesObject);
  }
}

import { Injectable } from '@angular/core';
import { RestClientService } from 'src/app/core/services/api-access/rest-client.service';
import { Observable } from 'rxjs';
import { Damage } from 'src/app/models/damage';
import { Video } from '../../../models/video';

@Injectable({
  providedIn: 'root'
})
export class DamageRemoteService {

  private URL = '/damages';

  constructor(private restClientService: RestClientService) { }

  public get(video: Video): Observable<Damage[]> {
    return this.restClientService.get(`${this.URL}/${video.id}`);
  }

  public getByRoad(roadID: number): Observable<Damage[]> {
    return this.restClientService.get(`${this.URL}/${roadID}/road`);
  }

  public getFrames(damageID: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${damageID}/frames`);
  }

  public create(damageID: number): Observable<Damage> {
    return this.restClientService.post(`${this.URL}/${damageID}`, null);
  }

  public update(damage: Damage): Observable<Damage> {
    return this.restClientService.put(`${this.URL}/${damage.id}`, damage);
  }

  public validate(damageID: number): Observable<Damage> {
    return this.restClientService.put(`${this.URL}/${damageID}/validate`, null);
  }

  public delete(damageID: number): Observable<Damage> {
    return this.restClientService.delete(`${this.URL}/${damageID}`);
  }

  public getAllDamages(recordID: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${recordID}/record`);
  }

  public getEstimation(recordID: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${recordID}/estimation-all`);
  }
}

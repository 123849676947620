import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../core/services/loader.service';
import { ResponsiveService } from '../../../core/services/responsive.service';

import { Record } from 'src/app/models/records/record';
import { ProjectThumbnail } from 'src/app/models/project-thumbnail';
import { RecordRemoteService } from '../../../core/services/remote/record/record-remote.service';
import { UserRemoteService } from '../../../core/services/remote/user/user.remote.service';
import { GalleryCategoryEnum } from '../../../models/common/gallery-category-enum';
import { AuthenticationService } from '../../../services/security/authentication.service';
import { User } from '../../../shared/models/user/user';
import { clone } from 'lodash';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  constructor(private recordRemoteService: RecordRemoteService,
    private loaderService: LoaderService,
    private responsiveService: ResponsiveService,
    private userRemoteService: UserRemoteService,
    private authenticationService: AuthenticationService) {
  }

  userProjects: Record[] = [];
  currentProjects: Record[] = [];
  departmentProjects: Record[];
  showGoogleAdsense = false;
  departmentProjectsLoaded = false;
  sliderImages: ProjectThumbnail[] = [];
  galleryCategorySelected: GalleryCategoryEnum = GalleryCategoryEnum.MY_PROJECTS;
  gridItemPercent = 0;
  filter = '';
  user: User;

  ngOnInit() {
    this.loaderService.showLoader();
    this.getUser();
    this.showGoogleAdsense = !this.responsiveService.isSmallScreen();
    this.gridProperties();
  }

  filterChange(value: string) {
    this.filter = value.toLowerCase();
    this.galleryCategoryClick(this.galleryCategorySelected);
  }

  galleryCategoryClick(galleryCategory: GalleryCategoryEnum) {
    this.galleryCategorySelected = galleryCategory;
    switch (galleryCategory) {
      case GalleryCategoryEnum.MY_PROJECTS:
        if (this.userProjects && this.userProjects.length > 0) {
          const projects = clone(this.userProjects).sort(this.sortByCreated);
          if (projects.length > 10) {
            this.currentProjects = projects.splice(0, 10);
          } else {
            this.currentProjects = projects;
          }
        }
        break;
      case GalleryCategoryEnum.LAST_PROJECTS:
        if (this.userProjects && this.userProjects.length > 0) {
          const projects = clone(this.userProjects).sort(this.sortByCreated);
          if (projects.length > 10) {
            this.currentProjects = projects.splice(0, 10);
          } else {
            this.currentProjects = projects;
          }
        }
        break;
      case GalleryCategoryEnum.FAVORITES:
        this.currentProjects = [];
        break;
      case GalleryCategoryEnum.MY_SHARED:
        this.currentProjects = [];
        break;
    }
    this.loadSliderImages();
  }

  private getUserProjects() {
    this.recordRemoteService.getRecords(this.user.id).subscribe(projects => {
      console.log(projects);

      this.userProjects = projects;
      this.userProjects.forEach(record => {
        record.name = record.licensePlate + '_' + record.id;
      });
      this.galleryCategoryClick(GalleryCategoryEnum.MY_PROJECTS);
      this.loaderService.hideLoader();
    }, error => this.loaderService.hideLoader());
  }

  private loadSliderImages() {
    this.sliderImages = [];
    this.currentProjects.forEach(sliderProject => {
      if (sliderProject) {
        this.sliderImages.push({
          name: sliderProject.id.toString(),
          src: sliderProject.videoURL,
          project: sliderProject
        });
      }
    });
  }

  private gridProperties() {
    if (this.responsiveService.isS()) {
      this.gridItemPercent = 49;
    } else if (this.responsiveService.isM()) {
      this.gridItemPercent = 22;
    } else if (this.responsiveService.isl() || this.responsiveService.isxl()) {
      this.showGoogleAdsense = true;
      this.gridItemPercent = 22;
    } else {
      this.showGoogleAdsense = true;
      this.gridItemPercent = 23;
    }
  }

  private getUser() {
    this.authenticationService.get().subscribe(user => {
      this.user = user;
      this.getUserProjects();
    });
  }

  /*private sortByVisits(project1: Project, project2: Project): number {
    return (project2.mesh_visits + project2.point_cloud_visits + project2.viewproject_visits) -
      (project2.mesh_visits + model1.point_cloud_visits + model1.viewproject_visits);
  }*/

  private sortByCreated(project1: Record, project2: Record): number {
    // const date1: any = new Date(project1.createdAt);
    // const date2: any = new Date(project2.createdAt);
    // return date2 - date1;
    return 0;
  }
}

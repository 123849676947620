import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Record} from '../../../app/models/records/record';

@Component({
  selector: 'app-projects-processing-slider',
  templateUrl: './projects-processing-slider.component.html',
  styleUrls: ['./projects-processing-slider.component.scss']
})
export class ProjectsProcessingSliderComponent {

  @Input() records: Record[] = [];
  @Input() widthPercent = 30;
  @Input() height = 278;
  @Input() marginRight = 30;

  @Output() clickItem = new EventEmitter<Record>();
  @Output() projectRefresh = new EventEmitter<Record>();

  constructor() {}

  click(record: Record) {
    this.clickItem.emit(record);
  }

  projectRefreshEvent(record: Record) {
    this.projectRefresh.emit(record);
  }
}

<div class="frame-downloader-dialog-container">

  <mat-dialog-content class="dialog-text">
    <div class="header-container">
      <div class="tittle">VERIFICANDO INFORMACIÓN</div>
    </div>
    <div class='image-container'>
      <img src="/assets/images/navbar/blue/eyesdcar-logo.svg" alt="Cargando">
    </div>
    <div class=text-dialog-container>
      <div class="selection-notice">Se está verificando la información con la base de datos de la Dirección General de Tráfico.</div>
    </div>
    <div class="accept-button">
      <button class="cancel" mat-button [mat-dialog-close]="true" (click)="onNoClick()">Cancelar</button>
    </div>
  </mat-dialog-content>
</div>

import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import * as _ from 'lodash';
import {Observable} from 'rxjs/internal/Observable';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';

@Injectable({
    providedIn: 'root',
})
export class RestClientErrorHandlerService {

    constructor() {}

    public handleError(httpErrorResponse: HttpErrorResponse) {
      /*let operationResult = null;
      if (httpErrorResponse.error && httpErrorResponse.error.message) {
        operationResult = httpErrorResponse.error.message;
      } else {
        operationResult = 'error'; // this.i18nService.getText('unknownErrorAccessingServer');
      }*/

      return throwError(httpErrorResponse.error);
    }

    public handleBlobError(httpErrorResponse: HttpErrorResponse) {
        return this.parseErrorBlob(httpErrorResponse).pipe(catchError((err) => this.handleError(err)));
    }

    private parseErrorBlob(err: HttpErrorResponse): Observable<any> {
        const reader: FileReader = new FileReader();
        const obs = Observable.create((observer: any) => {
            reader.onloadend = () => {
                const errResponse = _.clone(err);
                /*if (typeof reader.result === 'string') {
                  errResponse.error = JSON.parse(reader.result);
                }*/
                observer.error(errResponse);
                observer.complete();
            };
        });
        reader.readAsText(err.error);
        return obs;
    }
}

import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from '../../../core/services/loader.service';
import { ThreeViewerService } from '../../services/three-viewer.service';
import { VehicleMeshInitialData } from '../../models/vehicle-mesh-initial-data';
import { EventService } from 'src/app/core/services/event.service';
import { Subscription } from 'rxjs';
import { Damage } from 'src/app/models/damage';
import { EventData } from 'src/app/core/models/event-data';
import { I18nService } from 'src/app/core/services/i18n.service';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements AfterViewInit, OnDestroy, OnInit {

  private frameId: number = null;
  private subscriptions: Subscription[] = [];

  @ViewChild('rendererArea', { static: true })
  public rendererArea: ElementRef<HTMLCanvasElement>;

  @ViewChild('viewerContainer', { static: true })
  public viewerContainer: ElementRef<HTMLCanvasElement>;

  constructor(
    private ngZone: NgZone,
    private loaderService: LoaderService,
    private threeService: ThreeViewerService,
    private eventService: EventService,
    private i18nService: I18nService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.eventService.on('highlightCarDamage', () => {
      // this.threeService.showCamera();
    }));

    /*   this.subscriptions.push(this.eventService.on('carTypeViewer', (data) => {
        console.log(data);
      })); */
    this.subscriptions.push(this.eventService.on('activeDamage', (data: Set<Damage>) => {
      let damageData;
      for (let item of data.entries()) {
        console.log(item);
        damageData = this.i18nService.getText((item[0] as any).part_name);
      };

      this.threeService.highlightDamage(damageData);
    }));

    this.subscriptions.push(this.eventService.on('carTypeViewer', (data) => {
      // this.threeService.showCamera();
      console.log(data);
      this.threeService.setCarType(data);
      this.createScene(null);
    }));
    this.eventService.emit(new EventData('viewerReady', (null)));


  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy() {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
  }

  animate(): void {
    this.ngZone.runOutsideAngular(() => {
      this.render();
    });
  }

  render() {
    this.threeService.render();
    this.frameId = requestAnimationFrame(() => this.render());
  }

  private createScene(initialData: VehicleMeshInitialData) {
    const width = this.viewerContainer.nativeElement.getBoundingClientRect().width;
    const height = this.viewerContainer.nativeElement.getBoundingClientRect().height;

    this.threeService.createScene(this.rendererArea.nativeElement, width, height, null);
    this.animate();
  }

  onMouseMove(event) {
    this.threeService.rayCaster(event);
  }

  onMouseDown() {
    this.threeService.selectPart();
  }

  onDoubleClick() {
    this.threeService.restoreCarMaterials();
  }

}

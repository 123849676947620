<ng-container>
  <div class="progress" *ngIf="record && record.percentage_processed < 100">
    <div
      class="progress-bar progress-bar-striped progress-bar-animated"
      [style.width.%]="record.percentage_processed">
      <p>   {{record.percentage_processed}}% / 100% </p>
    </div>
  </div>
</ng-container>




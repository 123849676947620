<div class = "gallery-container">
  <div class="gallery-header-container">
    <div class="gallery-other-categories">
      <a class="font-size-categorias" (click)="galleryCategoryClick(1)" [ngClass] = "{'other-categories-selected': galleryCategorySelected == 1}">{{'records' | i18n}}</a>
      <!-- <a class="font-size-categorias" (click)="galleryCategoryClick(2)" [ngClass] = "{'other-categories-selected': galleryCategorySelected == 2}">{{'policies' | i18n}}</a> -->
    </div>
  </div>

  <app-gallery-slider [images]="sliderImages"></app-gallery-slider>

  <div class = "gallery">
    <app-gallery-grid
      [projects]="userProjects"
      [showSearch]="true"
      [gridItemPercent]="gridItemPercent"
      [showGoogleAdsense]="showGoogleAdsense">
    </app-gallery-grid>
  </div>
</div>

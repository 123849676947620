<div class="selectLanguage disable" [ngClass]="{'selectLanguageFooter': footer === true}">
  <button mat-button [matMenuTriggerFor]="menu">
    <span class="spanish-change"  *ngIf="footer===true">Cambiar idioma / <span style="font: italic normal 500 10px/13px Montserrat;
      ">Select language</span></span>
    <span class="english-change" *ngIf="footer===false"> {{ 'changeLanguage' | i18n }}</span>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <!-- TODO David: Esto se hace estatico porque con el ngFor no se esta propagando el clik -->
  <mat-menu  #menu="matMenu">
    <button
      mat-menu-item
      *ngIf="currentLanguage.code != 'es'"
      (click)="setCurrentLanguage('es')">
      Español
    </button>
    <button
      mat-menu-item
      *ngIf="currentLanguage.code != 'en'"
      (click)="setCurrentLanguage('en')">
      English
    </button>
  </mat-menu>
</div>

import { Component, OnInit, HostListener } from '@angular/core';
import { ProjectThumbnail } from '../../../models/project-thumbnail';
import { UserRemoteService } from '../../../core/services/remote/user/user.remote.service';
import { Record } from '../../../models/records/record';
import { LoaderService } from '../../../core/services/loader.service';
import { ResponsiveService } from '../../../core/services/responsive.service';
import { EventData } from 'src/app/core/models/event-data';

import { EventService } from 'src/app/core/services/event.service';

import { MatDialog } from '@angular/material/dialog';
import { DgtWaitingDialogComponent } from '../dialogs/dgt-waiting-dialog/dgt-waiting-dialog.component'
import { RecordRemoteService } from 'src/app/core/services/remote/record/record-remote.service';
import { RevaluationDialogComponent } from '../dialogs/revaluation-dialog/revaluation-dialog.component';

@Component({
  selector: 'app-home-new-project-component',
  templateUrl: './home-new-project-component.html',
  styleUrls: ['./home-new-project-component.scss'],
})

export class HomeNewProjectComponent implements OnInit {

  isSmallSize;
  sliderImages: ProjectThumbnail[];
  userRecords: Record[] = [];
  verify = true;

  constructor(
    private dialog: MatDialog,
    private projectRemoteService: RecordRemoteService,
    private loaderService: LoaderService,
    private responsiveService: ResponsiveService
  ) { }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (event.target.innerWidth <= 630) {
      this.isSmallSize = true;
    } else if (event.target.innerWidth > 630) {
      this.isSmallSize = false;
    }
  }

  ngOnInit() {
    this.loaderService.showLoader();
    this.getUserProjects();

    this.isSmallSize = this.responsiveService.isMobile();
  }

  private getUserProjects() {
    // Esto es lo que estaba antes copiado tal y como de eyesNroad (no funcionaba)

    // this.userRemoteService.getRecords().subscribe(projects => {
    //   this.userProjects = projects;
    //   if (this.userProjects.length > 0) {
    //     this.userProjects = this.userProjects.slice(0, 10);
    //   }
    //   this.loadSliderImages();
    //   this.loaderService.hideLoader();
    // }, error => this.loaderService.hideLoader());

    this.projectRemoteService.getProcessingRecords().subscribe(projects => { // TODO Cambiar a últimos expedientes cuando esté listo
      if (projects.length) {
        console.log(projects.slice());
        this.userRecords = projects;
        this.loadSliderImages();
      }
      this.loaderService.hideLoader();
    }, () => this.loaderService.hideLoader());

  }

  private loadSliderImages() {
    this.sliderImages = [];
    this.userRecords.forEach(record => {
      if (record) {
        this.sliderImages.push({
          name: record.id.toString(), // TODO Esto no se está mostrando por algún motivo
          src: record.previewURL,
          project: record
        });
      } else {
        this.sliderImages.push({
          name: null,
          src: null,
          project: null
        });
      }
    });
  }

  openVerifyingInfoDialog(): void {
    const dialogRef = this.dialog.open(DgtWaitingDialogComponent, {
      width: '628px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.FramesActive = false;
    });
  }

  createRevaluationDialog(){
    console.log("se lanzara el dialogo de reev");
    const dialogRef = this.dialog.open(RevaluationDialogComponent, {
      width: '628px',
      height: '319px',
      data: {
        
      },
    });
    
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {LoaderService} from '../../../core/services/loader.service';

@Component({
  selector: 'app-media-files-gallery',
  templateUrl: './media-files-gallery.component.html',
  styleUrls: ['./media-files-gallery.component.scss']
})
export class MediaFilesGalleryComponent implements OnInit {

  // mediaFiles: ModelFile[] = [];

  @Input() widthPercent = 30;
  @Input() height = 278;
  @Input() marginRight = 30;
  // @Input() model: model;

  constructor(
              private loaderService: LoaderService) { }

  ngOnInit() {
    /*
    this.loaderService.showLoader();
    this.modelService.getModelFilesName(this.model.id).subscribe(modelFiles => {
      if (modelFiles.length > 0) {
        modelFiles.forEach(modelFile => {
          const pos = this.mediaFiles.push({
            thumbnail: modelFile.thumbnail,
            preview: modelFile.preview,
            isVideo: modelFile.isVideo,
            thumbnailSrc: null,
            previewSrc: null,
            videoSrc: null,
            model: this.model
          });
          this.modelService.getPreviewImageByName(this.model.id, modelFile.preview).subscribe(file => {
            if (modelFile.isVideo) {
              // this.mediaFiles[pos - 1].videoSrc = this.thumbnailService.setApiImageToSrcImage(file);
            } else {
              // this.mediaFiles[pos - 1].thumbnailSrc = this.thumbnailService.setApiImageToSrcImage(file);
            }
            this.loaderService.hideLoader();
          });
        });
      } else {
        this.loaderService.hideLoader();
      }
    }, error => this.loaderService.hideLoader());
    */
  }
}

<app-slider
  *ngIf="popularImages && popularImages.length > 0">
  <app-populars-item
    *ngFor="let image of popularImages"
    [style.min-width.%]="widthPercent"
    [style.height.px]="height"
    [style.marginRight.px]="marginRight"
    [image]="image"
    (itemClick)="onSliderClick($event)">
  </app-populars-item>
</app-slider>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../../../shared/models/user/user';

@Component({
  selector: 'app-media-file-item',
  templateUrl: './media-file-item.component.html',
  styleUrls: ['./media-file-item.component.scss']
})
export class MediaFileItemComponent implements OnInit {

  avatar: any;
  user: User;

  // @Input() image: ModelFile;
  @Output() itemClick = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  click(code: string) {
    // this.itemClick.emit(this.image);
  }
}

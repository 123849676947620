import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-customer-support-dialog',
  templateUrl: './customer-support-dialog.component.html',
  styleUrls: ['./customer-support-dialog.component.scss']
})
export class CustomerSupportDialogComponent {

  constructor(public dialogRef: MatDialogRef<CustomerSupportDialogComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

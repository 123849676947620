export class Incidence {

  public id: number;
  public model_id: number;
  public open_date: string;
  public close_date: string;
  public user_id: number;
  public content: string;

  public constructor(modelId?: number, openDate?: string, userId?: number, content?: string) {
    this.model_id = modelId;
    this.open_date = openDate;
    this.user_id = userId;
    this.content = content;
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {UserRateRemoteService} from '../../../core/services/remote/user-rate/user-rate-remote.service';

@Component({
  selector: 'app-google-adsense',
  templateUrl: './google-adsense.component.html',
  styleUrls: ['./google-adsense.component.scss']
})
export class GoogleAdsenseComponent implements OnInit {

  credits = null;

  @Input() width;
  @Input() height;

  constructor(private userRateRemoteService: UserRateRemoteService) { }

  ngOnInit() {
    this.userRateRemoteService.getCredits().subscribe(credits => {
      this.credits = credits;
    });
  }

}

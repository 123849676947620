<div class="layout-container" #layoutContainer>
  <div class="app">
    <app-header *ngIf="showHeader"
      [showBackground]="showHeaderBackground"
      [navbarBackgroundBlue]="navbarBackgroundBlue"
      [lowHeader]="lowHeader"></app-header>
    <main [ngClass]="{ 'main-gray': grayBackground }">
      <router-outlet (activate)='onComponentActivate($event)'></router-outlet>
    </main>
    <app-footer *ngIf="showFooter"></app-footer>
  </div>
</div>


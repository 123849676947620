import {NgModule} from '@angular/core';
import {SliderComponent} from './components/slider/slider.component';
import {LayoutComponent} from './components/layout/layout.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {LanguageSelectorComponent} from './components/language-selector/language-selector.component';
import {SharedModule} from '../shared/shared.module';
import {PopularsSliderComponent} from './components/populars-slider/populars-slider.component';
import {PopularsItemComponent} from './components/populars-slider/populars-item/populars-item.component';
import {I18nService} from '../core/services/i18n.service';
import {COMMONS_I18N_ENTRIES} from './config/commons-i18n-entries';
import {LoaderComponent} from './components/loader/loader.component';
import {GalleryGridComponent} from './components/gallery-grid/gallery-grid.component';
import { GalleryVisualizatorComponent } from './components/gallery-visualizator/gallery-visualizator.component';
import { GallerySliderComponent } from './components/gallery-slider/gallery-slider.component';
import { GalleryItemComponent } from './components/gallery-slider/gallery-item/gallery-item.component';
import { MediaFilesGalleryComponent } from './components/media-files-gallery/media-files-gallery.component';
import {MediaFileItemComponent} from './components/media-files-gallery/media-file-item/media-file-item.component';
import {StaticSliderComponent} from './components/static-slider/static-slider.component';
import {SimplebarAngularModule} from 'simplebar-angular';
import { MarkUnmarkModelAsFavoriteComponent } from './components/mark-unmark-model-as-favorite/mark-unmark-model-as-favorite.component';
import {AdsenseModule} from 'ng2-adsense';
import { GoogleAdsenseComponent } from './components/google-adsense/google-adsense.component';
import { ProjectProgressBarComponent } from './components/project-progress-bar/project-progress-bar.component';
import {LinesComponent} from './components/lines/lines.component';
import {ChartsModule} from 'ng2-charts';


@NgModule({
  declarations: [
    SliderComponent,
    LayoutComponent,
    NavbarComponent,
    HeaderComponent,
    FooterComponent,
    LanguageSelectorComponent,
    ProjectProgressBarComponent,
    PopularsItemComponent,
    PopularsSliderComponent,
    LoaderComponent,
    GalleryGridComponent,
    GalleryVisualizatorComponent,
    GallerySliderComponent,
    GalleryItemComponent,
    MediaFilesGalleryComponent,
    MediaFileItemComponent,
    StaticSliderComponent,
    MarkUnmarkModelAsFavoriteComponent,
    GoogleAdsenseComponent,
    LinesComponent,
  ],
  imports: [
    SharedModule,
    SimplebarAngularModule,
    ChartsModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-4671591524487127'
    }),
  ],
    exports: [
      SliderComponent,
      LayoutComponent,
      NavbarComponent,
      HeaderComponent,
      FooterComponent,
      LanguageSelectorComponent,
      SharedModule,
      ProjectProgressBarComponent,
      PopularsItemComponent,
      PopularsSliderComponent,
      LoaderComponent,
      GalleryGridComponent,
      GalleryVisualizatorComponent,
      MediaFilesGalleryComponent,
      StaticSliderComponent,
      SimplebarAngularModule,
      GoogleAdsenseComponent,
      GallerySliderComponent,
      AdsenseModule,
      LinesComponent,
    ]
})
export class CommonsModule {
  constructor(private i18nService: I18nService) {
    i18nService.addI18nEntriesObject(COMMONS_I18N_ENTRIES);
  }
}

<div class="slider-item" (click)="click(image.project.id)" *ngIf="image.project">
  <div class="wrapper">
    <div class="opacity-div"></div>
    <img [src]="src" />
    <p class="model-name">{{image.project.licensePlate }}{{'_'}}{{image.project.id}}</p>
    <div class="line"></div>
    <div class="gallery-item-user-info-container d-none d-md-flex">
      <div class="gallery-item-user-info-subcontainer">
        <img  src="/assets/images/navbar/blue/eyesdcar-logo.svg" class="user-photo" />
        <div class="gallery-item-user-info-text">
          <p class="gallery-item-user-info-name">{{user.profile.name}} {{user.profile.surname}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

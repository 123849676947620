import { Injectable } from '@angular/core';
import { Incidence } from '../models/incidence';
import { Observable } from 'rxjs';
import { Saveable } from '../shared/models/saveable';
import { Retrievable } from '../shared/models/retrievable';
import { RestClientService } from '../core/services/api-access/rest-client.service';
import { Deleteable } from '../shared/models/deleteable';
import { Searchable } from '../shared/models/searchable';
import { DateService } from './api-services/date.service';
import {IncidenceSearchCriteria} from '../models/security/incidence-search-criteria';
import {IncidenceMessage} from '../models/incidence-message';

@Injectable({
    providedIn: 'root'
})
export class IncidenceRemoteService implements
    Searchable<Incidence, IncidenceSearchCriteria>,
    Retrievable<Incidence, number>,
    Saveable<Incidence>,
    Deleteable<Incidence, number> {

    private URL = '/incidences';
    private postMessage = '/incidencemessage';

    constructor(private restClientService: RestClientService, private dateService: DateService) { }

    public search(criteria?: IncidenceSearchCriteria): Observable<Incidence[]> {
        return this.restClientService.get(this.URL, criteria);
    }

    public searchMessages(criteria?: IncidenceSearchCriteria): Observable<IncidenceMessage[]> {
        return this.restClientService.get(this.URL, criteria);
    }

    public get(id: number): Observable<Incidence> {
        return this.restClientService.get(`${this.URL}/${id}`);
    }

    public close(incidence: Incidence): Observable<Incidence> {
        incidence.close_date = this.dateService.formatDateTime(new Date());
        return this.save(incidence);
    }

    public save(incidence: Incidence): Observable<Incidence> {
        if (incidence.id !== undefined && incidence.id !== null) {
            return this.restClientService.put(`${this.URL}/${incidence.id}`, incidence);
        }
        return this.restClientService.post(this.URL, incidence);
    }

    public saveMessage(incidenceMessage: IncidenceMessage): Observable<IncidenceMessage> {
        return this.restClientService.post(this.postMessage, incidenceMessage);
    }

    public delete(id: number): Observable<Incidence> {
        return this.restClientService.delete(`${this.URL}/${id}`);
    }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';
import { BaseStorageService } from '../../models/storage/base-storage.service';
import { StorageService } from '../../models/storage/storage.service';
import { AcceptedLogin } from '../../models/security/accepted-login';
import { DateService } from '../date.service';
import { UserToken } from 'src/app/models/security/user-token';

@Injectable({
  providedIn: 'root',
})
export class SecurityTokenStorage<T extends UserToken> extends BaseStorageService<T> {
  private OnSessionExpired = new Subject<UserToken>();

  constructor(storageService: StorageService) {
    super(storageService);
  }

    private static loginExpired(userToken: UserToken) {
        return DateService.datetimeIsBefore(DateService.getDate(userToken.tokenExpirationDate), DateService.currentDate());
    }

    public onSessionExpired(): Observable<any> {
      return this.OnSessionExpired;
    }

    protected getStorageKey(): string {
        return 'acceptedLogin';
    }

    public getAcceptedLogin(): UserToken {
        const userToken = super.getObjectValue();
        if (userToken) {
          if (!SecurityTokenStorage.loginExpired(userToken)) {
            return userToken;
          } else {
            this.OnSessionExpired.next(userToken);
          }
        }
        return null;
    }
}

export class Record {
  creation_date?: Date;
  user_id?: number;
  userName?: string;
  userSurname?: string;
  brand?: string;
  vehicleType?: string;
  license_plate?: string;
  vin?: string;
  insurance?: string;
  vehicle_brand_id?: number;
  vehicle_type_id?: number;
  vehicle_id?: number;
  id?: number;
  registration_date?: Date;
  previewURL?: string;
  avatarURL?: string;
  videoURL?: string;
  percentage_processed?: number;
  model?: string;
  modelVersion?: string;
  carFrame?: string;
  viewerType?: string;
  version?: string;
  licensePlate?: string;
  name?: string;
  recordId?: number;
}

export class AppLoadModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppLoadService {

  constructor(private httpClient: HttpClient) { }

  initializeApp(): Promise<any> {
    return new Promise((resolve, reject) => {
        if (!window.navigator.onLine) {
          window.location.href = 'http://127.0.0.1:8080/offline.html';
        }
        resolve();
    });
  }
}

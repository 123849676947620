export const APP_I18N_ENTRIES = {
  unknownErrorAccessingServer: {
    en: '',
    es: '',
  },
  authTitle: {
    en: 'Artificial intelligence for road inventories.',
    es: 'Inteligencia artificial para inventarios viales.',
  },
  login: {
    en: 'Sign In',
    es: 'Iniciar sesión',
  },
  username: {
    en: 'Username',
    es: 'Usuario',
  },
  password: {
    en: 'Password',
    es: 'Contraseña',
  },
  loginLabel: {
    en: 'Sign in',
    es: 'Loguearse'
  },
  passwordRequired: {
    en: 'Password is required',
    es: 'La contraseña es obligatoria',
  },
  yourFirstTime: {
    en: 'Your first time?',
    es: '¿Tu primera vez?'
  },
  createAccount: {
    en: 'Create a free account',
    es: 'Crea tu cuenta, ¡Gratis!',
  },
  connectWith: {
    en: 'Connect with',
    es: 'Conectar con',
  },
  facebook: {
    en: 'Facebook',
    es: 'Facebook',
  },
  authTitleDesktop2: {
    en: 'Automatic detection',
    es: 'Detección automática',
  },
  authTitleDesktop3: {
    en: 'of vehicle damages',
    es: 'de daños en vehículos',
  },
  authTitleDesktop4: {
    en: 'in the images using',
    es: 'en imagen mediante',
  },
  authTitleDesktop5: {
    en: 'artificial intelligence',
    es: 'inteligencia artificial',
  },
  start: {
    en: 'Start',
    es: 'Empezar',
  },
  termsConditionsRequired: {
    en: 'You must agree terms and conditions',
    es: 'Debe aceptar los términos y condiciones',
  },
  agree: {
    en: 'I agree to the',
    es: 'Acepto',
  },
  ethicalRules: {
    en: 'Ethical Rules',
    es: 'Código ético'
  },
  home: {
    en: 'Home',
    es: 'Inicio',
  },
  new3d: {
    en: 'New 3D',
    es: 'Nuevo 3D'
  },
  generation: {
    en: 'Generation',
    es: 'Generación'
  },
  profile: {
    en: 'Profile',
    es: 'Perfil'
  },
  your3dModel: {
    en: 'Make your 3D model',
    es: 'Haz tu modelo 3D'
  },
  yourProject: {
    en: 'Your Project',
    es: 'Tu Proyecto'
  },
  projectsInProcess: {
    en: 'Projects management',
    es: 'Gestión de proyectos'
  },
  morePopulars: {
    en: 'More populars',
    es: 'Más populares'
  },
  showAll: {
    en: 'Show all',
    es: 'Mostrar todo'
  },
  newProjectText: {
    en: 'Upload your road project and analyze all its signs',
    es: 'Sube tu proyecto de coche y analiza todos sus daños.'
  },
  galleryText: {
    en: 'Your projects, roads, your eyesNroad gallery…',
    es: 'Tus proyectos, coches, modelo, historial…'
  },
  logout: {
    en: 'Logout',
    es: 'Cerrar sesión',
  },
  fares: {
    en: 'Fares',
    es: 'Tarifas',
  },
  toolbar_measure: {
    en: 'Measure',
    es: 'Medición'
  },
  measure_panel_title: {
    es: 'Medida',
    en: 'Measure'
  },
  measure_panel_steps: {
    es: 'Marca dos puntos para definir la línea de longitud a medir.',
    en: 'Mark two points to define the length line to measure.'
  },
  measure_panel_measure_length_title: {
    es: 'Longitud de medida',
    en: 'Measure length'
  },
  measure_panel_measure_length_title2: {
    es: 'sin unidad de medida',
    en: 'without measure numbers'
  },
  measure_panel_tip: {
    es: 'RECOMENDACIÓN: Para obtener una longitud con unidad de medida defina una escala con la herramienta',
    en: 'TIP: To get a length with measure numbers, define a scale with the tool'
  },
  measure_panel_tip2: {
    es: 'escala',
    en: 'scale'
  },
  toolbar_scale: {
    en: 'Scale',
    es: 'Escala',
  },
  toolbar_p2p: {
    en: 'Point to Point',
    es: 'Punto a Punto',
  },
  toolbar_photoreadjustment: {
    en: 'Photo Readjustment',
    es: 'Fotorectificación',
  },
  toolbar_photoreadjustment_calculate: {
    en: 'Calculate',
    es: 'Calcular',
  },
  toolbar_photoreadjustment_activate_magnifying: {
    en: 'Activate magnifying glass',
    es: 'Activar Lupa',
  },
  toolbar_photoreadjustment_magnifying_text: {
    en: 'Activate the magnifying glass to get an enlarged view of the image.',
    es: 'Activar la lupa para obtener una visión ampliada de la imagen.',
  },
  toolbar_photoreadjustmen_gallery: {
    en: 'Photo Readjustment Gallery',
    es: 'Galería de fotorectificación',
  },
  scale_panel_title: {
    es: 'Cambiar escala',
    en: 'Change scale'
  },
  scale_panel_steps: {
    es: 'Marca dos puntos para definir la línea de longitud a escalar.',
    en: 'Mark two points to define the length line to scale.'
  },
  scale_panel_measure_length_title: {
    es: 'Longitud de medida',
    en: 'Measure length'
  },
  scale_panel_measure_length_unit_title: {
    es: 'sin unidad de medida',
    en: 'without measure numbers'
  },
  scale_panel_model_scale_title: {
    es: 'Escalado de modelos 3D',
    en: '3D model scale'
  },
  scale_panel_model_scale_message: {
    es: 'Defina un valor númerico y una unidad de medida a la linea marcada para escalar el modelo 3D.',
    en: 'Define a numeric value and an measurement number for the marked line to scale the 3D model.'
  },
  scale_panel_meters_unit: {
    es: 'METROS',
    en: 'METERS'
  },
  scale_panel_feet_unit: {
    es: 'PIES',
    en: 'FEET'
  },
  scale_panel_centimeters_unit: {
    es: 'CENTÍMETROS',
    en: 'CENTIMETERS'
  },
  scale_panel_inches_unit: {
    es: 'PULGADAS',
    en: 'INCHES'
  },
  scale_panel_accept_button: {
    es: 'ACEPTAR',
    en: 'ACCEPT'
  },
  scale_panel_delete_button: {
    es: 'ELIMINAR',
    en: 'DELETE'
  },
  scale_panel_enable_zoom_title: {
    es: 'Activar lupa',
    en: 'Activate lens'
  },
  scale_panel_enable_zoom_description: {
    es: 'Activar la lupa para obtener más precisión en definir el punto.',
    en: 'Activate lens to obtain more accuracy and define the point.'
  },
  scale_panel_enable_zoom_button: {
    es: 'ACTIVAR LUPA',
    en: 'ACTIVATE LENS'
  },
  toolbar_modify: {
    en: 'Modify',
    es: 'Modificar',
  },
  toolbar_view: {
    en: 'View',
    es: 'Vista',
  },
  toolbar_movement: {
    en: 'Movement',
    es: 'Movimiento',
  },
  toolbar_virtual_tour: {
    en: 'Virtual Tour',
    es: 'Tour Virtual',
  },
  virtual_tour_panel_title: {
    en: 'Make a Virtual Tour of the 3D model',
    es: 'Realiza un Tour Virtual del modelo 3D',
  },
  virtual_tour_panel_steps: {
    en: 'Move the 3D model to the chosen position and save it to create the animation.',
    es: 'Mueve el modelo de la posición elegida y guarde la posición para crear la animación 3D.',
  },
  virtual_tour_panel_save_position_button: {
    en: 'Save position',
    es: 'Guardar Posición',
  },
  virtual_tour_panel_virtual_tour_player: {
    en: 'Virtual Tour Video',
    es: 'Reproductor Tour Virtual',
  },
  virtual_tour_panel_key_position: {
    en: 'Position',
    es: 'Posición',
  },
  virtual_tour_panel_speed: {
    en: 'Speed',
    es: 'Velocidad',
  },
  virtual_tour_panel_speed_message: {
    en: 'Define the play speed from the animation',
    es: 'Define la velocidad de reproducción de la animación',
  },
  virtual_tour_panel_wait: {
    en: 'Wait',
    es: 'Espera',
  },
  virtual_tour_wait_message: {
    en: 'Define the transition time between each position',
    es: 'Define el tiempo de espera entre cada una de las posiciones',
  },
  virtual_tour_delete_positions: {
    en: 'Delete positions',
    es: 'Eliminar posiciones',
  },
  virtual_tour_delete_positions_message: {
    en: 'Delete all positions defined in the 3D model',
    es: 'Eliminar todas las posiciones definidas en el modelo 3D',
  },
  virtual_tour_delete_positions_button: {
    en: 'DELETE Positions',
    es: 'ELIMINAR Posiciones',
  },
  toolbar_image_processing: {
    en: 'Images processing',
    es: 'Tratamiento de Imágenes',
  },
  language_name: {
    en: 'Language',
    es: 'Idioma',
  },
  language_change_panel_steps: {
    en: 'Language',
    es: 'Cambiar el idioma de la plataforma eyescloud3D',
  },
  toolbar_rotation_center: {
    en: 'Rotation Center',
    es: 'Centro de Rotación',
  },
  rotation_center_panel_steps1: {
    en: 'Change the rotation center of the',
    es: 'Cambiar el centro de rotación del',
  },
  rotation_center_panel_steps2: {
    en: '3D model',
    es: 'modelo 3D',
  },
  rotation_center_panel_steps3: {
    en: 'Select the point to establish the New Rotation Center',
    es: 'Seleccione el punto para establecer el Nuevo centro de Rotación',
  },
  rotation_center_panel_change_center_button: {
    en: 'CHANGE ROTATION CENTER',
    es: 'CAMBIAR CENTRO DE ROTACIÓN',
  },
  rotation_center_panel_change_confirmation: {
    en: 'New Rotation Center is assigned correctly',
    es: 'Nuevo centro de rotación asignado correctamente',
  },
  toolbar_establish_starting_position: {
    en: 'Establishing Starting Position',
    es: 'Establecer Posición Inicial',
  },
  toolbar_reset: {
    en: 'Reset',
    es: 'Reestablecer'
  },
  reset_panel_steps: {
    en: 'Reestablish the movement parameters to the initial position in which the 3D model is loaded.',
    es: 'Reestablece los parámetros de movimiento a la posición inicial en la que se carga el modelo 3D.'
  },
  reset_panel_button: {
    en: 'REESTABLISH',
    es: 'REESTABLECER'
  },
  reset_panel_confirmation: {
    en: 'Parameters are reestablished correctly.',
    es: 'Parámetros reestablecidos correctamente.'
  },
  color_change_panel_title: {
    en: 'Change the background color of the 3D model environment',
    es: 'Cambia el color de fondo del entorno del modelo 3D'
  },
  change_color_name: {
    en: 'Background color',
    es: 'Color de fondo'
  },
  social_share_name: {
    en: 'Share',
    es: 'Compartir'
  },
  go_back_main_menu: {
    en: 'Go back to the menu',
    es: 'Volver al menú'
  },
  go_back_to_gallery: {
    en: 'Go back to the gallery',
    es: 'Volver a la galería'
  },
  multilayer_name: {
    en: 'Multilayer',
    es: 'Multicapa'
  },
  photo_readjustment_name: {
    en: 'Photo Readjustment',
    es: 'Fotorectificación'
  },
  social_share_panel_title: {
    en: 'Share your 3D models on your social networks or post it on your web',
    es: 'Comparte tus modelos 3D en tus redes sociales o publícalo en tu web'
  },
  multilayer_panel_title: {
    en: 'View differents work layers to integrate the 3D model',
    es: 'Visualiza diferentes capas de trabajo para integrar el modelo 3D'
  },
  multilayer_panel_title_integrate_cartography: {
    en: 'Integrate Cartography',
    es: 'Integrar Cartografía'
  },
  multilayer_panel_title_integrate_cartography_message: {
    en: 'Integrate your 3D model over the map according to geolocalization',
    es: 'Integre su modelo 3D sobre el mapa según la geolocalización.'
  },
  multilayer_panel_title_integrate_cartography_message2: {
    en: 'Only for models with geolocalization',
    es: 'Solo para modelos con geolocalización'
  },
  multilayer_panel_title_integrate_cartography_georeference_title: {
    en: 'Georeference model',
    es: 'Georreferenciar modelo'
  },
  multilayer_panel_title_integrate_cartography_georeference_message: {
    en: 'If your 3D model is not georeferenced you can use in scale group, the Georeference System tool',
    es: 'Si su modelo 3D no está georreferenciado puede utilizar en la escala la herramienta Sistema Georreferenciado.'
  },
  multilayer_panel_title_integrate_cartography_georeference_button: {
    en: 'GEOREFERENCE SYSTEM',
    es: 'SISTEMA GEORREFERENCIADO'
  },
  toolbar_height: {
    en: 'Height',
    es: 'Altura'
  },
  height_panel_steps: {
    en: 'Mark three points to define the floor surface and calculate the height from it.',
    es: 'Marca tres puntos para definir la superficie suelo y calcular la altura respecto a ella.'
  },
  height_panel_surface_title: {
    en: 'Surface',
    es: 'Superficie'
  },
  height_panel_floor_title: {
    es: 'suelo',
    en: 'floor'
  },
  height_panel_scale_length_title: {
    es: 'Longitud de medida',
    en: 'Measure length'
  },
  height_panel_scale_length_units_title: {
    es: 'sin unidad de medida',
    en: 'without measure numbers'
  },
  height_panel_tip: {
    es: 'RECOMENDACIÓN: Para obtener una longitud con unidad de medida defina una escala con la herramienta',
    en: 'TIP: To get a length with measure numbers, define a scale with the tool'
  },
  height_panel_tip2: {
    es: 'escala',
    en: 'scale'
  },
  height_panel_reset_points_title: {
    es: 'Reestablecer puntos',
    en: 'Reestablish points'
  },
  height_panel_reset_points_message: {
    es: 'Plantea nuevos puntos para definir el suelo y calcular de',
    en: 'Plan new points to define the floor and calculate'
  },
  height_panel_reset_points_message2: {
    es: 'nuevo la altura.',
    en: 'again its height.'
  },
  height_panel_reset_points_button: {
    es: 'REESTABLECER NUEVOS PUNTOS',
    en: 'REESTABLISH NEW POINTS'
  },
  toolbar_area: {
    en: 'Area',
    es: 'Área'
  },
  area_panel_steps: {
    es: 'Marca tres puntos mínimo para definir y calcular el área.',
    en: 'Mark more than three points minimum to define and calculate the area.'
  },
  area_panel_area_points_title: {
    es: 'Puntos de área',
    en: 'Area points'
  },
  area_panel_calculated_area: {
    es: 'Área calculada',
    en: 'Calculated area'
  },
  toolbar_volume: {
    en: 'Volume',
    es: 'Volumen'
  },
  volume_panel_steps: {
    en: 'Mark more than three points minimum to define the area and calculate the volume.',
    es: 'Marca tres puntos mínimo para definir el área y calcular su volumen.'
  },
  volume_panel_limit_points: {
    en: 'Limit points',
    es: 'Puntos límites'
  },
  volume_panel_calculated_volume: {
    en: 'Calculated volume',
    es: 'Volumen calculado'
  },
  help: {
    en: 'Help',
    es: 'Ayuda'
  },
  tools_panel_title: {
    en: 'Tools',
    es: 'Herramientas'
  },
  toolbar_coordinate: {
    en: 'Coordinate',
    es: 'Coordenada'
  },
  coordinate_panel_steps: {
    en: 'Mark a point over the 3D model to get the information of the coordinate.',
    es: 'Marca un punto sobre el modelo 3D para obtener la información de la coordenada.'
  },
  coordinate_panel_error: {
    en: 'The coordinate (x,y,z) selected is out of its 3D environment.',
    es: 'La coordenada (x,y,z) seleccionada está fuera del entorno 3D de su modelo.'
  },
  coordinate_panel_selected_xyz_point: {
    en: 'Selected point XYZ',
    es: 'Punto seleccionado XYZ'
  },
  coordinate_panel_select_xyz_coord: {
    en: 'Select a coordinate XYZ',
    es: 'Seleccionar coordenada XYZ'
  },
  coordinate_panel_point_title: {
    en: 'Point (x,y,z)',
    es: 'Punto (x,y,z)'
  },
  toolbar_reference_system: {
    en: 'Reference system',
    es: 'Sistema de referencia'
  },
  reference_system_panel_steps: {
    en: 'Select a point over the model and click add button to insert a new coordinate',
    es: 'Seleccione un punto sobre el modelo y haga click en añadir para insertar su nueva coordenada.'
  },
  reference_system_panel_steps_warning: {
    en: 'You need at least three points to apply the transformation',
    es: 'Necesita al menos 3 puntos para aplicar la transformación.'
  },
  reference_system_panel_apply_changes_button: {
    en: 'APPLY CHANGES',
    es: 'APLICAR CAMBIOS'
  },
  reference_system_panel_point_xyz: {
    en: 'Point (x,y,z)',
    es: 'Punto (x,y,z)'
  },
  reference_system_panel_new_coordinate: {
    es: 'Nueva coordenada',
    en: 'New coordinate'
  },
  reference_system_panel_add_points: {
    es: 'Añadir puntos',
    en: 'Add points'
  },
  reference_system_panel_add_points_steps: {
    es: 'Añade el punto seleccionado al cambio de sistema de referencia, pudiendo asignar su nueva coordenada 3D.',
    en: 'Add the selected point to the reference system change, allowing to assign its new 3D coordinate.'
  },
  reference_system_panel_add_point: {
    es: 'AÑADIR PUNTO',
    en: 'ADD POINT'
  },
  reference_system_panel_import_csv: {
    es: 'Importar CSV',
    en: 'Import CSV'
  },
  reference_system_panel_import_csv_steps: {
    es: 'Importar un fichero CSV con las nuevas coordenadas para los puntos añadidos. El fichero debe contener tantas líneas como puntos se hayan añadido, con el formato: X,X ; Y,Y ; Z,Z.',
    en: 'Import a CSV file with the new coordinates for the added points. The file must have as many lines as points added, with the X,X; Y,Y; Z,Z format.'
  },
  reference_system_panel_import_csvs: {
    es: 'IMPORTAR CSV',
    en: 'IMPORT CSV'
  },
  reference_system_panel_cancel: {
    es: 'Anular',
    en: 'Cancel'
  },
  reference_system_panel_cancel_steps: {
    es: 'Elimina todos los puntos y anula el cambio de sistema',
    en: 'Delete all points and cancel the system change'
  },
  reference_system_panel_cancel_button: {
    es: 'ANULAR',
    en: 'CANCEL'
  },
  toolbar_georeferencing_system: {
    en: 'Georeferencing system',
    es: 'Sistema georreferenciado'
  },
  toolbar_floor_plane: {
    en: 'Floor plane',
    es: 'Establecer suelo'
  },
  floor_panel_steps: {
    en: 'Mark three points to define the horizontal map and calculate the 3D model orientation.',
    es: 'Marca tres puntos para definir el plano horizontal y calcular la orientacion del modelo 3D.'
  },
  floor_panel_history: {
    en: 'Map record',
    es: 'Historial de planos'
  },
  floor_panel_perspective_title: {
    es: 'Perspectiva',
    en: 'Perspective'
  },
  floor_panel_perspective_warning: {
    es: 'Por defecto el modelo se carga en perspectiva',
    en: 'By default the model is loaded in perspective'
  },
  floor_panel_perspective_change_button: {
    es: 'CAMBIAR A PERSPECTIVA ORTOGONAL',
    en: 'CHANGE TO ORTHOGONAL PERSPECTIVE'
  },
  floor_panel_view_title: {
    es: 'Vista',
    en: 'View'
  },
  floor_panel_view_steps: {
    es: 'Posiciona el modelo 3D en el entorno definiendo una vista.',
    en: 'Place the 3D model in the environment defining a view.'
  },
  toolbar_notes: {
    en: 'Notes',
    es: 'Notas'
  },
  notes_panel_title: {
    en: 'Insert Notes',
    es: 'Insertar Notas'
  },
  notes_panel_steps1: {
    es: 'Haga click sobre el modelo 3D',
    en: 'Click over the 3D model'
  },
  notes_panel_steps2: {
    es: 'o defina la coordenada exacta',
    en: 'or define the exact coordinate'
  },
  notes_panel_steps3: {
    es: 'para insertar una nota.',
    en: 'to insert a note'
  },
  notes_panel_create_comment: {
    es: 'Crear un comentario',
    en: 'Create a comment'
  },
  notes_panel_note_size: {
    es: 'Tamaño del indicador de nota',
    en: 'Note marker size'
  },
  notes_panel_note_color: {
    es: 'Color indicador',
    en: 'Marker color'
  },
  notes_panel_create_note: {
    es: 'CREAR NOTA',
    en: 'CREATE NOTE'
  },
  notes_panel_notes_history: {
    es: 'Historial de Notas',
    en: 'Notes history'
  },
  notes_panel_see_notes: {
    es: 'Ver notas',
    en: 'See notes'
  },
  notes_panel_see_notes_message: {
    es: 'Muestra el contenido de todas las notas',
    en: 'Show the content of all the notes'
  },
  notes_panel_see_notes_button: {
    es: 'VER NOTAS',
    en: 'SEE NOTES'
  },
  notes_panel_hide_notes_button: {
    es: 'OCULTAR NOTAS',
    en: 'HIDE NOTES'
  },
  notes_panel_remove_notes_title: {
    es: 'Eliminar notas',
    en: 'Delete notes'
  },
  notes_panel_remove_notes_question1: {
    es: '¿Desea eliminar todas las notas',
    en: 'Would you like to delete all the notes'
  },
  notes_panel_remove_notes_question2: {
    es: 'de este proyecto?',
    en: 'of this project?'
  },
  notes_panel_remove_notes_warning: {
    es: 'Esta acción no se puede deshacer',
    en: 'This action will not be able to be undone'
  },
  notes_panel_remove_notes_button: {
    es: 'ELIMINAR',
    en: 'DELETE'
  },
  toolbar_clipping: {
    en: 'Clipping',
    es: 'Recorte'
  },
  clipping_panel_steps1: {
    en: 'Select from three to seven points',
    es: 'Seleccione de 3 a 7 puntos'
  },
  clipping_panel_steps2: {
    en: 'to establish the area that',
    es: 'para establecer el área que'
  },
  clipping_panel_steps3: {
    en: 'you wish to trim.',
    es: 'desea recortar.'
  },
  clipping_panel_question1: {
    en: 'Would you like to trim the obtained',
    es: '¿Desea recortar la selección'
  },
  clipping_panel_question2: {
    en: 'selection?',
    es: 'realizada?'
  },
  clipping_panel_clip_button: {
    en: 'TRIM',
    es: 'RECORTAR'
  },
  clipping_panel_delete_clips: {
    en: 'Delete trims',
    es: 'Eliminar recortes'
  },
  clipping_panel_delete_clips_question1: {
    en: 'Would you like to delete all trims?',
    es: '¿Desea eliminar todos los recortes?'
  },
  clipping_panel_delete_clips_question2: {
    en: 'This action wil not be able to be undone.',
    es: 'Esta acción no podrá deshacerse.'
  },
  clipping_panel_delete_clips_button: {
    en: 'DELETE ALL',
    es: 'ELIMINAR TODOS'
  },
  toolbar_join_models: {
    en: 'Join models',
    es: 'Unir modelos'
  },
  join_models_panel_steps: {
    en: 'To join models it is compulsory to select two 3D models. The model on the screen corresponds to the first model.',
    es: 'Para unir modelos es necesario seleccionar 2 modelos 3D. El modelo en pantalla corresponde al primer modelo.'
  },
  join_models_panel_steps2: {
    en: 'Select three points to be able to join it to another 3D model.',
    es: 'Selecciona 3 puntos para poder unirlo a otro modelo 3D.'
  },
  join_models_panel_add_point: {
    en: 'Add point',
    es: 'Añadir punto'
  },
  join_models_panel_delete_last_point: {
    en: 'Delete last point',
    es: 'Borrar último punto'
  },
  join_models_panel_delete_all_points: {
    en: 'Delete all',
    es: 'Borrar todos'
  },
  join_models_panel_save_join_points: {
    en: 'Save joining points',
    es: 'Guardar puntos para la unión'
  },
  join_models_panel_save_join_points_name: {
    en: 'Assign a name to the project for the joining points',
    es: 'Asigne un nombre al proyecto de los puntos de unión'
  },
  join_models_panel_save_join_points_button: {
    en: 'Save joining points',
    es: 'Guardar puntos de unión'
  },
  join_models_panel_joining_points_placeholder: {
    en: 'Joining points name',
    es: 'Nombre puntos de unión'
  },
  join_models_panel_select_second_model_title: {
    en: 'Select second 3D model',
    es: 'Selecciona segundo modelo 3D'
  },
  join_models_panel_select_second_model_message: {
    en: 'Select second 3D model from the gallery',
    es: 'Seleccione el segundo modelo 3D desde la galería'
  },
  join_models_panel_select_second_model_button: {
    en: 'GO TO GALLERY',
    es: 'IR A GALERÍA'
  },
  join_models_destination_panel_steps: {
    en: 'Select the model that you wish to import and then, select three points to join it.',
    es: 'Selecciona el modelo que desea importar y después, elige 3 puntos para unirlo.'
  },
  join_models_destination_panel_add_points: {
    en: 'Add point',
    es: 'Añadir punto'
  },
  join_models_destination_panel_delete_last_point: {
    en: 'Delete last point',
    es: 'Borrar último punto'
  },
  join_models_destination_panel_delete_all_points: {
    en: 'Delete all',
    es: 'Borrar todos'
  },
  join_models_destination_panel_add_model: {
    en: 'Add 3D model',
    es: 'Añadir modelo 3D'
  },
  join_models_destination_panel_remove_model: {
    en: 'Remove 3D model',
    es: 'Eliminar modelo 3D'
  },
  toolbar_orthographic_projection: {
    en: 'Orthographic projection',
    es: 'Proyección ortográfica'
  },
  orthographic_projection_panel_steps1: {
    en: 'Represents the 3D model',
    es: 'Representa el modelo 3D'
  },
  orthographic_projection_panel_steps2: {
    en: 'in a surface by a',
    es: 'en un plano mediante'
  },
  orthographic_projection_panel_steps3: {
    en: 'orthographic projection',
    es: 'proyección ortogonal.'
  },
  orthographic_projection_panel_not_show_again: {
    en: 'Do not show again this page',
    es: 'No volver a mostrar esta ventana'
  },
  toolbar_perspective_projection: {
    en: 'Perspective projection',
    es: 'Proyección perspectiva'
  },
  perspective_projection_panel_steps1: {
    en: 'Represent the 3D model',
    es: 'Representa el modelo 3D'
  },
  perspective_projection_panel_steps2: {
    en: 'in a surface by a',
    es: 'en un plano mediante'
  },
  perspective_projection_panel_steps3: {
    en: 'perspective projection.',
    es: 'proyección perspectiva.'
  },
  perspective_projection_panel_not_show_again: {
    en: 'Do not show again this page',
    es: 'No volver a mostrar esta ventana'
  },
  toolbar_section: {
    en: 'Section',
    es: 'Sección'
  },
  toolbar_elevation_map: {
    en: 'Elevation map',
    es: 'Mapa de elevacion'
  },
  elevation_map_panel_steps: {
    en: 'Elevations map gives the number of the altitude to a ground level from 0m numbers, minimum number from the maximum ground altitude in the 3D model.',
    es: 'El mapa de elevaciones proporciona el valor de la altitud a nivel del suelo a partir de valores de 0m, valor mínimo, hasta un valor máximo correspondiente a la altitud máxima del terreno en el modelo 3D.'
  },
  elevation_map_panel_min_dim: {
    en: 'DIMENSIUM MIN',
    es: 'COTA MÍN'
  },
  elevation_map_panel_max_dim: {
    en: 'DIMENSIUM MAX',
    es: 'COTA MÁX'
  },
  elevation_map_panel_accept_button: {
    en: 'ACCEPT',
    es: 'ACEPTAR'
  },
  elevation_map_panel_exit_button: {
    en: 'EXIT ELEVATION MAP',
    es: 'SALIR DEL MAPA DE ELEVACION'
  },
  toolbar_vertexs: {
    en: 'Vertexs',
    es: 'Vértices'
  },
  toolbar_establish_initial_position: {
    en: 'Establish initial position',
    es: 'Establecer posición inicial'
  },
  initial_position_panel_steps: {
    en: 'Establish this position as initial position of view in which the model is loaded.',
    es: 'Establecer esta posición como posición inicial de vista en la que se carga el modelo.'
  },
  initial_position_panel_establish: {
    en: 'ESTABLISH',
    es: 'ESTABLECER'
  },
  initial_position_panel_establish_confirmation: {
    en: 'The camera position is saved correctly.',
    es: 'La posición de la cámara se ha guardado correctamente.'
  },
  toolGroupMeasure: {
    en: 'Measure',
    es: 'Medida'
  },
  toolGroupScale: {
    en: 'Scale',
    es: 'Escala'
  },
  toolGroupModify: {
    en: 'Modify',
    es: 'Modificar'
  },
  toolGroupMovement: {
    en: 'Movement',
    es: 'Movimiento'
  },
  toolGroupVisualization: {
    en: 'Visualization',
    es: 'Vista'
  },
  toolGroupDefault: {
    en: 'Tools',
    es: 'Herramientas'
  },
  toolGroupMeasureFooter: {
    en: 'Measurement tools',
    es: 'Herramientas de medida'
  },
  toolGroupScaleFooter: {
    en: 'Scale and reference system tools',
    es: 'Herramientas de escala y sistema de referencia ...'
  },
  toolGroupModifyFooter: {
    en: 'Tools for the 3D model adjustment',
    es: 'Herramientas de modificación del modelo 3D'
  },
  toolGroupMovementFooter: {
    en: '3D model movement tools',
    es: 'Herramientas de movimiento del modelo 3D'
  },
  toolGroupViewFooter: {
    en: 'View tools',
    es: 'Herramientas de visualización'
  },
  downloadPorgressMessage: {
    es: 'Preparando descarga...',
    en: 'Preparing download...'
  },
  management: {
    en: 'My management',
    es: 'Mi gestión'
  },
  chatOnline: {
    en: 'Chat online',
    es: 'Chat online'
  },
  chatOnlineClose: {
    en: 'Close chat',
    es: 'Cerrar chat'
  },
  recordName: {
    en: 'Record name',
    es: 'Nombre de expediente'
  },
  recordNamePlaceholder: {
    en: 'Automatically generated',
    es: 'Se genera automáticamente'
  },
  recordNumber: {
    en: 'Record No.:',
    es: 'Nº de expediente:'
  },
  rememberMe: {
    en: 'Remember me',
    es: 'Recuérdame',
  },
  forgotPassword: {
    en: 'Forgot password?',
    es: '¿Olvido su contraseña?'
  },
  forgotPasswordIntroduction1: {
    en: 'Write your username and click in get code button',
    es: 'Escriba su usuario y haga click en obtener código.'
  },
  forgotPasswordIntroduction2: {
    en: 'We send you an email with instructions for reset your password',
    es: 'Le enviaremos un email con instrucciones para restablecer su contraseña.'
  },
  getCode: {
    en: 'Get code',
    es: 'Obtener código'
  },
  reset: {
    en: 'Reset',
    es: 'Reestablecer'
  },
  forgotPasswordMessage1: {
    en: 'We have send you an email.',
    es: 'Te hemos enviado un correo.'
  },
  forgotPasswordMessage2: {
    en: 'Please, check your inbox and follow the instructions in the mail',
    es: 'Por favor, revisa tu bandeja de entrada sigue las instrucciones en el correo.'
  },
  accept: {
    en: 'Accept',
    es: 'Aceptar',
  },
  cancel: {
    en: 'Cancel',
    es: 'Cancelar',
  },
  email: {
    en: 'Email',
    es: 'Correo Electrónico'
  },
  passwordConfirmation: {
    en: 'Confirm password',
    es: 'Confirme contraseña',
  },
  newPasswordTitle: {
    en: 'Write your new password',
    es: 'Escriba su nueva contraseña'
  },
  newPasswordText1: {
    en: 'Please, introduce the new password in both fields.',
    es: 'Por favor, introduzca la nueva contraseña en ambos campos.'
  },
  newPasswordText2: {
    en: 'After that, click in Save Button and you will can login with the new password.',
    es: 'Después, haga click en Guardar y podrá entrar con la nueva contraseña.'
  },
  resetPassword: {
    en: 'Reset password',
    es: 'Reestablecer contraseña'
  },
  pointCloud: {
    en: 'Points cloud',
    es: 'Nube de puntos'
  },
  mesh: {
    en: 'Mesh',
    es: 'Malla'
  },
  videos: {
    en: 'videos',
    es: 'videos'
  },
  photos: {
    en: 'Photos',
    es: 'Fotos'
  },
  view: {
    en: 'View',
    es: 'Ver'
  },
  camera: {
    en: 'Camera',
    es: 'Cámara'
  },
  date: {
    en: 'Date',
    es: 'Fecha'
  },
  modelCategory: {
    en: 'model Category',
    es: 'Categoría del modelo'
  },
  accessibleByUrl: {
    en: 'Accessible by URL',
    es: 'Accesible por URL'
  },
  visibleDepartment: {
    en: 'Visible for the department',
    es: 'Visible para el departamento'
  },
  modelData: {
    en: 'model Data',
    es: 'Datos del modelo'
  },
  bigModels: {
    en: 'big models',
    es: 'modelos grandes'
  },
  smallModels: {
    en: 'small models',
    es: 'modelos pequeños'
  },
  modelGeneration: {
    en: 'Generation of 3d model',
    es: 'Generación del modelo'
  },
  free: {
    en: 'Free',
    es: 'Gratis'
  },
  download: {
    en: 'Download',
    es: 'Descargar'
  },
  downloadIsPossibleMessage: {
    en: 'THE PLAN DOES NOT ALLOW DOWNLOADING',
    es: 'EL PLAN NO PERMITE DESCARGA.'
  },
  downloadIsPossibleMessageChangePlan: {
    en: 'CHANGE PLAN',
    es: 'CAMBIAR PLAN'
  },
  bonusDownload: {
    en: 'bonus download',
    es: 'bono descarga'
  },
  model: {
    en: 'model',
    es: 'modelo'
  },
  models: {
    en: 'models',
    es: 'modelos'
  },
  businessPlan: {
    en: 'business plan',
    es: 'plan empresarial'
  },
  totalBusinessPlan: {
    en: '4Pro business fare',
    es: 'plan empresarial total'
  },
  customerService: {
    en: 'Customer Service',
    es: 'Atención al Cliente'
  },
  duration: {
    en: 'duration',
    es: 'duración'
  },
  storage: {
    en: 'storage',
    es: 'almacenamiento'
  },
  storage100GbFree2Years: {
    en: '100 GB free in the next 2 years ',
    es: '100 GB gratis durante 2 años'
  },
  monthlySubscription: {
    en: 'monthly subscription',
    es: 'suscripción mensual'
  },
  yearlySubscription: {
    en: 'yearly subscription',
    es: 'suscripción anual'
  },
  storage150GbFree2Years: {
    en: '150 GB free in the next 2 years ',
    es: '150 GB gratis durante 2 años'
  },
  year: {
    en: 'year',
    es: 'año'
  },
  address: {
    en: 'Address',
    es: 'Dirección'
  },
  postalCode: {
    en: 'P.C.',
    es: 'C.P.'
  },
  video: {
    en: 'video',
    es: 'vídeo'
  },
  modelsInProgress: {
    en: '3d models in progress',
    es: 'Modelos 3d en progreso'
  },
  estimatedTime: {
    en: 'Process estimated time',
    es: 'Tiempo estimado de proceso'
  },
  classifyYour3dModel: {
    en: 'Classify your 3d model',
    es: 'Clasifica tu modelo 3d'
  },
  goTo3dModel: {
    en: 'Go to 3d model',
    es: 'Ir al modelo 3d'
  },
  upload3dModel: {
    en: 'Upload 3d model',
    es: 'Subir modelo 3d'
  },
  keepAllYour3dModels: {
    en: 'Keep all your 3D models',
    es: 'Mantén todos tus modelos 3D'
  },
  inASingleViewer: {
    en: 'in a single viewer',
    es: 'en un único visualizador'
  },
  uploadModel3dMessage: {
    en: 'Upload your 3D models to integrate them into eyesCloud3D',
    es: 'Sube tus modelos 3D para integrarlos en eyesCloud3D'
  },
  validFormats: {
    en: 'Valid Formats',
    es: 'Formatos válidos'
  },
  yourRecordsInProcess: {
    en: 'Your records in process',
    es: 'Tus expedientes en proceso'
  },
  toolbar_photoreadjustment_select_image: {
    en: 'Select an image to calculate length',
    es: 'Seleccione una imagen para calcular la longitud'
  },
  toolbar_photoreadjustment_text: {
    en: 'Select an image to calculate the readjustment',
    es: 'Seleccione una imagen para calcular la rectificación'
  },
  toolbar_photoreadjustment_selected_image: {
    en: 'Selected image',
    es: 'Foto seleccionada'
  },
  toolbar_photoreadjustment_back_text: {
    en: 'Back to images selection',
    es: 'Volver a selección de imágenes'
  },
  toolbar_photoreadjustment_back_text_readjustment: {
    en: 'Back to photoreadjustment',
    es: 'Volver a fotorectificación'
  },
  toolbar_photoreadjustment_select_area: {
    en: 'Select area',
    es: 'Seleccionar área'
  },
  toolbar_photoreadjustment_select_area_text: {
    en: 'Define the area you want to work with',
    es: 'Defina el área con la que desea trabajar'
  },
  toolbar_photoreadjustment_select_area_button: {
    en: 'Define area',
    es: 'Definir área'
  },
  toolbar_photoreadjustment_select_trapeze: {
    en: 'Select Trapezium to Photorectify',
    es: 'Selecciona Trapecio para Fotorectificar'
  },
  toolbar_photoreadjustment_select_trapeze_text: {
    en: 'Define the trapezoid and then assign a measurement to each side of the trapezoid to calculate Photorectification',
    es: 'Defina el trapecio y después asigne una medida a cada lado del trapecio para calcular la Fotorectificación'
  },
  toolbar_photoreadjustment_trapeze_delete: {
    en: 'Delete trapeze',
    es: 'Eliminar trapecio'
  },
  toolbar_photoreadjustment_reset_tool: {
    en: 'Reset tool',
    es: 'Resetear herramienta'
  },
  toolbar_photoreadjustment_reset_tool_text: {
    en: 'Complete resetting of the photorectification of the general area and trapezius',
    es: 'Reseteo completo de la fotorectificación área general y trapecio'
  },
  toolbar_photoreadjustment_reset_tool_button: {
    en: 'Reset photoreadjustment',
    es: 'Resetear fotorectificación'
  },
  toolbar_photoreadjustment_scene_manager_button: {
    en: 'Scene Manager',
    es: 'Gestor de escena'
  },
  toolbar_photoreadjustment_scene_manager_text: {
    en: 'Scene Management and Visualization Tool',
    es: 'Herramienta de gestión y Visualización de escena'
  },
  toolbar_photoreadjustment_view: {
    en: 'View',
    es: 'Vista'
  },
  toolbar_photoreadjustment_view_text: {
    en: 'Position the 3D model in the environment by defining a view',
    es: 'Posiciona el modelo3D en el entorno definiendo una vista'
  },
  toolbar_photoreadjustment_view_perspective: {
    en: 'Perspective',
    es: 'Perspectiva'
  },
  toolbar_photoreadjustment_tools: {
    en: 'Tools',
    es: 'Herramientas'
  },
  toolbar_photoreadjustment_tools_text: {
    en: 'It performs measure operations and angles calculations',
    es: 'Realiza operaciones de medición y cálculo de ángulos'
  },
  toolbar_photoreadjustment_save: {
    en: 'Save in the gallery',
    es: 'Guardar en Galería'
  },
  toolbar_photoreadjustment_save_text: {
    en: 'Save the photorectification project in the gallery',
    es: 'Guarda el proyecto de Fotorectificación en la galería'
  },
  toolbar_photoreadjustment_save_button: {
    en: 'Save',
    es: 'Guardar'
  },
  toolbar_photoreadjustment_save_as_button: {
    en: 'Save as',
    es: 'Guardar como'
  },
  toolbar_photoreadjustment_add_items: {
    en: 'Add items',
    es: 'Añadir objetos'
  },
  toolbar_photoreadjustment_items_history: {
    en: 'Items history',
    es: 'Historial de objetos'
  },
  myProjects: {
    en: 'My projects',
    es: 'Mis proyectos'
  },
  records: {
    en: 'Projects',
    es: 'Expedientes'
  },
  allProcessingRecords: {
    en: 'All your records in process',
    es: 'Todos tus expedientes en proceso'
  },
  goToRecord: {
    en: 'Go to record',
    es: 'Ir al expediente'
  },
  project: {
    en: 'Project',
    es: 'Proyecto'
  },
  noProjectsMainText: {
    en: 'You currently have no completed records',
    es: 'Actualmente no tiene expedientes realizados'
  },
  noProjectsSecondaryText: {
    en: 'Generate a new cart project and analyze all its signals.',
    es: 'Genera un nuevo expediente y analiza todos sus daños.'
  },
  emptyPayments: {
    en: 'No payments have been made.',
    es: 'No se han realizado pagos.'
  },
  emptyPaymentsDesc1: {
    en: 'It is time to customize the rate to your needs.',
    es: 'Es momento de personalizar la tarifa a tus necesidades.'
  },
  emptyPaymentsDesc2: {
    en: 'Generate and download 3D models with adapted plans and bonuses.',
    es: 'Genera y descarga modelos 3D con bonos y planes adaptados.'
  },
  emptyPaymentButton: {
    en: 'SELECT YOUR RATE',
    es: 'SELECCIONA TU TARIFA'
  },
  road: {
    en: 'Road',
    es: 'Carretera'
  },
  inspectionType: {
    en: 'Inspection Type',
    es: 'Tipo de inspección'
  },
  damage: {
    en: 'Damage assessment:',
    es: 'Valoración de daños:'
  },
  carHit: {
    en: 'Hit',
    es: 'Golpe'
  },
  totalDamage: {
    en: 'Total damage;',
    es: 'Total de daños:'
  },
  damageFrames: {
    en: 'DAMAGE FRAMES',
    es: 'FRAMES DE DAÑOS'
  },
  downloadDamage: {
    en: 'Download damage assessment',
    es: 'Descargar valoración de daños'
  },
  carVideo: {
    en: 'Vehicle video:',
    es: 'Vídeo del vehículo:'
  },
  unsupportedBrowser: {
    en: 'Your browser does not support the video tag.',
    es: 'Su navegador no soporta la etiqueta de vídeo.'
  },
  changeLanguage: {
    en: 'Seleccionar idioma',
    es: 'Select Language'
  },
  recordSubMgmt: {
    en: 'Report submission management',
    es: 'Gestión envío de informes'
  },
  stats: {
    en: 'Statistics',
    es: 'Estadísticas'
  },
  sentLinks: {
    en: 'Sent Links',
    es: 'Enlaces enviados'
  },
  licensePlate: {
    en: 'License plate',
    es: 'Nº de matrícula'
  },
  policies: {
    en: 'Policies',
    es: 'Pólizas'
  },
  selector_daños_data1: {
    en: '',
    es: 'Pieza'
  },
  selector_daños_data2: {
    en: '',
    es: 'Tipo Daño'
  },
  selector_daños_data3: {
    en: '',
    es: 'Superficie'
  },
  selector_daños_data4: {
    en: 'You currently have no completed records',
    es: 'Actualmente no tiene expedientes procesandose'
  },
  selector_daños_data5: {
    en: 'Total cost: ',
    es: 'Coste total: '
  },
  selector_daños_data6: {
    en: 'Price',
    es: 'Precio'
  },
};

<div mat-dialog-content>
  <div class="header-container">
    <p class="title">{{'updates' | i18n}}</p>
    <a (click)="onNoClick()">X</a>
  </div>
  <div class="faq-container">
    <ngx-simplebar [options]="options">
      <p class="date">14 de Julio de 2019</p>
      <p><b>Optimización del sistema de Inteligencia Artificial</b></p>
      <p>Se han mejorado y reentrenado las redes neuronales, optimizando el sistema basado en Inteligencia Artificial.</p>
      <p><b>Amplicación del conjunto de señales detectada para el código de circulación de Chile</b></p>
      <p>Se ha incrementado la señalética detectada tanto para las señales preventivas (añadiendo las PG-4a, PG-8a, PF-5 y PI-4e) como para las reglamentarias (añadiendo la RR-1-80 y RO-6c).</p>  

      <div class="line"></div>

      <p class="date">07 de Julio de 2019</p>
      <p><b>Procesamiento de videos nocturnos</b></p>
      <p>Ya es posible procesar videos nocturnos en la plataforma. Para ello, será necesario disponer previamente del mismo tramo procesado en modo diurno.</p>
      <p><b>Opción para agregar más videos desde pantalla de datos</b></p>
      <p>Ha sido añadida la opción para acceder a la subida de vídeos desde la ventana de datos. Permitirá añadir más videos a la carretera y proyecto que se estaba visualizando.</p>
      <p><b>Validación grupal de señales</b></p>
      <p>Activando la opción "Selección múltiple" en la pantalla de datos, es posible interactuar con varias señales al mismo tiempo, permitiendo así validar varias señales de una sola vez.</p>
      <p><b>Opción para insertar señales de forma manual</b></p>
      <p>Permite insertar señales agregando todos los datos de forma manual. Esta señal quedará asociada al vídeo, carretera y proyecto actual.</p>

      <div class="line"></div>

      <p class="date">30 de Junio de 2019</p>
      <p><b>Procesamiento de videos nocturnos</b></p>
      <p>Procesamiento de reinspecciones de tramos nocturnos, aportando información sobre la retroreflecitivdad de la señal (en esta fase como valores 0 sin reflectividad, o 100 con reflectividad).</p>
      <p><b>Visualización de datos nocturnos</b></p>
      <p>Generación de la información proporcionada por las comparativas nocturnas, permitiendo consultar la retroreflectividad, estado, velocidad y momento de toma.</p>
      <p><b>Añadida la función de modificación de tramo</b></p>
      <p>Se añade la opción de modificación de tramo en el procesamiento de los vídeos, que consiste en el reemplazo total de las señales previamente detectadas por las detectadas en esta nueva inspección en modo "modificación de tramo".</p>
      <p><b>Archivado y consulta de inventario</b></p>
      <p>Permite archivar el inventario de una carretera en un momento concreto, así como poder consultar sus datos en cualquier momento a través de un histórico de inventario.</p>
      <p><b>Envío de notificaciones por email</b></p>
      <p>Se añade la funcionalidad de comunicaciones por correo electrónico de completado de procesamiento de inspecciones.</p>

      <div class="line"></div>

      <p class="date">23 de Junio de 2019</p>
      <p><b>Procesamiento de comparativa diurna</b></p>
      <p>Procesamiento de reinspecciones de tramos diurnos, aportando información de nuevas señales, señales faltantes o estado de señales ya detectadas en inspecciones anteriores.</p>
      <p><b>Mejoras en la detección de datos GPS</b></p>
      <p>Incluidas algunas mejoras en la detección de datos GPS sobreimpresos en imagen.</p>
      <p><b>Visualización de imagen de ubicación de señal no encontrada</b></p>
      <p>Cuando en una comparativa una señal no es encontrada, se muestra el frame del vídeo que corresponde a la ubicación donde debería estar dicha señal.</p>
      <p><b>Visualización de señales sobre Google Maps embebido y exportación en formato KMZ</b></p>
      <p>Permite visualizar sobre Google Maps la ubicación de las señales detectadas, así como exportar en formato KMZ estos datos para procesarlos en aplicaciones de terceros como Google Earth.</p>
      <p><b>Visualización de videos de salida</b></p>
      <p>Visualización online de los vídeos resultantes de procesamiento, tanto del video original como de sus comparativas.</p>      

      <div class="line"></div>

      <p class="date">16 de Junio de 2019</p>
      <p><b>Plataforma online con gestión de usuarios y procesamiento Cloud</b></p>
      <p>Lanzamiento de la versión beta de la plataforma online, con gestión de usuarios, empresas y roles.</p>
      <p><b>Procesamiento de videos diurnos (primera inspección y ampliación)</b></p>
      <p>Capacidad de procesamiento de video dirunos en modalidad nueva inspección o ampliación de tramo.</p>
      <p><b>Visualización de datos e señales detectadas</b></p>
      <p>Pantalla de visualización de señales detectadas, estructurada en 3 bloques: vídeos procesados, señales detectadas, frames asociados a cada señal.</p>
      <p><b>Detección automática de datos GPS</b></p>
      <p>Lectura automática de datos GPS proporcionados por la cámara y asígnación directa con la señal detectada.</p>
      <p><b>Interacción con señales detectadas</b></p>
      <p>Opciones para validar, modificar o eliminar señales, tanto desde opción en interfaz como por teclado (teclas V, E y D).</p>

      <div class="line"></div>
    </ngx-simplebar>
    <div class="orange-line"></div>
  </div>
</div>

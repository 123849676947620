export class IncidenceMessage {

  public incidence_id: number;
  public time: string;
  public user_id: number;
  public content: string;

  public constructor(incidence_id?: number, time?: string, userId?: number, content?: string) {
    this.incidence_id=incidence_id;
    this.time=time;
    this.user_id = userId;
    this.content = content;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '../../core/services/loader.service';
import { AuthenticationService } from '../../services/security/authentication.service';
import { User } from '../../shared/models/user/user';
import { UserRemoteService } from '../../core/services/remote/user/user.remote.service';
import { ResponsiveService } from '../../core/services/responsive.service';
import { ProjectThumbnail } from 'src/app/models/project-thumbnail';
import { Record } from 'src/app/models/records/record';
import { Inspection } from 'src/app/models/inspection';
import { Road } from 'src/app/models/road';
import { RecordRemoteService } from '../../core/services/remote/record/record-remote.service';
import { RecordFilesRemoteService } from '../../core/services/remote/record/record-files-remote.service';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-projects-processing',
  templateUrl: './projects-processing.component.html',
  styleUrls: ['./projects-processing.component.scss']
})
export class ProjectsProcessingComponent implements OnInit, OnDestroy {

  currentRecord: Record = null;
  otherRecords: Record[] = [];
  lastProjectThumbSrc: any;
  user: User;
  projectsProcessingImages: ProjectThumbnail[] = [];
  showGoogleAdsense = true;
  mainImage: string;
  processingRecords: Record[] = [];

  private intervalSubscription: Subscription;

  constructor(
    private loaderService: LoaderService,
    private projectRemoteService: RecordRemoteService,
    public responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.loaderService.showLoader();
    this.showGoogleAdsense = this.responsiveService.isl() || this.responsiveService.isxl();
    this.loadData();
    this.intervalSubscription = interval(10000).subscribe(() => {
      this.checkPercentage();
    });
  }

  loadData() {
    this.projectRemoteService.getProcessingRecords().subscribe(projects => {
      if (projects.length) {
        this.processingRecords = projects;
        this.currentRecord = this.processingRecords[0]; // Me quedo con el primer expediente recuperado
      }
      this.fillOtherProjects();
      this.loaderService.hideLoader();
    }, () => this.loaderService.hideLoader());
  }

  fillOtherProjects() {
    // Si el usuario tiene menos de 5 expedientes procesandose se le completa con sus ultimos expedientes hechos
    const countModelsToFill = 5 - this.processingRecords.length;
    if (countModelsToFill > 0) {
      this.projectRemoteService.getProcessingRecords().subscribe((lastRecords: Record[]) => {
        // this.projectRemoteService.getLastRecords().subscribe((lastRecords: Record[]) => { // TODO No está listo aún
        if (lastRecords.length >= countModelsToFill) {
          lastRecords = lastRecords.slice(0, countModelsToFill);
          if (!this.currentRecord) {
            this.currentRecord = lastRecords[0];
          }
        }
        lastRecords.forEach(record => {
          if (this.processingRecordsContains(record) === false) {
            this.processingRecords.push(record);
          }
        });
        this.loaderService.hideLoader();
      }, () => this.loaderService.hideLoader());
    } else {
      this.loaderService.hideLoader();
    }
  }

  projectClick(record: Record) {
    this.currentRecord = record;
  }

  projectRefresh(record: Record) {
    if (this.currentRecord.id === record.id) {
      this.currentRecord = record;
    }
  }

  setDefaultMainImage() {
    this.mainImage = '/assets/images/gallery-item-default-image.png';
  }

  processingRecordsContains(newrecord: Record): Boolean {
    let found = false;
    this.processingRecords.forEach(record => {
      if (record.id === newrecord.id)
        found = true;
    });
    return found;
  }

  checkPercentage() {
    this.projectRemoteService.getProcessingRecords().subscribe(projects => {
      const updatedRecord = projects.find(record => record.id === this.currentRecord.id);
      if (updatedRecord && updatedRecord.percentage_processed !== this.currentRecord.percentage_processed) {
        this.currentRecord = updatedRecord;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
}

<!--
<iframe src="https://www.eyescloud3d.com/help.php" style="width: 100vw; height: 100vh;" frameborder="0"></iframe>
-->

<div class="container">
    <div class="row">
        <div class="col-md-2 menu">
            <button *ngFor="let opt of options; let i = index" class="buttonMenu"
                [class.selectedBtn]="selectedOption == i" (click)="selectedOption = i" mat-button>{{opt}}</button>
        </div>

        <div class="col-md-10">
            <div id="novedades" class="content" *ngIf="selectedOption == 0">
                <legend>{{options[selectedOption]}}</legend>
                <hr>
                <div class="timeline w-100">
                    <div id="barra"></div>
                    <div *ngFor="let n of news; let i = index" class="row m-0" [ngClass]="{'justify-content-start': i%2==0,'justify-content-end': i%2!=0}">
                        <div class="col-lg-6 col-12 p-0 ml-2 mr-2">
                                <div class="content novedad-col">
                                    <div [innerHTML]="n"></div>
                                </div>
                                <div id="circle"></div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div id="FAQ" class="content" *ngIf="selectedOption == 1">
                <legend>{{options[selectedOption]}} </legend>
                <hr>
                <ul>
                    <li *ngFor="let faq of faqs; let i = index"><a [routerLink]="['/']"
                            fragment="'faq-'+i">{{faq.question}} </a></li>
                </ul>
                <div class="answer" *ngFor="let faq of faqs; let i = index" [id]="'faq-'+i">
                    <b>
                        <p>{{faq.question}}</p>
                    </b>
                    <p>{{faq.answer}}</p>
                    <br>
                </div>
            </div>

            <div id="guiaUso" class="content" *ngIf="selectedOption == 2">
                <legend>{{options[selectedOption]}}</legend>
                <hr>
                Esto debería ser un swiper. Como en galería
                <div class="swiper-container-1">
                    <!-- Additional required wrapper -->
                    <div class="swiper-wrapper">
                        <!-- Slides -->
                        <ng-container *ngFor="let c of counter(13); let i = index" >
                            <div class="swiper-slide">
                                <img src="assets/images/help/carrousel1/tip{{(i+1)}}.jpg" alt="Tip">
                            </div>
                        </ng-container>
                    </div>

                    <div class="swiper-pagination-1"></div>
                    <div class="swiper-button-prev-1"></div>
                    <div class="swiper-button-next-1"></div>
                </div>
            </div>

            <div id="consejoCaptura" class="content" *ngIf="selectedOption == 3">
                <legend>{{options[selectedOption]}}</legend>
                <hr>
                Esto debería ser un swiper. Como en galería
                <div class="swiper-container-2">
                    <!-- Additional required wrapper -->
                    <div class="swiper-wrapper">
                        <!-- Slides -->
                        <ng-container *ngFor="let c of counter(18); let i = index">
                            <div class="swiper-slide">
                                <img src="assets/images/help/carrousel2/tip{{(i+1)}}.jpg" alt="Tip">
                            </div>
                        </ng-container>
                    </div>

                    <div class="swiper-pagination-2"></div>
                    <div class="swiper-button-prev-2"></div>
                    <div class="swiper-button-next-2"></div>
                </div>
            </div>

            <div id="ayuda" class="content text-center" *ngIf="selectedOption == 4">
                <legend>{{options[selectedOption]}}</legend>
                <hr>
                <p>eyesCloud3D tiene un equipo de soporte técnico para responder sus preguntas lo antes posible. <br>

                    Si tiene alguna pregunta o problema, comuníquese con nuestro equipo de soporte técnico por correo
                    electrónico</p>
                <img src="https://www.eyescloud3d.com/help/img/help.jpg" alt=""> <br>
                <a href="mailto:customercare@ecapture3d.com">
                    <p class="mt-2">
                        customercare@ecapture3d.com
                    </p>
                </a>
            </div>

            <div class="updates-container" *ngIf="selectedOption == 5">
                <legend>{{options[selectedOption]}}</legend>
                <hr>
                <p>eyesCloud3D tiene un equipo de soporte técnico para responder sus preguntas lo antes posible. <br>

                    Si tiene alguna pregunta o problema, comuníquese con nuestro equipo de soporte técnico por correo
                    electrónico</p>
                <img src="https://www.eyescloud3d.com/help/img/help.jpg" alt=""> <br>
                <a href="mailto:customercare@ecapture3d.com">
                    <p class="mt-2">
                        customercare@ecapture3d.com
                    </p>
                </a>
            </div>
        </div>
    </div>
</div>
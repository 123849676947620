<div mat-dialog-content class="dialog-content">
    <div class="header-container">
        <p class="title">{{'revDialogTitle' | i18n}}</p>
    </div>
    <div class="first-row">
        <p class="first-row">{{'revDialogTextFirstRow' | i18n}}</p>
    </div>
    <div class="second-row">
        <p class="second-row">{{'revDialogTextSecondRow' | i18n}}</p>
    </div>
    <form class="form" (ngSubmit)="revaluation()" novalidate>
        <label>
            <input formControlName="longitude" class="form-control"
                   [ngClass]="{ 'is-invalid': submitted }" 
                   placeholder ="{{'revDialogTitle' | i18n}}" >
        </label>
        <div class="container">
            <div class="buttons-container">
              <button type="submit" (click)="revaluation()">{{'continue' | i18n}}</button>
            </div>
          </div>
    </form>
</div>
  

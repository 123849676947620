import {Component, Input, OnInit} from '@angular/core';
import {Language} from '../../../models/language';
import {I18nService} from '../../../core/services/i18n.service';
import {Router} from '@angular/router';
import { SecurityTokenStorage } from 'src/app/services/security/security-token-storage';
import { UserRemoteService } from 'src/app/core/services/remote/user/user.remote.service';
import { BaseProfile } from 'src/app/models/security/base-profile';
import { UserToken } from 'src/app/models/security/user-token';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})

export class LanguageSelectorComponent implements OnInit {

  currentLanguage: Language;
  @Input() footer: boolean;
  private userLogged: any;
  languageId: number;

  constructor(private i18nService: I18nService,
              private router: Router,
              private userService: UserRemoteService,
              private securityTokenStorage: SecurityTokenStorage<UserToken>) {
                try {
                  this.userLogged = this.securityTokenStorage.getObjectValue();
                } catch (e) {

                }
              }

  ngOnInit() {
    //console.log(this.footer)
    this.currentLanguage = this.i18nService.getCurrentLanguage();
  }

  public getOthersThanCurrentLanguage(): Language[] {
    return I18nService.getSupportedLanguages()
      .filter((x) => x.code !== this.i18nService.getCurrentLanguage().code);
  }

  setCurrentLanguage(code: string) {
    this.i18nService.setCurrentLanguage(code);
    this.currentLanguage = this.i18nService.getCurrentLanguage();
    if (this.userLogged != null) {

      if (this.currentLanguage.code !== null) {
        if (this.currentLanguage.code === 'es') {
          this.languageId = 1;
        } else {
          this.languageId = 2;
        }
        this.userService.editLanguage(this.userLogged.id, { language_id: this.languageId }).subscribe(
          (response) => {
            // this.currentLanguageCode = languageCode;
          },
          (error) => {
            console.log(error);
            console.log('Error en servicio de actualizar idioma del usuario.');
          }
        );
      }
    }    this.reloadCurrentRoute();
  }

  private reloadCurrentRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const currentUrl = this.router.url;

    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
  }
}

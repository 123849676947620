<div class = "row navbar-container"
     [ngClass] = "{
     'navbar-container-blue': backgroundBlue,
     'navbar-container-white': !backgroundBlue
     }">
  <div
    class = "eyescloud-navbar"
    [ngClass] = "{
     'navbar-background-blue': backgroundBlue,
     'navbar-background-white': !backgroundBlue
     }">

    <div class="logo-container">
      <img routerLink="/home" class="eyesnroad-navbar-img" src="/assets/images/navbar/blue/eyesdcar-logo.svg" *ngIf="backgroundBlue">
      <img routerLink="/home" class="eyesnroad-navbar-img"src="/assets/images/navbar/white/eyesdcar-logo.svg" *ngIf="!backgroundBlue">

      <img routerLink="/home" class="eyesnroad-logo-img" src="/assets/images/eyesncar-logo-text-white2.svg" *ngIf="backgroundBlue">
      <img routerLink="/home" class="eyesnroad-logo-img display-pc" src="/assets/images/eyesdcard-logo-text-blue.svg" *ngIf="!backgroundBlue">
    </div>

    <div class="eyescloud-navbar-right-side">
      <a routerLink="/mobile-profile-menu" class="navbar-menu-link d-sm-block d-md-none">
        <img src="/assets/images/navbar/blue/menu-icon.png" *ngIf="backgroundBlue">
        <img src="/assets/images/navbar/white/menu-icon.png" *ngIf="!backgroundBlue">
      </a>
      <div class="d-none d-md-flex eyescloud-navbar-menu">
        <a routerLink="/new-project"><p>{{'newProject' | i18n}}</p></a>
        <a routerLink="/gallery"><p>{{'gallery' | i18n}}</p></a>
        <a routerLink="/profile/personal-data"><p>{{'account' | i18n}}</p></a>
        <img *ngIf="backgroundBlue" src="/assets/images/vertical-separator-white.png">
        <img *ngIf="!backgroundBlue" src="/assets/images/vertical-separator-blue.png">
        <app-language-selector [footer]=false style="margin: 0px 3px; display: flex; align-items: center;" class="language-selector"></app-language-selector>
        <img *ngIf="backgroundBlue" src="/assets/images/vertical-separator-white.png">
        <img *ngIf="!backgroundBlue" src="/assets/images/vertical-separator-blue.png">
        <!--<a (click)="logout()"><p class="logout">{{'logout' | i18n}}</p></a> --->
        <img  class="logout-icon" style="width: 21px; margin-right: 9px;cursor: pointer;" (click)="logout()" src="/assets/images/logout-icon.png">
      </div>
      <div class="none component-flex eyescloud-navbar-menu eyescloud-navbar-menu-aux" *ngIf="!logged">
        <a routerLink="/login" style="margin-right: 15px;"><p class="logout">{{'connectWith' | i18n}}</p></a>
      </div>
      <a (click)="chatSwitcher()" class="chat-online-button d-none d-md-flex">
        <p>{{chatTitle}}</p>
      </a>
    </div>
  </div>
</div>

<!-- href="#hs-chat-open" -->

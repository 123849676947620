import {PreloadingStrategy, Route} from '@angular/router';
import {of} from 'rxjs';
import {Observable} from 'rxjs/Observable';

export class CustomPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preload) {
      return load();
    } else {
      return of(null);
    }
  }
}

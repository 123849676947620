import {Component, Input, OnInit} from '@angular/core';
import {Thumbnail} from '../../../models/thumbnail';
import {Router} from '@angular/router';

@Component({
  selector: 'app-populars-slider',
  templateUrl: './populars-slider.component.html',
  styleUrls: ['./populars-slider.component.scss']
})
export class PopularsSliderComponent implements OnInit {

  @Input() widthPercent = 30;
  @Input() height = 250;
  @Input() marginRight = 30;
  @Input() popularImages: Thumbnail[] = [];

  constructor(
              private router: Router) { }

  ngOnInit() {
  }

  onSliderClick(code: string) {
    this.router.navigate(['/model', code]);
  }
}

import {Component, Input, OnInit, HostListener} from '@angular/core';
import {AuthenticationService} from '../../../services/security/authentication.service';
import {LoaderService} from '../../../core/services/loader.service';
import { I18nService } from 'src/app/core/services/i18n.service';
import { ResponsiveService } from '../../../core/services/responsive.service';
import { StorageService } from 'src/app/models/storage/storage.service';
import { SecurityTokenStorage } from 'src/app/services/security/security-token-storage';
import { BaseProfile } from 'src/app/models/security/base-profile';
import { UserToken } from 'src/app/models/security/user-token';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

chatSwitch = false;
  @Input() backgroundBlue = true;

  chatTitle = '';
  showSmallNavbar = false;
  permissionGuard: string;
  logged = true;
  isSmallMenu = false;
  loggedUser: any;

  constructor(private authenticationService: AuthenticationService,
              private loaderService: LoaderService,
              private i18nService: I18nService,
              public responseService: ResponsiveService,
              private storageService: StorageService,
              private securityTokenStorage: SecurityTokenStorage<UserToken>){}


  ngOnInit() {
    this.loggedUser = this.securityTokenStorage.getObjectValue();
    this.chatTitle = this.i18nService.getText('chatOnline');
    const width = window.outerWidth;
    this.checkWindowsWidth(width);
    this.permissionGuard = this.storageService.get('acceptedLogin');
    if (!this.loggedUser) {
      this.logged = false;
    } else {
      this.logged = true;
    }
    if (this.responseService.isTablet()) {
      this.isSmallMenu = true;
    } else {
      this.isSmallMenu = false;
    }
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.screenResize(event);

    if (event.target.innerWidth >= 992) {
      this.isSmallMenu = false;
    } else if (event.target.innerWidth < 992) {
      this.isSmallMenu = true;
    }
  }

  logout() {
    this.loaderService.showLoader();
    this.authenticationService.logout();
  }

  chatSwitcher() {
    this.chatSwitch = !this.chatSwitch;
    if (this.chatSwitch) {
      this.chatTitle = this.i18nService.getText('chatOnlineClose');
      (window as any).HubSpotConversations.widget.open();
    } else {
      (window as any).HubSpotConversations.widget.close();
      this.chatTitle = this.i18nService.getText('chatOnline');
    }
  }

  private screenResize(event) {
    const width = event.target.outerWidth;
    this.checkWindowsWidth(width);
  }

  private checkWindowsWidth(width) {
    if (width <= 630) {
      this.showSmallNavbar = true;
    } else {
      this.showSmallNavbar = false;
    }
  }
}

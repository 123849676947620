<div class="slider-item" *ngIf="record" (click)="click(record)">
  <div class="wrapper">
    <div class="opacity-div"></div>
    <img alt="" [src]="record.previewURL" (error)="setDefaultImage($event)" />
    <div class="progress-bar-container">
      <app-project-progress-bar [record]="record"></app-project-progress-bar>
    </div>
    <div class="project-name-container"><p class="project-name">{{'recordNumber' | i18n}} {{record.recordId}}</p></div>
    <div class="line"></div>
    <div class="gallery-item-user-info-container">
      <div class="gallery-item-user-info-subcontainer">
        <img alt="" [src]="avatarSrc" class="user-photo" (error)="setDefaultAvatar($event)" />
        <div class="gallery-item-user-info-text">
          <p class="gallery-item-user-info-name" *ngIf="record.userName.length > 0">{{record.userName}} {{record.userSurname}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

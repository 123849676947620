<h1>{{'yourRecordsInProcess' | i18n}}</h1>
<div class="projects-processing-container">
  <div class = "no-projects-container" *ngIf="!(processingRecords && processingRecords.length > 0)">
    <img src="/assets/images/home/no-projects.svg" alt="no projects">
    <div class="vertical-line"></div>
    <div class="no-projects-text-container">
      <p class="main-text">{{'selector_daños_data4' | i18n}}</p>
      <p class="secondary-text">{{'noProjectsSecondaryText' | i18n}}</p>
    </div>
  </div>
  <div class="projects-processing-left-container" *ngIf="processingRecords && processingRecords.length > 0">
 
    <div class="top-container">
      <!-- <div class="publi-container">
        <div class="google-ads" *ngIf="showGoogleAdsense">
          <ng-adsense
            [adClient]="'ca-pub-4671591524487127'"
            [adSlot]="6373068550"
            [display]="'inline-block'"
            width="300"
            height="250"
            adFormat="rectangle">
          </ng-adsense>
        </div>
      </div> -->


      <div class="top-container-right" *ngIf="processingRecords && processingRecords.length > 0">
        <app-current-project-processing *ngIf="currentRecord" [record]="currentRecord"></app-current-project-processing>
      </div>
    </div>
    <p *ngIf="processingRecords && processingRecords.length > 0">{{'allProcessingRecords' | i18n}}</p>
    <div class="models-processing-left-bottom-container" *ngIf="processingRecords && processingRecords.length > 0">
      <div class="models-processing-slider-container">
        <app-projects-processing-slider [records]="processingRecords" (clickItem)="projectClick($event)">
        </app-projects-processing-slider>
      </div>
    </div>
  </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectThumbnail } from 'src/app/models/project-thumbnail';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import { User } from 'src/app/shared/models/user/user';
import { RecordFilesRemoteService } from '../../../../core/services/remote/record/record-files-remote.service';
import { UserRemoteService } from '../../../../core/services/remote/user/user.remote.service';

@Component({
  selector: 'app-populars-item',
  templateUrl: './populars-item.component.html',
  styleUrls: ['./populars-item.component.scss']
})
export class PopularsItemComponent implements OnInit {

  avatarSrc = '';
  src = '';
  user: User;

  @Input() image: ProjectThumbnail;
  @Output() itemClick = new EventEmitter<any>();

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    if (this.image) {
      this.authenticationService.get().subscribe(userLogged => {
        this.user = userLogged;
        this.avatarSrc = this.user.avatar;
      });

      if (!this.image.project.previewURL) {
        this.setDefaultImage();
      } else {
        this.src = this.image.project.previewURL;
      }

      if (this.avatarSrc) {
        this.setDefaultAvatar();
      }
    }
  }

  click(code: number) {
    this.itemClick.emit(code);
  }

  setDefaultImage() {
    this.src = '/assets/images/blue-background.png';
  }

  setDefaultAvatar() {
    this.avatarSrc = '/assets/images/gallery-item-default-avatar.png';
  }
}

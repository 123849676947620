import {Component, HostListener, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'app-capture-tips-dialog',
  templateUrl: './capture-tips-dialog.component.html',
  styleUrls: ['./capture-tips-dialog.component.scss']
})
export class CaptureTipsDialogComponent {

  step = 1;
  showMatSelect = false;

  constructor(private dialogRef: MatDialogRef<CaptureTipsDialogComponent>,
              private router: Router) {
  }

  ngOnInit() {
    const width = window.innerWidth;
    if (width <= 830) {
      this.showMatSelect = true;
    } else if (width > 830) {
      this.showMatSelect = false;
    }
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if(event.target.innerWidth <= 830) {
      this.showMatSelect = true;
    } else {
      this.showMatSelect = false;
    }

    if(event.target.innerWidth <= 630) {
      this.router.navigateByUrl('/profile/mobile-capture-tips');
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  leftArrowClick() {
    if (this.step > 1) {
      this.step--;
    } else {
      this.step = 5;
    }
  }

  rightArrowClick() {
    if (this.step < 5) {
      this.step++;
    } else {
      this.step = 1;
    }
  }

  goTo(step) {
    this.step = step;
  }
}

<div class="record-info">
  <p>{{'recordNumber' | i18n}} {{recordID}}</p>
  <p class="username">{{'username' | i18n}} {{record.userName}}</p>
</div>

<div class="vehicle-info">
  <div class="form">
    <p class="vehicle-data">{{'vehicleData' | i18n}}</p>

    <div class="owner form-field">
      <p>{{'owner' | i18n}}</p>
      <div class="field">
        <span>{{record.userName}} {{record.userSurname}}</span>
      </div>
    </div>

    <div class="registration-number form-field">
      <div class="group">
        <p>{{'registrationNumber' | i18n}}</p>
        <div class="field registration-number-number">
          <span>{{record.license_plate}}</span>
        </div>
      </div>
      <div class="group">
        <p>{{'policyNumber' | i18n}}</p>
        <div class="field policy-number">
          <span>{{record.insurance}}</span>
        </div>
      </div>
      <!-- <div class="group">
        <p>{{'enrollment' | i18n}}</p>
        <div class="field registration-number-year">
          <span>{{''}}</span>
        </div>
      </div> -->
    </div>



    <div class="vehicle form-field">

      <div class="group">
        <p>{{'vehicleBrand' | i18n}}</p>
        <div class="field vehicle-brand">
          <span>{{record.brand}}</span>
        </div>
      </div>

      <div class="group">
        <p>{{'datos_expediente_data2' | i18n}}</p>
        <div class="field vehicle-brand">
          <span>{{record.model}}</span>
        </div>
      </div>
    </div>
    <div class="vehicle form-field">
      <div class="group">
        <p>{{'datos_expediente_data1' | i18n}}</p>
        <div class="field vehicle-brand">
          <span>{{record.version}}</span>
        </div>
      </div>
      <div class="group">
        <p>{{'vehicleType' | i18n}}</p>
        <div class="field vehicle-type">
          <span>{{record.vehicleType}}</span>
        </div>
      </div>

    </div>

    <div class="policy form-field">
      <div class="group">
        <p>{{'frameNumber' | i18n}}</p>
        <div class="field">
          <span>{{record.vin}}</span>
        </div>
      </div>
      <div class="group">
        <p>{{'processingDate' | i18n}}</p>
        <div class="field policy-date">
          <span>{{record.registration_date}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="separator"></div>
  <div class="video">
    <p>{{'carVideo' | i18n}}</p>
    <video src={{record.videoURL}} controls muted>
      {{'unsupportedBrowser' | i18n}}
    </video>
  </div>

</div>

import {Component, HostListener} from '@angular/core';
import {HomeComponent} from '../../../components/home/home.component';
import {ProfileLayoutComponent} from '../../../profile/components/profile-layout/profile-layout.component';
import {GalleryComponent} from 'src/app/gallery/components/gallery/gallery.component';
import {ViewprojectComponent} from 'src/app/view-project/components/view-project/viewproject.component';
import {HomeNewProjectComponent} from 'src/app/new-project/components/home-new-project-component/home-new-project-component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

  navbarBackgroundBlue = true;
  showHeaderBackground = true;
  showHeader = true;
  showFooter = true;
  grayBackground = false;
  lowHeader = false;
  componentActive: any;

  constructor() {
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
  }

  onComponentActivate(component) {
    this.componentActive = component;
    this.showHeader = true;
    this.showFooter = true;
    if (component instanceof HomeComponent || component instanceof ProfileLayoutComponent) {
      this.navbarBackgroundBlue = true;
      this.showHeaderBackground = false;
    } else {
      this.navbarBackgroundBlue = false;
      this.showHeaderBackground = true;
    }
    if (component instanceof GalleryComponent || component instanceof ViewprojectComponent ||
      component instanceof HomeNewProjectComponent) {
        this.lowHeader = true;
      } else {
        this.lowHeader = false;
      }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Incidence } from '../../models/incidence';
import { IncidenceMessage } from '../../models/incidence-message';
import { IncidenceRemoteService } from '../../services/incidence-remote.service';
import { DateService } from '../../services/date.service';
import { IncidenceSearchCriteria } from '../../models/security/incidence-search-criteria';
import { AuthenticationService } from '../../services/security/authentication.service';
import { User } from '../../shared/models/user/user';


@Component({
  selector: 'app-incidence',
  templateUrl: './incidence.component.html',
  styleUrls: ['./incidence.component.css']
})
export class IncidenceComponent implements OnInit {

  public form: FormGroup;
  public form2: FormGroup;
  public incidencia: Incidence;
  public incidenciaMessages: Incidence;
  public incidencias: Incidence[];
  public hideCrear: boolean;
  public showMensajes: boolean;
  public messages: IncidenceMessage[];
  public userLogged: User;

  constructor(private incidenceRemoteService: IncidenceRemoteService, private formBuilder: FormBuilder, private dateService: DateService,
              private authenticationService: AuthenticationService) {
    this.hideCrear = true;
    this.showMensajes = false;
    this.api();
    this.createForm();
  }

  public api() {
    // Buscar las incidencias del usuario
    this.authenticationService.get().subscribe(user => {
      this.incidencia = new Incidence(1, null, user.id, '');
      const criteria = new IncidenceSearchCriteria();
      this.userLogged = user;
      criteria.user_id = this.userLogged.id;
      this.incidenceRemoteService.search(criteria).subscribe(data => {
        this.incidencias = data;
      });
    });
  }

  public createForm() {
    this.form = this.formBuilder.group({
      content: ['', Validators.compose([Validators.required])],
    });
  }

  public createFormMessage() {
    this.form2 = this.formBuilder.group({
      content: ['', Validators.compose([Validators.required])],
    });
  }

  public crearIncidencia() {
    this.incidencia.content = this.form.controls.content.value;
    this.incidencia.open_date = this.dateService.formatDateTime(new Date());
    this.incidenceRemoteService.save(this.incidencia).subscribe(data => {
      if (data != null) {
        this.api();
        this.form.controls.content.setValue('');
      }
    });
  }

  public getIncidence(incidence_id: number) {
    this.incidenceRemoteService.get(incidence_id).subscribe(incidencia => {
      this.incidenciaMessages = incidencia;
    });

    const criteria = new IncidenceSearchCriteria();
    criteria.id = incidence_id;
    this.incidenceRemoteService.searchMessages(criteria).subscribe(data => {
      this.showMensajes = true;
      this.messages = data;

      this.createFormMessage();

    });
  }

  public closeIncidence(incidence: Incidence) {
    this.incidenceRemoteService.close(incidence).subscribe(data => {
    });
  }

  public deleteIncidence(id: number) {
    this.incidenceRemoteService.delete(id).subscribe(data => {
      this.api();
    });
  }

  public crearMensaje(incidence_id: number) {
    var newMessage = new IncidenceMessage(incidence_id, this.dateService.formatDateTime(new Date()), this.userLogged.id, this.form2.controls.content.value);
    this.incidenceRemoteService.saveMessage(newMessage).subscribe(data => {
      this.form2.controls.content.setValue('');
      this.messages.push(newMessage);
    });
  }


  public toggleCrear() {
    this.hideCrear = !this.hideCrear;
  }

  public toggleMensajes() {
    this.showMensajes = !this.showMensajes;
  }

  ngOnInit() {
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestClientService } from '../../core/services/api-access/rest-client.service';
import { StorageService } from '../../models/storage/storage.service';
import { AppConfigService } from '../config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class RecordStorageRemoteService {

  private URL = '/record-files';

  constructor(
    private restClientService: RestClientService,
    private storageService: StorageService,
    private appConfigService: AppConfigService) { }

  public uploadUrl(): string {
    return this.appConfigService.getAppConfig().apiUrl + '/files/upload/' + this.storageService.get('recordId');
  }

  public delete(recordId: number, filename: string): Observable<any> {
    const file = {
      filename
    };
    return this.restClientService.post(`${this.URL}/delete/${recordId}/${filename}`, file);
  }

  public getStorage(id: number): Observable<number> {
    return this.restClientService.get(`${this.URL}/userStorage/${id}`);
  }

  public deleteRecord(recordId: number): Observable<any> {
    return this.restClientService.delete(`${this.URL}/delete/${recordId}`);
  }

  public getVideo(recordId: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${recordId}/file`);
  }

  public getDropzoneVideoPreview(recordId: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${recordId}/filePreview`);
  }
}

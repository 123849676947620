import { Component, OnInit, HostListener, AfterViewInit, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '../../core/services/loader.service';
import { ResponsiveService } from '../../core/services/responsive.service';
import { Record } from 'src/app/models/records/record';
import { ProjectThumbnail } from 'src/app/models/project-thumbnail';
import { UserRemoteService } from '../../core/services/remote/user/user.remote.service';
import { RecordRemoteService } from '../../core/services/remote/record/record-remote.service';
import { GalleryCategoryEnum } from 'src/app/models/common/gallery-category-enum';
import { AuthenticationService } from 'src/app/services/security/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterContentInit {
  [x: string]: any;

  constructor(
    private recordRemoteService: RecordRemoteService,
    private router: Router,
    private loaderService: LoaderService,
    private responsiveService: ResponsiveService,
    private userRemoteService: UserRemoteService,
    private authenticationService: AuthenticationService) {
  }

  userProjects: Record[] = [];
  departmentProjects: Record[];
  gridItemPercent;
  showGoogleAdsense = false;
  userProjectsLoaded = false;

  sliderImages: ProjectThumbnail[] = [];

  isResponsiveMobile = false;

  ngOnInit() {
    // this.loaderService.showLoader();
    this.getUser();
    this.responsiveVisualization();

  }
  ngAfterContentInit() {
    // DATOS DE PRUEBA -- Borrar
    // console.log('dentro');
    // const testProject = new Record();
    // this.sliderImages[0] = ({
    //   name: 'VW Passat',
    //   src: 'https://upload.wikimedia.org/wikipedia/commons/9/91/VW_Passat_B8_Limousine_2.0_TDI_Highline.JPG',
    //   userName: 'Max',
    //   userSurname: 'Power',
    //   project: testProject
    // });
    // this.sliderImages[1] = ({
    //   name: 'Renault Megane',
    //   src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/2017_Renault_Megane_Dynamique_S_NAV_DC_1.5_Front.jpg/1920px-2017_Renault_Megane_Dynamique_S_NAV_DC_1.5_Front.jpg',
    //   userName: 'Max',
    //   userSurname: 'Power',
    //   project: testProject
    // });
    // this.sliderImages[2] = ({
    //   name: 'Volvo V40',
    //   src: 'https://www.diariomotor.com/imagenes/picscache/1920x1600c/Volvo_V40_02_1920x1600c.jpg',
    //   userName: 'Max',
    //   userSurname: 'Power',
    //   project: testProject
    // });

    // this.sliderImages[3] = ({
    //   name: 'Nissan Micra',
    //   src: 'https://media.gossipvehiculo.com/wp-content/uploads/2020/11/05171236/Nissan-Micra-696x392.jpg',
    //   userName: 'Max',
    //   userSurname: 'Power',
    //   project: testProject
    // });
    //
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (event.target.innerWidth <= 768) {
      this.isResponsiveMobile = true;
    } else {
      this.isResponsiveMobile = false;
    }
  }

  private getUser() {
    this.loaderService.showLoader();
    this.authenticationService.get().subscribe(user => {
      this.user = user;
      this.getUserProjects();
    });
  }
  private getUserProjects() {
    this.recordRemoteService.getRecords(this.user.id).subscribe(projects => {
      this.userProjects = projects;
      this.loadSliderImages();
      this.loaderService.hideLoader();
    }, error => this.loaderService.hideLoader());
  }

  private responsiveVisualization() {
    if (this.responsiveService.isXS() || this.responsiveService.isS()) {
      this.gridItemPercent = 70;
      this.isResponsiveMobile = true;
    } else if (this.responsiveService.isM()) {
      this.gridItemPercent = 25;
      this.isResponsiveMobile = false;
    } else {
      this.showGoogleAdsense = true;
      this.gridItemPercent = 30;
      this.isResponsiveMobile = false;
    }
  }

  private loadSliderImages() {
    this.sliderImages = [];
    this.userProjects.forEach(projectReceive => {
      if (projectReceive) {
        this.sliderImages.push({
          name: projectReceive.id.toString(),
          src: projectReceive.previewURL,
          project: projectReceive
        });
      } else {
        this.sliderImages.push({
          name: null,
          src: null,
          project: null
        });
      }
    });
  }

  private hideLoader() {
    if (this.departmentProjectsLoaded && this.userProjectsLoaded) {
      this.loaderService.hideLoader();
    }
  }

}

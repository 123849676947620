import {Injectable} from '@angular/core';
import {RestClientService} from 'src/app/core/services/api-access/rest-client.service';
import {Observable} from 'rxjs';
import { Video } from 'src/app/models/video';

@Injectable({
    providedIn: 'root'
})
export class RecordFilesRemoteService {

  private URL = '/record-files';

  constructor(private restClientService: RestClientService) { }

  public getUrlProjectPreviewImage(recordId: number): Observable<string> {
    return this.restClientService.get(`${this.URL}/${recordId}/preview`);
  }
  public getFramesOfVideosSelected(recordId: number) {
    return this.restClientService.get(`${this.URL}/${recordId}/get-frames`);
  }
}

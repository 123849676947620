import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../../api-access/rest-client.service';
import {UserCredits} from '../../../../shared/models/user-rate/user-credits';
import {UserRate} from '../../../../shared/models/user-rate/user-rate';

@Injectable({
  providedIn: 'root'
})
export class UserRateRemoteService {

  private URL = '/management';

  constructor(private restClientService: RestClientService) { }

  public getCredits(): Observable<UserCredits> {
    return this.restClientService.get(`${this.URL}/credits`);
  }

  public get(id: number): Observable<UserRate> {
    return this.restClientService.get(`${this.URL}/${id}`);
  }

  public save(userRate: UserRate): Observable<UserRate> {
    if (userRate.id !== undefined && userRate.id !== null) {
      return this.restClientService.put(`${this.URL}/${userRate.id}`, userRate);
    }
    return this.restClientService.post(`${this.URL}`, userRate);
  }
}

<div class="top-container-right" *ngIf="record">
  <div class="projects-processing-left-top-container">
    <div class="last-project-processing-container">
      <div class="last-project-processing-info">
        <img [src]="src" (error)="setDefaultImage($event)">
        <div class="opacity-div"></div>
        <div class="last-project-progress-bar-container">
          <app-project-progress-bar [record]="record">
          </app-project-progress-bar>
        </div>
        <p class="project-name">{{'recordNumber' | i18n}} {{record.recordId.toString()| split:48}} </p>
        <div class="line"></div>
        <div class="gallery-item-user-info-container">
          <div class="gallery-item-user-info-subcontainer">
            <img [src]="avatarSrc" class="user-photo" (error)="setDefaultAvatar($event)" />
            <div class="gallery-item-user-info-text">
              <p class="gallery-item-user-info-name">{{record.userName}} {{record.userSurname}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="last-project-processing-categories">
        <div class="last-project-processing-categories-content">
          <!-- <div class="processing-estimated-time">
            <p class="processing-estimated-time-text">{{'estimatedTime' | i18n}}:</p>
            <p class="processing-estimated-time-value">0:00:00</p>
            <p class="processing-estimated-time-value">min</p>
          </div> -->

          <div class="project-info">
            <div class="project-info-title">{{'recordName' | i18n}}</div>
            <div class="project-info-text">{{record.recordId}}</div>
            <div class="gray-line"></div>
            <div class="project-info-title">{{'username' | i18n}}</div>
            <div class="project-info-text">{{record.userName}} {{record.userSurname}}</div>
            <div class="gray-line"></div>
            <div class="project-info-title">{{'licensePlate' | i18n}}</div>
            <div class="project-info-text">{{record.license_plate}}</div>
            <div class="gray-line"></div>
          </div>
        </div>
        <a class="active" routerLink="/projects/{{record.recordId}}"
          [ngClass]="{'disabled': record.percentage_processed !== 100}"><span>{{'goToRecord' | i18n}}</span></a>
      </div>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {EventData} from '../models/event-data';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private subject$ = new Subject();

  constructor() { }

  emit(event: EventData) {
    this.subject$.next(event);
  }

  on(eventName: string, data: any): Subscription {
    return this.subject$.pipe(
      filter( (e: EventData) => e.name === eventName),
      map( (e: EventData) => e.value)).subscribe(data);
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProfileOption } from '../../models/profile-option';
import { AuthenticationService } from '../../../services/security/authentication.service';
import { User } from '../../../shared/models/user/user';
import { LoaderService } from '../../../core/services/loader.service';
import { I18nService } from '../../../core/services/i18n.service';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UploadUserAvatarDialogComponent } from '../dialogs/upload-user-avatar-dialog/upload-user-avatar-dialog.component';
import { UserRemoteService } from '../../../core/services/remote/user/user.remote.service';
import { CustomerSupportDialogComponent } from '../dialogs/customer-support-dialog/customer-support-dialog.component';
import { FaqsDialogComponent } from '../dialogs/faqs-dialog/faqs-dialog.component';
import { ResponsiveService } from '../../../core/services/responsive.service';
import { UpdateDialogComponent } from '../dialogs/update-dialog/update-dialog.component';

import { CaptureTipsDialogComponent } from '../dialogs/capture-tips-dialog/capture-tips-dialog.component';
import { RecordRemoteService } from 'src/app/core/services/remote/record/record-remote.service';
import { EventData } from 'src/app/core/models/event-data';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss']
})
export class ProfileLayoutComponent implements OnInit {

  profileOption: ProfileOption = ProfileOption.USER_ACCOUNT;
  user: User;
  userType = '';
  show = true;
  countProjects = 0;
  avatarUrl = '';
  timeStamp;

  @ViewChild('x', { static: false }) x: ElementRef;

  constructor(
    private authenticationService: AuthenticationService,
    private loaderService: LoaderService,
    private i18nService: I18nService,
    private router: Router,
    private dialog: MatDialog,
    private userRemoteService: UserRemoteService,
    private responsiveService: ResponsiveService,
    private recordRemoteService: RecordRemoteService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.show = !this.responsiveService.isSmallScreen();
    this.loaderService.showLoader();
    this.loadUserData();
    this.setprofileOption(this.router.url);
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.setprofileOption(e.url);
      }
    });
  }

  editUserPhoto() {
  }

  goToChangePassword() {
    this.profileOption = ProfileOption.USER_CHANGE_PASSWORD;
  }

  goToControlPanel() {
    this.profileOption = ProfileOption.COMPANY_DATA;
  }

  onUploadAvatarChanged(event) {
    this.openUploadUserAvatarDialog(event);
  }

  openUploadUserAvatarDialog(file) {
    const dialogRef = this.dialog.open(UploadUserAvatarDialogComponent, {
      width: '917px',
      height: '613px',
      data: { file }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loaderService.showLoader();
        this.userRemoteService.uploadAvatar(this.user.id, result.file.base64).subscribe(avatarUrl => {
          this.loadUserData();
          this.loaderService.hideLoader();
        }, error => this.loaderService.hideLoader());
      }
    });
  }

  goToCustomerSupport() {
    this.dialog.open(CustomerSupportDialogComponent, {
      width: '729px'
    });
  }

  goToFaqs() {
    this.dialog.open(FaqsDialogComponent, {
      width: '917px',
      height: '773px',
      autoFocus: false
    });
  }

  goToUpdates() {
    this.dialog.open(UpdateDialogComponent, {
      width: '917px',
      height: '773px',
      autoFocus: false
    });
  }

  goToTips() {
    this.dialog.open(CaptureTipsDialogComponent, {
      width: '917px',
      height: '623px',
      autoFocus: false
    });
  }

  getUrlAvatar() {
    // if (this.timeStamp) {
    //   return this.avatarUrl + '?' + this.timeStamp;
    // }
    return this.avatarUrl;
  }

  private loadUserData() {
    this.authenticationService.get().subscribe(userLogged => {
      this.user = userLogged;
      this.avatarUrl = this.user.avatar;
      this.userType = this.user.department_id ?
        this.i18nService.getText('company') :
        this.user.is_professional ? this.i18nService.getText('professional') : this.i18nService.getText('user');
      this.recordRemoteService.getRecords(this.user.id).subscribe(projects => {
        this.countProjects = projects.length;
        this.loaderService.hideLoader();
      });
    });
  }

  private setprofileOption(url: string) {
    switch (url) {
      case '/profile/company-control-panel':
      case '/profile/company-control-panel/users':
      case '/profile/company-control-panel/users':
      case '/profile/company-control-panel/departments':
        this.profileOption = ProfileOption.COMPANY_CONTROL_PANEL;
        break;
      case '/profile/company-data':
        this.profileOption = ProfileOption.COMPANY_DATA;
        break;
      case '/profile/change-password':
        this.profileOption = ProfileOption.USER_CHANGE_PASSWORD;
        break;
      case '/profile/personal-data':
        this.profileOption = ProfileOption.USER_PERSONAL_DATA;
        break;
    }
  }

  refreshPersonalData() {
    this.eventService.emit(new EventData('setShowOption', 1));
  }

}

<div mat-dialog-content>
  <div class="header-container">
    <p class="title">{{'frequentlyQuestions' | i18n}}</p>
    <a (click)="onNoClick()"><img src="assets/images/modal-close-ico.png" alt=""></a>
  </div>
  <div class="faq-container">
    <ngx-simplebar [options]="options">
      <p class="question">¿Qué dispositivos de captura puedo usar para tomar fotos?</p>
      <p>Puede usar cualquier dispositivo, desde una cámara réflex a la cámara de un teléfono móvil. El único requisito es usar una lente estándar. Actualmente, la plataforma aún no admite imágenes ojo de pez o gran angular, estamos trabajando para incorporarlas en el futuro. También necesita usar imágenes JPG.</p>

      <div class="line"></div>

      <p class="question">¿Cómo deben tomarse las imágenes?</p>
      <p>El objeto a capturar no debe estar en movimiento y las imágenes deben estar nítidas, con el objeto centrado en la imagen y con una superposición igual o mayor al 66% entre las imágenes (la misma parte del objeto debe mostrarse al menos 3 imágenes). Siempre debe haber un cambio entre las capturas. Tomar dos imágenes del mismo punto puede causar que el modelo no se genere. Para ver más información sobre cómo capturar fotos, visite Capture Tips.</p>

      <div class="line"></div>

      <p class="question">¿Cómo generar un modelo 3D?</p>
      <p>Para generar un nuevo modelo 3D, siga estos pasos:<br>
        1. Haga clic en Nueva 3D en la barra de navegación superior.<br>
        2. Ingrese el nombre y tipo de modelo o elija la categoría. Seleccione el tipo de modelo (normal, cara) y la calidad (estándar o premium).<br>
        3. Haga clic en el cuadro de inserción de imágenes, seleccione las imágenes y haga clic en el botón Siguiente para obtener un informe sobre su proyecto y método de pago.<br>
        4. Finalmente, haga clic en Continuar y espere la notificación por correo electrónico.</p>

      <div class="line"></div>

      <p class="question">¿Qué opciones se usan por defecto?</p>
      <p>Por defecto, los modelos se generarán en calidad 'estándar' y con el tipo de modelo 'normal'.</p>

      <div class="line"></div>

      <p class="question">¿Qué tipo de resultados se obtienen?</p>
      <p> Se obtienen tres tipos de resultados:<br>
        La nube de puntos del modelo 3D en formato PLY binario.<br>
        El modelo de malla texturizada con textura basada en el color de la nube de puntos.<br>
        El modelo de malla texturizada con textura basada en fotografías (mayor calidad de textura).</p>

      <div class="line"></div>

      <p class="question">¿Cómo puedo reclamar un modelo 3D cuando no estoy satisfecho con el resultado?</p>
      <p> Para esa propuesta, puede usar el enlace disponible en la página del modelo 3D. Cuando haces un reclamo, se abre un espacio para tu clame para seguir su estado. Verificaremos su clama en nuestro departamento de soporte y le responderemos menos de 48 hous. Si el problema de su reclamo es responsabilidad de eCapture3D, le daremos el mismo crédito para crear un nuevo modelo 3D.</p>

      <div class="line"></div>

      <p class="question">¿Pueden los resultados ser compartidos? </p>
      <p> Puede compartir sus modelos 3D con su URL ya sea como un enlace único o a través de diferentes redes sociales (Facebook, Whatsapp, LinkedIn, Twitter y Google+). Just activate the 'public' option within the options of the 3D model. Puede activar la opción 'pública' en modelos 3D y todos los usuarios de la plataforma pueden verlos. Los usuarios de la plataforma Othes no pueden descargar sus modelos 3D. Incluso, puede vender su modelo 3D incluidos sus modelos en nuestra base de datos modelo 3D. Usted tiene un beneficio de su modelo 3D del 70% de todas las ventas obtenidas por plataforma.</p>

      <div class="line"></div>

      <p class="question">¿Cuál es el sistema de coordenadas de salida?</p>
      <p> Los modelos se generan en un sistema de coordenadas relativo. Normalmente, el punto de origen del sistema de coordenadas (0, 0, 0) estará ubicado en uno de los puntos de captura. Si conoce las coordenadas absolutas de su modelo, puede usar cualquier software de edición de modelos 3D para realizar la georreferenciación del modelo descargado.</p>

      <div class="line"></div>

      <p class="question">¿Cómo se puede escalar el modelo?</p>
      <p>Para realizar la escala directamente en el proceso de generación del modelo 3D, es necesario utilizar un objetivo (descargar el objetivo aquí). Debe imprimirse en dimensiones reales y colocarse al lado del objeto que se va a fotografiar, para que aparezca en las imágenes. El modelo resultante se escala automáticamente. Otra opción es generar el modelo y luego escalarlo con cualquier software de edición de modelos 3D utilizando una distancia real conocida. </p>

      <div class="line"></div>

      <p class="question">¿Qué significa establecer mi modelo como 'Visible'?</p>
      <p>Si está utilizando una cuenta proporcionada por su empresa, marcar el modelo como visible hará que tanto el jefe del departamento con el que está asociada la cuenta como el administrador de la compañía lean y editen el acceso al modelo. </p>

      <div class="line"></div>

      <p class="question">¿Qué significa configurar mi modelo 'A la venta'?</p>
      <p>Un modelo con la opción de venta activada puede ser vendido por eCapture3D en nuestra base de datos modelo 3D. El usuario fijará el precio final del modelo y obtendrá el 70% del mismo. El eCapture3D descontará el 30% restante para la comisión de administración y ventas.</p>
    </ngx-simplebar>
    <div class="orange-line"></div>
    <a class="help-link" style="cursor: pointer">Centro de ayuda</a>
  </div>
</div>

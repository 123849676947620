import {Injectable} from '@angular/core';
import {RestClientService} from '../../api-access/rest-client.service';
import {Observable} from 'rxjs';
import { Payment } from 'src/app/shared/models/payments/payment';
import { PaymentFilter } from 'src/app/shared/models/payments/payment-filter';
import { PaymentResponse } from 'src/app/shared/models/payments/payment-response';

@Injectable({
  providedIn: 'root'
})
export class PaymentRemoteService {

  private URL = 'payments';

  constructor(private restClientService: RestClientService) { }

  public add(payment: Payment): Observable<Payment> {
    return this.restClientService.post(`${this.URL}`, payment);
  }

  public all(filter: PaymentFilter): Observable<PaymentResponse[]> {
    return this.restClientService.get(`${this.URL}`, filter);
  }
  public generateInvoice(paymentId: number) {
    return this.restClientService.get(`${this.URL}/pdf/${paymentId}`, );
  }
}

import { Injectable, OnDestroy, OnInit } from '@angular/core';
import {
  AxesHelper,
  Box3,
  Box3Helper,
  Color,
  CubeTexture,
  CubeTextureLoader, DirectionalLight,
  DoubleSide,
  Group,
  LightProbe,
  LinearEncoding,
  Mesh,
  MeshStandardMaterial, Object3D, PCFSoftShadowMap,
  PerspectiveCamera,
  PlaneGeometry,
  Raycaster,
  Scene,
  sRGBEncoding,
  TextureLoader,
  Vector2,
  Vector3,
  WebGLRenderer
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { VehicleMeshInitialData } from '../models/vehicle-mesh-initial-data';
import { LightProbeGenerator } from 'three/examples/jsm/lights/LightProbeGenerator';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { EventService } from 'src/app/core/services/event.service';
import { Subject, Subscription } from 'rxjs';
import { EventData } from 'src/app/core/models/event-data';

@Injectable({
  providedIn: 'root'
})

export class ThreeViewerService implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  // Renderer VARS
  private frameId: number = null;
  private canvas: HTMLCanvasElement;
  private renderer: WebGLRenderer;

  // Scene VARS
  private camera: PerspectiveCamera;
  private scene: Scene;
  private orbitControls: OrbitControls;
  private boxHelper: Box3Helper;
  private dirLight: DirectionalLight;

  // Loader VARS
  private textureLoader: TextureLoader;
  private envTexture: CubeTexture;
  private initialData: VehicleMeshInitialData;
  private gltfLoader: GLTFLoader;
  private dracoLoader: DRACOLoader;

  // Model VARS
  private tempPath = ''; // Para poder depurar los distintos modelos de coches de forma rápida
  private model: Group;
  private carMat: MeshStandardMaterial;
  private boundingBox: Box3; // Bounding Box del modelo
  private raycaster: Raycaster;
  private hoveredPart: Object3D;
  private isZoomed: boolean;

  private carType: string;


  constructor(
    private eventService: EventService
  ) {

  }


  ngOnInit(): void {

    console.clear();







  }

  ngOnDestroy(): void {
    // if (this.frameId != null) {
    //   cancelAnimationFrame(this.frameId);
    // }
  }

  /**
   * Función encargada de crear todos los elementos de la escena 3D sobre la que se visualizarán los distintos modelos de los coches
   * @param renderArea Elemento HTML Canvas equivalente al área donde se renderizará toda la escena 3D de Three.js
   * @param widthContainer Ancho de dicho contenedor
   * @param heightContainer Alto del contenedor
   * @param initialData Objeto que contiene toda la información referente al coche a representar
   */
  createScene(renderArea: HTMLCanvasElement, widthContainer: number, heightContainer: number, initialData: VehicleMeshInitialData): void {
    this.canvas = renderArea;
    this.initialData = initialData;


    // Configuro el renderer de WebGL
    this.renderer = new WebGLRenderer({
      canvas: this.canvas,
      alpha: true,    // Background transparente
      antialias: true // Suavizado de bordes
    });
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = PCFSoftShadowMap;
    this.renderer.setSize(widthContainer, heightContainer);

    // Creo la escena
    this.scene = new Scene();

    // Configuro la cámara
    this.camera = new PerspectiveCamera(
      35, this.canvas.width / this.canvas.height, 0.1, 10000
    );
    this.camera.position.copy(new Vector3(5, 5, 5));
    this.camera.lookAt(new Vector3());
    this.camera.up.set(0, 1, 0);
    this.scene.add(this.camera);

    // Establezco los controladores de la cámara del visor
    this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);
    this.orbitControls.screenSpacePanning = true;
    this.orbitControls.enableDamping = true;
    this.orbitControls.dampingFactor = 0.25;
    this.orbitControls.maxPolarAngle = Math.PI / 2;
    this.orbitControls.minDistance = 2;
    this.orbitControls.maxDistance = 10;

    // Configuro la iluminación de la escena
    this.setSceneLights();

    // this.scene.add(this.axesHelper);

    // Configuro el Background de la escena
    this.renderer.setClearColor(new Color(0xffffff), 1); // 0x777777
    this.textureLoader = new TextureLoader();

    // Cargo el modelo 3D en la escena
    this.configGLTFLoader();
  }

  render(): void {
    this.camera.updateProjectionMatrix();
    this.renderer.render(this.scene, this.camera);
  }

  resize(): void {
    const width = this.canvas.width;
    const height = this.canvas.height;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);

    // console.log(width);
    // console.log(height);
  }

  public setCarType(type: string) {
    this.carType = type;
    //this.carType = 'coupe';
    //this.carType = 'sedan';
    //this.carType = 'familiar';
    //this.carType = 'tipoC';
    //this.carType = 'tipoC2';
    //this.carType = 'van';
    this.tempPath = '/assets/models/' + this.carType + '/';
  }

  /**
   * Función que centra la cámara al modelo 3D del coche que se encuentra presente en la escena del visor
   */
  public fitToScreen() {
    const size = this.getModelSize();
    const center = this.getModelCenter();

    this.orbitControls.target.copy(center);

    this.camera.position.copy(center.clone());
    this.camera.translateZ(size.x * 1.5);
    this.camera.zoom = 1;
  }


  /**
   * Función que configura la iluminación global de la escena y
   * el conjunto de luces de la misma
   */
  private setSceneLights() {
    new CubeTextureLoader().setPath('/assets/textures/cubemap/').load(
      [
        'px.png',
        'nx.png',
        'py.png',
        'ny.png',
        'pz.png',
        'nz.png'
      ],
      (cubeTexture) => {
        cubeTexture.encoding = LinearEncoding;
        const lightProbe = new LightProbe();
        lightProbe.copy(LightProbeGenerator.fromCubeTexture(cubeTexture));
        // const helper = new LightProbeHelper(lightProbe, 100);
        this.scene.add(lightProbe);
        this.envTexture = cubeTexture;
      });
    this.isZoomed = false;
    this.dirLight = new DirectionalLight();
    this.dirLight.intensity = 0.85;
    this.scene.add(this.dirLight);
  }

  /**
   * Función encargad de configurar todos los parámetros necesarios del loader de archivos en formato GLTF, que representan a los distintos
   * modelos 3D de coches que se visualizarán en el visor
   */
  private configGLTFLoader() {
    this.gltfLoader = new GLTFLoader();
    this.dracoLoader = new DRACOLoader().setDecoderPath('/assets/draco/');
    this.gltfLoader.setDRACOLoader(this.dracoLoader);
    this.loadGLTFModel(this.tempPath + 'model/CarModel.glb');
  }

  /**
   * Función encargada de cargar un modelo 3D a partir de un path local o URL y
   * que lo añade a la escena. El formato del modelo debe ser GLTF
   */
  private loadGLTFModel(path: string) {

    this.gltfLoader.load(path,
      (gltf) => {
        this.textureLoader.load(this.tempPath + 'textures/BaseColor.jpg', (diffuse) => {
          this.textureLoader.load(this.tempPath + 'textures/Metallic.jpg', (metalness) => {
            this.textureLoader.load(this.tempPath + 'textures/Roughness.jpg', (roughness) => {
              this.textureLoader.load(this.tempPath + 'textures/Opacity_1.jpg', (opacity) => {
                this.textureLoader.load(this.tempPath + 'textures/Normal.jpg', (normal) => {
                  this.textureLoader.load(this.tempPath + 'textures/Opacity.jpg', (planeOpacity) => {
                    diffuse.encoding = LinearEncoding;
                    metalness.encoding = LinearEncoding;
                    roughness.encoding = LinearEncoding;
                    opacity.encoding = LinearEncoding;
                    normal.encoding = LinearEncoding;
                    planeOpacity.encoding = LinearEncoding;

                    diffuse.flipY = false;
                    metalness.flipY = false;
                    roughness.flipY = false;
                    opacity.flipY = false;
                    normal.flipY = false;
                    planeOpacity.flipY = false;
                    this.carMat = new MeshStandardMaterial({
                      map: diffuse, metalnessMap: metalness, alphaMap: opacity, side: DoubleSide,
                      roughnessMap: roughness, envMap: this.envTexture, metalness: 1, transparent: true, envMapIntensity: 3.0,
                      normalMap: normal
                    });
                    const floorMat = new MeshStandardMaterial({
                      map: normal, alphaMap: planeOpacity, transparent: true, color: 0x000000
                    });
                    this.setModelMaterials(gltf, this.carMat, floorMat);
                    this.scene.add(gltf.scene);
                    this.model = gltf.scene;
                    this.raycaster = new Raycaster();
                    this.calcBoundingBox(false);
                    this.fitToScreen();
                    // console.clear(); // Limpiar la consola una vez cargado el modelo
                    this.textureLoader.load('/assets/textures/Road2.jpg', (road) => {
                      road.encoding = LinearEncoding;
                      const geometry = new PlaneGeometry(7, 7, 32);
                      const material1 = new MeshStandardMaterial({ color: 0x444444 });
                      const plane = new Mesh(geometry, material1);
                      plane.receiveShadow = true;
                    });
                  });
                });
              });
            });
          });
        });
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total * 100).toFixed(2) + '% cargado');
      },
      (error) => {
        console.log('Error al cargar el modelo:');
        console.log(error);
      }
    );
  }

  /**
   * Función que aplica el nuevo material compuesto a partir de las texturas proporcionadas por Orlando al modelo 3D del coche
   * @param gltf Modelo en formato GLTF que se va a cargar en la escena
   * @param carMaterial Nuevo material que se le aplicará al modelo, generado a partir de las texturas de Orlando
   * @param floorMaterial Material que se le aplicará al suelo del modelo 3D
   */
  private setModelMaterials(gltf, carMaterial, floorMaterial) {
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        switch (child.name) {
          case 'floor':
            child.material = floorMaterial;
            (child as Mesh).receiveShadow = false;
            break;
          case 'Other':
            const otherMat: MeshStandardMaterial = carMaterial.clone();
            otherMat.transparent = false;
            child.material = otherMat;
            break;
          default:
            child.material = carMaterial;
            break;
        }
        child.matrixAutoUpdate = false;
      }
    });
  }

  /**
   * Función que calcula el Bounding Box del modelo 3D en función a su tamaño, guardando
   * dichos valores en la variable bb del visor
   * @param show Boolean que indica si se quiere mostrar el Bounding Box en la escena
   */
  private calcBoundingBox(show: boolean) {
    this.boundingBox = new Box3().setFromObject(this.model);
    if (show) {
      if (this.boxHelper) {
        this.scene.remove(this.boxHelper);
      }
      this.boxHelper = new Box3Helper(this.boundingBox, new Color(0xffff00));
      this.scene.add(this.boxHelper);
    }

  }

  /**
   * Función que permite obtener el tamaño del BB del modelo 3D del coche,
   * retornando un Vector3 con su tamaño
   */
  private getModelSize() {
    const v = new Vector3();
    this.boundingBox.getSize(v);
    return v;
  }

  /**
   * Función que permite obtener el centro del BB del modelo 3D del coche,
   * retornando un Vector3 con su tamaño
   */
  private getModelCenter() {
    const v = new Vector3();
    this.boundingBox.getCenter(v);
    return v;
  }


  public formatPartName(part: string | number) {
    let r = 'Suelo';
    switch (part) {
      case 'Capo' || 1: return 'Hood'; break;
      case 'Aleta delantera derecha' || 2: r = 'WingFR'; break;
      case 'Aleta delantera izquierda' || 3: r = 'WingFL'; break;
      case 'Aleta trasera derecha' || 4: r = 'WingRR'; break;
      case 'Aleta trasera izquierda' || 5: r = 'WingRL'; break;
      case 'Faro delantero derecho' || 6: r = 'LightFR'; break;
      case 'Faro delantero izquierdo' || 7: r = 'LightFL'; break;
      case 'Faro trasero derecho' || 8: r = 'LightRR'; break;
      case 'Faro trasero izquierdo' || 9: r = 'LightRL'; break;
      case 'Paragolpes delantero' || 10: r = 'BumperF'; break;
      case 'Paragolpes trasero' || 11: r = 'BumperR'; break;
      case 'Puerta delantera derecha' || 12: r = 'DoorFR'; break;
      case 'Puerta delantera izquierda' || 13: r = 'DoorFL'; break;
      case 'Puerta trasera derecha' || 14: r = 'DoorRR'; break;
      case 'Puerta trasera izquierda' || 15: r = 'DoorRL'; break;
      case 'Luna delantera' || 16: r = 'WindshieldL'; break;
      case 'Luna trasera' || 17: r = 'WindshieldR'; break;
      case 'Ventana delantera derecha' || 18: r = 'DoorGlassFR'; break;
      case 'Ventana delantera izquierda' || 19: r = 'DoorGlassFL'; break;
      case 'Ventana trasera derecha' || 20: r = 'DoorGlassRR'; break;
      case 'Ventana trasera izquierda' || 21: r = 'DoorGlassRL'; break;
      case 'Ventana trasera derecha 2' || 22: r = 'DoorGlassRR2'; break;
      case 'Ventana trasera izquierda 2' || 23: r = 'DoorGlassRL2'; break;
      case 'Techo' || 24: r = 'Roof'; break;
      case 'Maletero' || 25: r = 'Trunk'; break;
      case 'Retrovisor derecho' || 26: r = 'RearviewR'; break;
      case 'Retrovisor izquierdo' || 27: r = 'RearviewL'; break;
      case 'Talonera derecha / Faldon lateral derecho' || 28: r = 'StirrupsR'; break;
      case 'Talonera izquierda / Faldon lateral izquierdo' || 29: r = 'StirrupsL'; break;




      default: r = 'Other'; break;
    }
    return r;

  }

  /**
   * Función que resalta de manera visual una pieza concreta del modelo 3D del coche para poder identificar daños fácilmente
   */
  public highlightDamage(partName: string | number) {
    const damagedPart = this.formatPartName(partName);

    if (damagedPart !== 'floor' && damagedPart !== 'Other') {
      this.isZoomed = true;
      //console.clear();
      // console.log(damagedPart);
      this.model.traverse(child => {
        switch (child.name) {
          case damagedPart:
            // console.log(child.name);
            (child as Mesh).material = new MeshStandardMaterial({ color: new Color(0xF1A227) });
            this.focusDamagedPart(child);
            break;
          case 'floor':
            break;
          default:
            (child as Mesh).material = new MeshStandardMaterial();
            break;
        }
      });
      this.hoveredPart = null;
    }
  }

  // showCamera() {
  //   console.log(this.camera);
  // }

  /**
   * Función que, dada una pieza del modelo 3D, se encarga de colocar la cámara de la escena de tal forma que enfoque dicha pieza
   * @param carPart Parte dañada del coche que queremos enfocar con la cámara del visor
   */
  private focusDamagedPart(carPart: Object3D) {
    // Frontal: Hood, LightFL, LightFR, BumperF
    // Superior: Roof, WindshieldF
    // Izquierda: StirrupsL, RearviewL, WingRL, WingFL, DoorFL, DoorGlassRL, DoorGlassFL, DoorRL
    // Derecha: DoorFR, DoorGlassFR, DoorGlassRR, DoorRR, StirrupsR, WingFR, WingRR, RearviewR
    // Trasera: Trunk, BumperR, WindshieldR, LightRL, LightRR
    let pos: Vector3;
    switch (carPart.name) {
      case 'Hood':
      case 'LightFL':
      case 'LightFR':
      case 'BumperF':
        // console.log('CÁMARA FRONTAL');
        pos = new Vector3(-0.048837436192589415, 0.7154703480000003, 4.25);
        break;
      case 'Trunk':
      case 'BumperR':
      case 'WindshieldR':
      case 'LightRL':
      case 'LightRR':
        // console.log('CÁMARA TRASERA');
        pos = new Vector3(-0.07449157629728147, 1.0203047995101064, -4.25);
        break;
      case 'Roof':
      case 'WindshieldF':
        // console.log('CÁMARA SUPERIOR');
        pos = new Vector3(0.047445083217976386, 3, 2.6467513400508507);
        break;
      case 'StirrupsL':
      case 'RearviewL':
      case 'WingRL':
      case 'WingFL':
      case 'DoorFL':
      case 'DoorGlassRL':
      case 'DoorGlassFL':
      case 'DoorRL':
        // console.log('CÁMARA IZQUIERDA');
        pos = new Vector3(3, 0.7154703480000004, 0.10432210411147963);
        break;
      default:
        // console.log('CÁMARA DERECHA');
        pos = new Vector3(-3, 0.7154703480000003, 0.3538013533289838);
        break;
    }
    this.camera.position.copy(pos);
    const target = new Box3().setFromObject(carPart).getCenter(new Vector3());
    this.camera.lookAt(target);
    this.orbitControls.target.copy(target);
  }

  /**
   * Función que permite detectar las colisiones del puntero del ratón del usuario para resaltar/seleccionar una parte del modelo 3D
   * @param e Evento de mouseDown detectado en el canvas del visor 3D
   */
  rayCaster(e) {
    if (this.raycaster && !this.isZoomed) {
      const mouse = new Vector2();
      mouse.x = (e.offsetX / e.path[0].clientWidth) * 2 - 1;
      mouse.y = - (e.offsetY / e.path[0].clientHeight) * 2 + 1;
      this.raycaster.setFromCamera(mouse, this.camera);
      const r = this.raycaster.intersectObjects(this.model.children, false)[0];
      if (r) {
        this.hoveredPart = r.object;
        if (this.hoveredPart.name !== 'floor' && this.hoveredPart.name !== 'Other') {
          const aux = this.carMat.clone();
          (aux as MeshStandardMaterial).emissive = new Color(0xF1A227);
          (aux as MeshStandardMaterial).emissiveIntensity = 0.2;
          (this.hoveredPart as Mesh).material = aux;
          this.model.traverse(child => {
            if ((child as Mesh).material !== this.carMat) {
              switch (child.name) {
                case 'Other':
                  const otherMat = this.carMat.clone();
                  otherMat.transparent = false;
                  (child as Mesh).material = otherMat;
                  break;
                case 'floor':
                case this.hoveredPart.name:
                  break;
                default:
                  (child as Mesh).material = this.carMat;
                  break;
              }
            }
          });
        }
      } else {
        this.hoveredPart = null;
      }
    }

  }

  /**
   * Función encargada de comprobar si se ha hecho un click simple sobre un modelo 3D y la resalta en caso afirmativo
   * @param e Evento de click sobre el canvas
   */
  selectPart() {
    if (this.hoveredPart) { /* console.log('🚗'); */ this.highlightDamage(this.hoveredPart.name); } else { /* console.log('💩'); */ }
  }

  /**
   * Función encargada de restaurar el material del modelo 3D del coche en caso de detectarse un doble click sobre una zona vacía de
   * la escena 3D donde éste se encuentra
   * @param e Evento de doble click sobre el canvas
   */
  restoreCarMaterials() {
    if (!this.hoveredPart || this.hoveredPart.name === 'floor') {
      /* console.log('🧹'); */
      this.isZoomed = false;
      this.fitToScreen();
      this.model.traverse(child => {
        switch (child.name) {
          case 'Other':
            const otherMat = this.carMat.clone();
            otherMat.transparent = false;
            (child as Mesh).material = otherMat;
            break;
          case 'floor':
            break;
          default:
            (child as Mesh).material = this.carMat;
            break;
        }
      });
    }
  }

}


import { ChangeDetectorRef } from '@angular/core';
import { Component, Output, EventEmitter, Input, SimpleChanges, OnChanges, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventData } from 'src/app/core/models/event-data';
import { EventService } from 'src/app/core/services/event.service';


import { StorageService } from 'src/app/models/storage/storage.service';

@Component({
  selector: 'app-dgt-waiting-dialog',
  templateUrl: './dgt-waiting-dialog.component.html',
  styleUrls: ['./dgt-waiting-dialog.component.scss']
})
export class DgtWaitingDialogComponent {

  options = { autoHide: false };


 //  @Output() accept = new EventEmitter();
  errorMessage = '';
  notShowAgain = false;

  constructor(public dialogRef: MatDialogRef<DgtWaitingDialogComponent>,
              private storageService: StorageService,
              private eventService: EventService) {
  }


  onNoClick(): void {
    // console.log(this.notShowAgain)
    if (this.notShowAgain === true) {
      this.storageService.set('framesDialog','1');
      // console.log("Almacenado")
    }
    this.dialogRef.close();

  }

  onAcceptClick(): void {
    // console.log(this.notShowAgain)
    this.eventService.emit(new EventData('toggleFrameDownload', null));
    if (this.notShowAgain === true) {
      this.storageService.set('framesDialog', '1');
      // console.log("Almacenado")
    }
    this.dialogRef.close();

  }

  notShowMessage(event) {
    console.log(event.target.checked);
    if ( event.target.checked  === true) {
      this.notShowAgain = true;
    } else {
      this.notShowAgain = false;
    }
  }

}
import { Component, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lines',
  templateUrl: './lines.component.html',
  styleUrls: ['./lines.component.scss']
})
export class LinesComponent {

  public lineChartData:Array<any> = [
    { data: [65, 59, 80, 81, 12, 89, 15, 19, 50, 90, 56, 89], label: 'Nuevos', pointRadius: 0},
    { data: [28, 89, 40, 21, 78, 50, 56, 45, 2, 96, 78, 40], label: 'Reevaluación', pointRadius: 0},
  ];
  
  public lineChartLabels:Array<any> = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];

  public lineChartOptions:any =  {responsive: true, defaultFontColor: "#FFFFFF"};

  public lineChartColors: Array<any> = [
    {
      backgroudColor: 'rgba(255,255,255,1)',
      borderColor: 'rgba(255,255,255,1)',
      pointBackgroundColor: 'rgba(255,255,255,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(255,255,255,1)',
    },
    {
      backgroudColor: 'rgba(234,76,27,1)',
      borderColor: 'rgba(234,76,27,1)',
      pointBackgroundColor: 'rgba(234,76,27,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(234,76,27,1)',
    }
  ];

  public lineChartLegend: boolean = true;

  public lineChartType: string = 'line';

}

<div class="slider-item" (click)="click(image.project.id)" *ngIf="image.project">
  <div class="wrapper">
    <!-- <div class="opacity-div" [ngClass] = "{'failed-model': image.project.recordStatusID === 2 }"></div> -->
    <img [src]="src" />
    <!--<app-mark-unmark-model-as-favorite *ngIf="showMarkAsFavorite"
                                       [user]="user"
                                       [model]="image.project">
    </app-mark-unmark-model-as-favorite>-->
    <p class="model-name">{{image.project.licensePlate }}{{'_'}}{{image.project.id}}</p>
    <div class="line"></div>
    <div class="gallery-item-user-info-container">
      <div class="gallery-item-user-info-subcontainer">
        <img src="/assets/images/navbar/blue/eyesdcar-logo.svg" class="user-photo" />
        <div class="gallery-item-user-info-text">
          <p class="gallery-item-user-info-name"> {{ userName }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Record } from 'src/app/models/records/record';
import { RecordRemoteService } from 'src/app/core/services/remote/record/record-remote.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EventService } from 'src/app/core/services/event.service';
import { EventData } from 'src/app/core/models/event-data';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-record-data',
  templateUrl: './record-data.component.html',
  styleUrls: ['./record-data.component.scss'],
})

export class RecordDataComponent implements AfterViewInit, OnDestroy, OnInit {

  record: Record = new Record();
  recordID;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private recordRemoteService: RecordRemoteService,
    private activatedRoute: ActivatedRoute,
    private eventService: EventService
  ) { }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
    });

    this.recordID = this.activatedRoute.snapshot.params.code;

    this.subscriptions.push(this.eventService.on('viewerReady', () => {
      this.recordRemoteService.get(this.recordID).subscribe(record => {
        this.record = record;
        console.log('aqui');

        console.log(this.record);
        this.eventService.emit(new EventData('carTypeViewer', this.record.viewerType));
      });

    }));


  }

  ngAfterViewInit(): void {
    // this.createScene(null);
  }

  ngOnDestroy() {
    // if (this.frameId != null) {
    // cancelAnimationFrame(this.frameId);
    // }
  }


}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {clone} from 'lodash';
import {HttpCancelService} from '../../../core/services/api-access/http-cancel-service.service';
import { Record } from 'src/app/models/records/record';
import { ProjectThumbnail } from 'src/app/models/project-thumbnail';
import { LoaderService } from 'src/app/core/services/loader.service';
import {UserRemoteService} from '../../../core/services/remote/user/user.remote.service';
import {RecordRemoteService} from '../../../core/services/remote/record/record-remote.service';

@Component({
  selector: 'app-gallery-visualizator',
  templateUrl: './gallery-visualizator.component.html',
  styleUrls: ['./gallery-visualizator.component.scss']
})
export class GalleryVisualizatorComponent implements OnInit, OnChanges {

  departmentProjects: Record[];
  userProjectsLoaded = false;
  departmentProjectsLoaded = false;
  sliderImages: ProjectThumbnail[] = [];
  gridProjects: Record[] = [];
  searchImages: ProjectThumbnail[] = [];

  @Input() userProjects: Record[] = [];
  @Input() showSearch = false;
  @Input() showGoogleAdsense = false;
  @Input() galleryByCategoryWidthPercent: number;
  @Input() showGridImages = true;
  @Output() filterChange = new EventEmitter<string>();

  constructor(private recordRemoteService: RecordRemoteService,
              private loaderService: LoaderService,
              private httpCancelService: HttpCancelService,
              private userRemoteService: UserRemoteService) { }

  ngOnInit() {
    this.getUserProjects();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('sliderImages')) {
      this.loadSliderImages();
    }
  }

  private getUserProjects() {
    // this.userRemoteService.getRecords().subscribe(record => {
    //   this.loaderService.showLoader();
    //   this.userProjects = record;
    //   this.loadSliderImages();
    //   this.loaderService.hideLoader();
    // }, error => this.loaderService.hideLoader());
  }

  private loadSliderImages() {
    this.sliderImages = [];
    this.userProjects.forEach(sliderProject => {
      if (sliderProject) {
        // this.sliderImages.push({
        //   name: sliderProject.name,
        //   src: null,
        //   project: sliderProject
        // });

        /*this.recordRemoteService.getThumbailImage(sliderProject.id).subscribe(image => {
          const ImageSelected = this.sliderImages.find(Image => Image.record.id === image.id);
          if (ImageSelected) {
            ImageSelected.src = image.src;
          }
        });*/
      } else {
        this.sliderImages.push({
          name: null,
          src: null,
          project: null
        });
      }
    });
  }

  filterChangeEvent(value: string) {
      this.filterChange.emit(value);

      if (value.length < 4) {
        this.searchImages = [];
      } else {
        this.httpCancelService.cancelPendingRequests();
        let searchProjects = clone(this.userProjects);
        // searchProjects = searchProjects.filter(record => record.name.toLowerCase().includes(value.toLowerCase()));
        /*
        searchProjects = searchProjects.concat(clone(this.popularModels)
          .filter(model => model.name.toLowerCase().includes(value.toLowerCase()) ||
            (model.description && model.description.toLowerCase().includes(value.toLowerCase()))));
*/
        this.searchImages = [];

        // if (searchProjects.length > 0) {
        //   searchProjects.forEach(searchProject => {
        //     this.searchImages.push({
        //       name: searchProject.name,
        //       src: null,
        //       project: searchProject
        //     });
        //     /*this.recordRemoteService.getThumbailImage(searchProject.id).subscribe(image => {
        //       const ImageSelected = this.searchImages.find(Image => Image.record.id === searchProject.id);
        //       if (ImageSelected) {
        //         ImageSelected.src = image.src;
        //       }
        //     });*/
        //   });
        // }
      }
    }



  /*
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('sliderModels') && !changes.sliderModels.isFirstChange()) {
      this.loadSliderImages();
    }
    if (changes.hasOwnProperty('userModels') && !changes.userModels.isFirstChange() && this.userModels && !this.userModelsReady) {
      this.userModelsReady = true;
      this.initialLoadGrid();
    }
    if (changes.hasOwnProperty('popularModels') && !changes.popularModels.isFirstChange() &&
      this.popularModels && !this.popularModelsReady) {
      this.popularModelsReady = true;
      this.initialLoadGrid();
    }
  }

  categoryClick(category: ModelCategory) {
    this.gridModels = [];
    this.gridModels = clone(this.userModels)
      .filter(model => model.model_category_id ? model.model_category_id === category.id : category.id === 6);
    if (this.gridModels.length > 32) {
      this.gridModels = this.gridModels.slice(0, 32);
    } else {
      this.gridModels = this.gridModels.concat(this.fillWithPopularModels(32 - this.gridModels.length, category));
    }
    this.loadGridImages();
  }

  filterChangeEvent(value: string) {
    this.filterChange.emit(value);

    if (value.length < 4) {
      this.searchImages = [];
    } else {
      this.httpCancelService.cancelPendingRequests();
      let searchModels = clone(this.userModels);
      searchModels = searchModels.filter(model => model.name.toLowerCase().includes(value.toLowerCase()) ||
        (model.description && model.description.toLowerCase().includes(value.toLowerCase())));
      /*searchModels = searchModels.concat(clone(this.popularModels)
        .filter(model => model.name.toLowerCase().includes(value.toLowerCase()) ||
          (model.description && model.description.toLowerCase().includes(value.toLowerCase()))));

      this.searchImages = [];

      if (searchModels.length > 0) {
        searchModels.forEach(searchModel => {
          this.searchImages.push({
            name: searchModel.name,
            src: null,
            model: searchModel
          });
          this.modelService.getPreviewImage(searchModel.id).subscribe(image => {
            const ImageSelected = this.searchImages.find(Image => Image.model.id === searchModel.id);
            if (ImageSelected) {
              ImageSelected.src = image;
            }
          });
        });
      }
    }
  }

  private loadSliderImages() {
    this.sliderImages = [];
    this.sliderModels.forEach(sliderModel => {
      if (sliderModel) {
        this.sliderImages.push({
          name: sliderModel.name,
          src: null,
          model: sliderModel
        });
        this.modelService.getPreviewImage(sliderModel.id).subscribe(image => {
          const ImageSelected = this.sliderImages.find(Image => Image.model.id === sliderModel.id);
          if (ImageSelected) {
            ImageSelected.src = image;
          }
        });
      } /*else {
        this.sliderImages.push({
          name: null,
          src: null,
          model: null
        });
      }
    });
  }

  private initialLoadGrid() {
    if (this.popularModelsReady && this.userModelsReady) {
      if (this.userModels.length > 32) {
        this.gridModels = clone(this.userModels).slice(0, 32);
      } else {
        this.gridModels = clone(this.userModels);
        const fillModels = this.fillWithPopularModels(32 - this.gridModels.length);
        this.gridModels = fillModels ? this.gridModels.concat(fillModels) : this.gridModels ;
      }
      this.loadGridImages();
    }
  }

  private loadGridImages() {
    this.gridImages = [];

    this.gridModels.forEach(gridModel => {
      if (gridModel) {
        this.gridImages.push({
          name: gridModel.name,
          src: null,
          model: gridModel
        });
        this.modelService.getPreviewImage(gridModel.id).subscribe(image => {
          const ImageSelected = this.gridImages.find(Image => Image.model && Image.model.id === gridModel.id);
          if (ImageSelected) {
            ImageSelected.src = image;
          }
        });
      } else {
        this.gridImages.push({
          name: null,
          src: null,
          model: null
        });
      }
    });
  }

  private fillWithPopularModels(countModelsToFill: number, category?: ModelCategory): model[] {
    const fillModels = clone(this.popularModels);
    const result = [];
    if (category) {
      if (countModelsToFill > 0) {
          const models = fillModels.filter(model =>
            model.model_category_id ?
              model.model_category_id === category.id :
              category.id === 6)
            .splice(0, countModelsToFill - 1);
          if (models.length > 0) {
            result.push(null);
          }
          return result.concat(models);
      }
    } else {
      result.push(null);
      return result.concat(fillModels.splice(0, countModelsToFill - 1));
    }
  }

  private getSeparator() {

  }

  */
}

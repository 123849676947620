import { APP_INITIALIZER, NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UiModule } from './ui.module';
import { ExtendedModule, FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppLoadService } from './app-load.service';
import { AuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { IncidenceComponent } from './components/incidence/incidence.component';
import { PaymentOkComponent } from './components/payments/payment-ok/payment-ok.component';
import { PaypalComponent } from './components/payments/paypal/paypal.component';
import { HelpComponent } from './components/help/help.component';
import { CoreModule } from './core/core.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RestrictedPatternDirective } from './directives/pattern-validator/restricted-pattern.directive';
import { APP_BASE_HREF, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { APP_I18N_ENTRIES } from './config/app-i18n-entries';
import { PaymentKoComponent } from './components/payments/payment-ko/payment-ko.component';
import { InformationPageComponent } from './components/information-page/information-page.component';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { APP_ROUTES } from './config/app.routes';
import { CommonsModule } from './commons/commons.module';
import { I18nService } from './core/services/i18n.service';
import { SharedModule } from './shared/shared.module';
import { ProjectsProcessingItemComponent } from './components/projects-processing-slider/projects-processing-item/projects-processing-item.component';
import { ProjectsProcessingComponent } from './components/projects-processing/projects-processing.component';
import { ProjectsProcessingSliderComponent } from './components/projects-processing-slider/projects-processing-slider.component';
import { AppConfigLoaderService } from './services/config/app-config-loader.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CurrentProjectProcessingComponent } from './components/current-project-processing/current-project-processing.component';
import { CustomPreloadingStrategy } from './core/services/custom-preloading-strategy';
import { ErrorInterceptor } from './services/security/error-interceptor';

export function init_app(appLoadService: AppLoadService) {
  return () => appLoadService.initializeApp();
}

let config;
config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('2442240265789461')
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    IncidenceComponent,
    PaymentOkComponent,
    PaymentKoComponent,
    PaypalComponent,
    HelpComponent,
    InformationPageComponent,
    ProjectsProcessingComponent,
    ProjectsProcessingSliderComponent,
    ProjectsProcessingItemComponent,
    CurrentProjectProcessingComponent,
  ],
  imports: [
    CoreModule,
    SharedModule,
    CommonsModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    UiModule,
    BrowserAnimationsModule,
    ExtendedModule,
    FlexLayoutModule,
    RouterModule.forRoot(APP_ROUTES, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: CustomPreloadingStrategy,
      enableTracing: false
    }),
    BrowserModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  exports: [
    RestrictedPatternDirective,
    MatInputModule,
    MatFormFieldModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [AppConfigLoaderService],
      useFactory: apiConfigProvider,
      multi: true,
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    AppLoadService,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppLoadService], multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CustomPreloadingStrategy
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private i18nService: I18nService) {
    i18nService.addI18nEntriesObject(APP_I18N_ENTRIES);
  }
}

export function apiConfigProvider(appConfigLoaderService: AppConfigLoaderService) {
  return () => appConfigLoaderService.loadConfiguration();
}

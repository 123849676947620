import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AcceptedLogin } from '../../models/security/accepted-login';
import { Credential } from '../../models/security/credential';
import { RestClientService } from '../../core/services/api-access/rest-client.service';
import { BaseProfile } from '../../models/security/base-profile';
import { AuthService } from 'angularx-social-login';
import { SocialCredential } from 'src/app/models/security/socialcredential';

@Injectable({ providedIn: 'root' })
export class LoginRemoteService {
  constructor(private restClientService: RestClientService, private authService: AuthService) { }

  public login<T extends BaseProfile>(credential: Credential): Observable<AcceptedLogin<T>> {
    return this.restClientService.post('login', credential);
  }

  public logout(): Observable<any> {
    this.socialSignOut();
    console.log('logout login-remote');
    return this.restClientService.get('logout', null);
  }

  private socialSignOut() {
    // this.authService.signOut();
  }
  public loginWithToken<T extends BaseProfile>(token: string): Observable<AcceptedLogin<T>> {
    return this.restClientService.get(`/user-by-token/${token}`);
  }
  public socialLogin<T extends BaseProfile>(credential: SocialCredential): Observable<AcceptedLogin<T>> {
    return this.restClientService.post('/sociallogin', credential);
  }
}

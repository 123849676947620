import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectThumbnail } from 'src/app/models/project-thumbnail';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import { User } from 'src/app/shared/models/user/user';

@Component({
  selector: 'app-gallery-item',
  templateUrl: './gallery-item.component.html',
  styleUrls: ['./gallery-item.component.scss']
})
export class GalleryItemComponent implements OnInit {

  src = '';
  avatarSrc = '';
  user: User;
  userName = '';

  @Input() image: ProjectThumbnail;
  @Output() itemClick = new EventEmitter<any>();

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
    if (this.image) {
      this.authenticationService.get().subscribe(userLogged => {
        this.user = userLogged;
        this.avatarSrc = this.user.avatar;
        if (this.user.profile.surname) {
          this.userName = this.user.profile.name + ' ' + this.user.profile.surname;
        } else {
          this.userName = this.user.profile.name;
        }
      });

      if (!this.image.project.previewURL) {
        this.setDefaultImage();
      } else {
        this.src = this.image.project.previewURL;
      }

      if (this.avatarSrc) {
        this.setDefaultAvatar();
      }
    }
  }

  click(code: number) {
    this.itemClick.emit(this.image.project.id);
  }

  setDefaultImage() {
    this.src = '/assets/images/blue-background.png';
  }

  setDefaultAvatar() {
    this.avatarSrc = '/assets/images/gallery-item-default-avatar.png';
  }
}

<div class="flex">
  <div class="flex-item">
    <div style="display: block;">
    <canvas baseChart width="400" height="150"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                ></canvas>
    </div>
  </div>
</div>



<div class="view-project-container">
  <div class="view-project-left-column">
    <app-record-data></app-record-data>
  </div>
  <div class="view-project-center-column">
    <div class="display-hits">
      <img src="../../../../assets/images/viewproject/eye.svg" class="eye">
      <p class="hits-text">Visualización de golpes:</p>
    </div>
    <div class="viewer">
      <app-viewer></app-viewer>
    </div>
    <div class="rotate-model">
      <img src="../../../../assets/images/viewproject/arrow-left.svg" class="arrow-left" (click)="highlightDamage()">
      <p>{{'rotateModel' | i18n}}</p>
    </div>
  </div>
  <div class="view-project-right-column">
    <app-damages-selector></app-damages-selector>
    <app-damages-frames></app-damages-frames>
  </div>
</div>

export const COMMONS_I18N_ENTRIES = {
  gallery: {
    en: 'Gallery',
    es: 'Galería',
  },
  newProject: {
    en: 'New Record',
    es: 'Nuevo Expediente',
  },
  proyectGallery: {
    en: 'Projects Gallery',
    es: 'Galería de expedientes',
  },
  loading: {
    en: 'loading',
    es: 'cargando',
  },
  account: {
    es: 'Cuenta',
    en: 'Account'
  },
  company: {
    en: 'company',
    es: 'empresa',
  },
  professional: {
    en: 'professional',
    es: 'profesional'
  },
  project_management: {
    en: 'Project Management',
    es: 'Mi cuenta'
  },
  projectsInProcess: {
    en: 'Projects in process',
    es: 'Proyectos en proceso'
  },
  user: {
    en: 'User',
    es: 'Usuario',
  },
  viewMore: {
    en: 'view more',
    es: 'ver más',
  },
  nameRequired: {
    en: 'Name is required',
    es: 'El nombre es obligatorio',
  },
  name: {
    en: 'Name',
    es: 'Nombre'
  },
  emailRequired: {
    en: 'Email is required',
    es: 'El correo electrónico es obligatorio',
  },
  surname: {
    en: 'surname',
    es: 'Apellidos'
  },
  save: {
    en: 'save',
    es: 'guardar'
  },
  cancel: {
    en: 'Cancel',
    es: 'Cancelar'
  },
  continue: {
    en: 'Continue',
    es: 'Continuar'
  },
  models: {
    en: 'models',
    es: 'modelos'
  },
  badEmail: {
    en: 'Bad email',
    es: 'Correo erróneo',
  },
  minPasswordError: {
    en: 'Password must be more than 4 characters',
    es: 'La contraseña tiene que tener más de 4 caracteres',
  },
  notEqualPasswords: {
    en: 'Passwords are not equals',
    es: 'La contraseñas no coinciden',
  },
  followUsIn: {
    en: 'Follow us in',
    es: 'Síguenos en',
  },
  termsOfUse: {
    en: 'Terms of use',
    es: 'Términos de uso'
  },
  privacy: {
    en: 'Privacy',
    es: 'Privacidad',
  },
  privacyPolicy: {
    en: 'Privacy Policy',
    es: 'Política de Privacidad',
  },
  cookiePolicies: {
    en: 'Cookie policies',
    es: 'Políticas de cookies'
  },
  downloadApp: {
    en: 'Download App',
    es: 'Descarga la aplicación'
  },
  delete: {
    en: 'Delete',
    es: 'Eliminar'
  },
  footerText: {
    en: ' eyesNroad.com is an eCapture3D product. Each of the projects generated in eyesNroad.com are property of the users',
    es: ' eyesDcar.com es un producto de ecaptureDtech. Cada uno de los proyectos generados en eyesDcar.com son propiedad de sus usuarios.'
  },
  searcher: {
    en: 'Searcher',
    es: 'Buscador'
  },
  searcherExit: {
    en: 'Exit the searcher',
    es: 'Salir del buscador'
  },
  noResultsFound: {
    en: 'No results found for',
    es: 'No se han encontrado resultados para'
  },
  then: {
    en: 'Then',
    es: 'A continuación'
  },
  mostPopulars3dModels: {
    en: 'Most populars 3D models',
    es: 'Modelos 3d Más Populares'
  },
  searchResultText: {
    en: 'Search result of',
    es: 'Resultado de la búsqueda de'
  },
  new: {
    en: 'New',
    es: 'Nuevo'
  },
  new2: {
    en: 'New',
    es: 'Crea un nuevo Expediente'
  },
  record_number: {
    en: 'Record nº:',
    es: 'Nº de expediente:'
  },
  search_text: {
    en: '(Record nº or Policy)',
    es: '(Nº de expediente o Póliza)'
  },
  new_records: {
    en: 'NEW RECORDS',
    es: 'NUEVOS EXPEDIENTES'
  },
  reprocessing_records: {
    en: 'REPROCESSING RECORDS',
    es: 'REEVALUACÓN DE EXPEDIENTES'
  },
  sending_links:{
    en: 'Sending Links',
    es: 'Envío de enlaces' 
  },
  download_damage_assessment:{
    en: 'download damage assessment',
    es: 'descargas valoración de daños'
  }
};

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { log } from 'console';
import { EventData } from 'src/app/core/models/event-data';
import { EventService } from 'src/app/core/services/event.service';
import { RecordFilesRemoteService } from 'src/app/core/services/remote/record/record-files-remote.service';
import { Damage } from 'src/app/models/damage';
import { ViewFileDialogComponent } from 'src/app/new-project/components/dialogs/view-file-dialog/view-file-dialog.component';
import { DamageRemoteService } from 'src/app/services/common/damage/damage-remote.service';

@Component({
  selector: 'app-damages-selector',
  templateUrl: './damages-selector.component.html',
  styleUrls: ['./damages-selector.component.scss']
})
export class DamagesSelectorComponent implements OnInit {

  damages;
  framesDamages;
  showFrames;
  isDown = false;
  startX: number;
  scrollLeft: any;
  start;
  end;
  clickItem: any;
  damageActive = new Set<Damage>();
  buttonRight = document.getElementById('damage');
  buttonLeft = document.getElementById('damage');
  displayedColumns: string[] = ['select', 'part', 'damage-type', 'surface'];
  totalCost: number = 0;
  estimations: any;

  @ViewChild('slider', { static: true }) slider: ElementRef;

  constructor(
    private damageRemoteService: DamageRemoteService,
    private recordFilesRemoteService: RecordFilesRemoteService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    // this.damageRemoteService.getAllDamages(this.activatedRoute.snapshot.params.code).subscribe(damagesReceived => {
    //   // this.damages = damagesReceived;
    // });

    this.recordFilesRemoteService.getFramesOfVideosSelected(this.activatedRoute.snapshot.params.code).subscribe((frames: any) => {
      if (frames.length > 0) {
        this.framesDamages = frames.filter(url => url.includes(".jpg"));;
        this.showFrames = this.framesDamages;
      }
    });

    this.damageRemoteService.getEstimation(this.activatedRoute.snapshot.params.code).subscribe(estimations => {
      this.damages = this.estimations = estimations;
      this.damages.damages.map(element => {
        element.damage_name = this.parserCarDamage(element.car_damage_type_id);
        element.part_name = this.parserCarPart(element.car_part_id);
        return element;
      })
    });
  }


  mouseDown(event) {
    this.isDown = true;
    this.slider.nativeElement.classList.add('active');
    this.startX = event.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;
    this.start = +new Date();
  }

  mouseUp(event) {
    this.isDown = false;
    this.slider.nativeElement.classList.remove('active');
    this.end = +new Date();
    const diff = this.end - this.start;
    this.clickItem.emit(diff < 100);
  }

  mouseMove(event) {
    if (!this.isDown) { return; }
    event.preventDefault();
    const x = event.pageX - this.slider.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2; // scroll-fast
    this.slider.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  openFile(src: string) {
    if (!this.isDown) {
      let dialogRef = this.dialog.open(ViewFileDialogComponent, {
        data: { damage: src },
        panelClass: 'ViewFileDialogComponent'
      });
    }
  }

  roundNumber(_number: number) {
    return Math.round(_number);
  }

  parserCarDamage(id: number) {
    switch (id) {
      case 1:
        return 'damages_Data30';
      case 2:
        return 'damages_Data31';
      case 3:
        return 'damages_Data32';
      case 4:
        return 'damages_Data33';
      default:
        break;
    }
  }

  parserCarPart(id: number) {
    return 'damages_Data' + id;
  }

  activateDamage(damage: Damage) {
    if (damage.frames) {
      const framesString = damage.frames;
      const match = framesString.match(/"frames":\s*\["([^"]+\.jpg)"\]/);
      if (match && match.length > 1) {
        const fileName = match[1];
        const urls = this.framesDamages.filter((frameUrl: string) => frameUrl.includes(fileName));
        if (urls.length > 0) {
          this.showFrames = urls;
        } else {
          this.showFrames = this.framesDamages;
        }
      }
    }

    if (this.damageActive.has(damage)) {
      this.damageActive.delete(damage);
      this.showFrames = this.framesDamages;
    } else {
      this.damageActive.clear();
      this.damageActive.add(damage);
    }

    this.eventService.emit(new EventData('activeDamage', this.damageActive));
  }

  moveLeft() {
    document.getElementById('sliders').scrollLeft -= 50;
  }

  moveRight() {
    document.getElementById('sliders').scrollLeft += 50;
  }

  // Función para activar/desactivar el checkbox de una fila
  toggleCheckbox(row: any, event): void {
    if (event.checked) {
      this.totalCost += row.prices.totalPrice;
    } else {
      this.totalCost -= row.prices.totalPrice;
    }
    this.totalCost = parseFloat(this.totalCost.toFixed(2));
  }

  // Función para verificar si una fila está seleccionada
  isChecked(row: any): boolean {
    return null;
  }
}

<div mat-dialog-content>
  <div class="header-container" *ngIf="!showMatSelect">
    <div class="head-links">
      <a [ngClass]="{ 'active': step == 1 }" (click)="goTo(1)">{{'capture_tips_1' | i18n}}</a>
      <a [ngClass]="{ 'active': step == 2 }" (click)="goTo(2)">{{'capture_tips_2' | i18n}}</a>
      <a [ngClass]="{ 'active': step == 3 }" (click)="goTo(3)">{{'capture_tips_3' | i18n}}</a>
      <a [ngClass]="{ 'active': step == 4 }" (click)="goTo(4)">{{'capture_tips_4' | i18n}}</a>
      <a [ngClass]="{ 'active': step == 5 }" (click)="goTo(5)">{{'capture_tips_5' | i18n}}</a>
    </div>
    <a (click)="onNoClick()"><img src="assets/images/modal-close-ico.png"></a>
  </div>
  <div class="header-container" *ngIf="showMatSelect">
    <mat-form-field>
      <mat-select [(value)]="step" (selectionChange)="goTo($event.value)">
        <mat-option class="opciones-menu-responsive" [value]="1">{{'capture_tips_1' | i18n}}</mat-option>
        <mat-option class="opciones-menu-responsive" [value]="2">{{'capture_tips_2' | i18n}}</mat-option>
        <mat-option class="opciones-menu-responsive" [value]="3">{{'capture_tips_3' | i18n}}</mat-option>
        <mat-option class="opciones-menu-responsive" [value]="4">{{'capture_tips_4' | i18n}}</mat-option>
        <mat-option class="opciones-menu-responsive" [value]="5">{{'capture_tips_5' | i18n}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="dialog-container">
    <a (click)="leftArrowClick()"><img src="assets/images/viewproject/left-arrow-ico.png"></a>
    <div class="slider-item slider-item-1" *ngIf="step == 1">
      <div class="tip-content">
        <p class="step-title">{{'capture_tips_1' | i18n}}</p>
        <p class="step-text">{{'capture_tips_1_sub_1' | i18n}}<br>{{'capture_tips_1_sub_2' | i18n}}</p>
        <div class="step-content">
          <div class="step-content-column">
            <img src="/assets/images/profile/tips/tips1-1.png">
          </div>
          <div class="step-content-column">
            <img src="/assets/images/profile/tips/tips1-2.png">
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="line"></div>
        <div class="step-content">
          <div class="step-content-row-1">
            <img src="/assets/images/profile/tips/tips-view-1.png">
            <p>{{'capture_tips_windshield_view' | i18n}}</p>
          </div>
          <div class="step-content-row">
            <img src="/assets/images/profile/tips/tips-view-2.png">
            <p>{{'capture_tips_camera_view' | i18n}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="slider-item slider-item-2" *ngIf="step == 2">
      <div class="tip-content">
        <p class="step-title">{{'capture_tips_2' | i18n}}</p>
        <p class="step-text">{{'capture_tips_2_sub_1' | i18n}} {{'capture_tips_2_sub_2' | i18n}}</p>
        <div class="step-content">
          <div class="step-content-column">
            <img src="/assets/images/profile/tips/tips2-1.png">
          </div>
          <div class="step-content-column">
            <img src="/assets/images/profile/tips/tips2-2.png">
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="line"></div>
        <div class="step-content">
          <div class="step-content-row-1">
            <img src="/assets/images/profile/tips/tips-view-1.png">
            <p>{{'capture_tips_windshield_view' | i18n}}</p>
          </div>
          <div class="step-content-row">
            <img src="/assets/images/profile/tips/tips-view-2.png">
            <p>{{'capture_tips_camera_view' | i18n}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="slider-item slider-item-3" *ngIf="step == 3">
      <div class="tip-content">
        <p class="step-title">{{'capture_tips_3' | i18n}}</p>
        <p class="step-text">{{'capture_tips_3_sub_1' | i18n}} {{'capture_tips_3_sub_2' | i18n}}</p>
        <div class="step-content">
          <div class="step-content-column">
            <img src="/assets/images/profile/tips/tips3-1.png">
          </div>
          <div class="step-content-column">
            <img src="/assets/images/profile/tips/tips3-2.png">
          </div>
        </div>
        <div class="step-content-3">
          <div class="step-content-column">
            <img src="/assets/images/profile/tips/tips3-3.png">
          </div>
          <div class="step-content-column">
            <img src="/assets/images/profile/tips/tips3-4.png">
          </div>
        </div>
        <div class="step-content-3-text">
          <p class="step-content-3-text-p">{{'capture_tips_3_sub_3' | i18n}}</p>
        </div>
      </div>
      <div class="footer">
        <div class="line"></div>
        <div class="step-content">
          <div class="step-content-row-1">
            <img src="/assets/images/profile/tips/tips-view-1.png">
            <p>{{'capture_tips_windshield_view' | i18n}}</p>
          </div>
          <div class="step-content-row">
            <img src="/assets/images/profile/tips/tips-view-2.png">
            <p>{{'capture_tips_camera_view' | i18n}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="slider-item slider-item-4" *ngIf="step == 4">
      <div class="tip-content">
        <p class="step-title">{{'capture_tips_4' | i18n}}</p>
        <p class="step-text">{{'capture_tips_4_sub_1' | i18n}} {{'capture_tips_4_sub_2' | i18n}}</p>
        <div class="step-content">
          <div class="step-content-column">
            <img src="/assets/images/profile/tips/tips4-1.png">
          </div>
          <div class="step-content-column">
            <img src="/assets/images/profile/tips/tips4-2.png">
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="line"></div>
        <div class="step-content">
          <div class="step-content-row-1">
            <img src="/assets/images/profile/tips/tips-view-1.png">
            <p>{{'capture_tips_windshield_view' | i18n}}</p>
          </div>
          <div class="step-content-row">
            <img src="/assets/images/profile/tips/tips-view-2.png">
            <p>{{'capture_tips_camera_view' | i18n}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="slider-item slider-item-5" *ngIf="step == 5">
      <div class="tip-content">
        <p class="step-title">{{'capture_tips_5' | i18n}}</p>
        <p class="step-text">{{'capture_tips_5_sub_1' | i18n}} {{'capture_tips_5_sub_2' | i18n}}</p>
        <div class="step-content">
          <div class="step-content-column">
            <img src="/assets/images/profile/tips/tips5.png">
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="line"></div>
        <div class="step-content">
          <div class="step-content-row-1">
            <img src="/assets/images/profile/tips/tips-view-1.png">
            <p>{{'capture_tips_windshield_view' | i18n}}</p>
          </div>
          <div class="step-content-row">
            <img src="/assets/images/profile/tips/tips-view-2.png">
            <p>{{'capture_tips_camera_view' | i18n}}</p>
          </div>
        </div>
      </div>
    </div>
    <a (click)="rightArrowClick()"><img src="assets/images/viewproject/right-arrow-ico.png"></a>
  </div>
</div>
<div mat-dialog-actions>
  <span class="dot" [ngClass]="{ 'active': step == 1 }"></span>
  <span class="dot" [ngClass]="{ 'active': step == 2 }"></span>
  <span class="dot" [ngClass]="{ 'active': step == 3 }"></span>
  <span class="dot" [ngClass]="{ 'active': step == 4 }"></span>
  <span class="dot" [ngClass]="{ 'active': step == 5 }"></span>
</div>


<body style="padding: 3%;">
    <!-- Primero debería ir el leer, editar y borrar -->
    <table class="table table-trapped">
        <thead>
            <tr>
                <th>Id</th>
                <th>Modelo</th>
                <th>Fecha</th>
                <th>Cierre</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of incidencias">
                <td>#{{data.id}}</td>
                <td>Model #{{data.model_id}}</td>
                <td>{{data.open_date}}</td>
                <td>{{data.close_date}}</td>
                <td>
                    <button class="btn btn-primary m-1" (click)="getIncidence(data.id)">Ver</button>
                    <button class="btn btn-warning m-1" (click)="closeIncidence(data)">Cerrar</button>
                    <button class="btn btn-danger m-1" (click)="deleteIncidence(data.id)">Eliminar</button>
                </td>
            </tr>
        </tbody>

    </table>
    <!-- Un botón para desplegar la creación de una nueva incidencia -->
    <button class="btn btn-primary block full-width m-b" style="margin-bottom: 1%;" (click)="toggleCrear()">Crear nueva
        incidencia</button>
    <!-- Crear una nueva incidencia -->
    <div class="container" *ngIf="!hideCrear">
        <form (ngSubmit)="crearIncidencia()" [formGroup]="form" class="m-t" novalidate role="form">
            <div class="form-group">
                <input [formControl]="form.controls.content" class="form-control" app-i18n="content"
                    app-i18n-attr="placeholder" name="content" required type="text"
                    placeholder="Descripción de la incidencia">
            </div>
            <button [disabled]="form.controls.content.value.length==0" class="btn btn-primary block full-width m-b" app-i18n="accept" type="submit">Enviar</button>
        </form>
    </div>
    <div class="container" *ngIf="showMensajes">
        <button class="btn btn-secondary" (click)="toggleMensajes()">Ocultar</button>
        <h2>Mensajes de la incidencia #{{incidenciaMessages.id}}</h2>
        <div *ngFor="let message of messages" [class.responseStyle]="message.user_id==userLogged.id">
            <b>User #{{message.user_id}}:</b><br />
            {{message.content}}<br />
            <em>{{message.time}}</em><br />
            <hr>
        </div>
        <div>
            <form (ngSubmit)="crearMensaje(incidenciaMessages.id)" [formGroup]="form2" class="m-t" novalidate role="form2">
                <div *ngIf="incidenciaMessages.close_date==null" class="form-group">
                    <input [formControl]="form2.controls.content" class="form-control" app-i18n="content"
                        app-i18n-attr="placeholder" name="content" required type="text" placeholder="Enviar mensaje">
                </div>
                <button *ngIf="incidenciaMessages.close_date==null" [disabled]="form2.controls.content.value.length==0" class="btn btn-primary block full-width m-b"
                    app-i18n="accept" type="submit">Enviar</button>
                <div *ngIf="incidenciaMessages.close_date!=null" class="alert alert-danger">
                    La incidencia está cerrada
                </div>
            </form>
        </div>
    </div>
</body>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { Rate } from 'src/app/shared/models/user-rate/rate';
import {RestClientService} from '../api-access/rest-client.service';

@Injectable({
    providedIn: 'root'
})
export class RateRemoteService {

    private URL = '/rates';

    constructor(private restClientService: RestClientService) { }

    public search(): Observable<Rate[]> {
        return this.restClientService.get(`${this.URL}`);
    }

    public get(id: number): Observable<Rate> {
      return this.restClientService.get(`${this.URL}/${id}`);
    }
}

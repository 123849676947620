import { Injectable } from '@angular/core';
import { RestClientService } from 'src/app/core/services/api-access/rest-client.service';
import { Observable } from 'rxjs';
import { Record } from 'src/app/models/records/record';
import { Road } from '../../../../models/road';

@Injectable({
  providedIn: 'root'
})
export class RecordRemoteService {

  private URL = '/records';
  private sharedURL = '/shared-records';

  constructor(private restClientService: RestClientService) { }

  public getRoads(recordId: number): Observable<Road[]> {
    return this.restClientService.get(`${this.URL}/${recordId}/roads`);
  }

  public getRecords(userId: number): Observable<Record[]> {
    return this.restClientService.get(`${this.URL}/user/${userId}`);
  }
  public getAllRecords(userId: number): Observable<Record[]> {
    return this.restClientService.get(`${this.URL}/user/${userId}`);
  }

  create(record: Record): Observable<Record> {
    return this.restClientService.post(this.URL, record);
  }

  get(id: number): Observable<Record> {
    return this.restClientService.get(`${this.URL}/complete/${id}`);
  }

  createSharedRecord(data): Observable<any> {
    return this.restClientService.post(`${this.URL}/${data.id}/${this.sharedURL}`, data);
  }

  public getProcessingRecords(): Observable<Record[]> {
    return this.restClientService.get(`${this.URL}/processing`);
  }

  public getLastRecords(): Observable<Record[]> {
    return this.restClientService.get(`${this.URL}/by/last`, null);
  }

  public getDepartmentRecords(): Observable<Record[]> {
    return this.restClientService.get(`${this.URL}/by/departments`, null);
  }

  public save(record: Record): Observable<Record> {
    if (record.id !== undefined && record.id !== null) {
      return this.restClientService.put(`${this.URL}/${record.id}`, record);
    }
    return this.restClientService.post(`${this.URL}`, record);
  }

  public createQueue(record: Record): Observable<any> {
    return this.restClientService.post(`${this.URL}/queue/${record.id}`, null);
  }
}

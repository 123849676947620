import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RestrictedPatternDirective} from './directives/pattern-validator/restricted-pattern.directive';

@NgModule({
  declarations: [
    RestrictedPatternDirective,
  ],
  exports: [
    RestrictedPatternDirective,
  ],
  providers: [],
  imports: [
    FormsModule,
  ]
})
export class UiModule { }

import {Route} from '@angular/router';
import {HomeComponent} from '../components/home/home.component';
import {PermissionGuard} from '../services/security/guards/auth.guard';
import {InformationPageComponent} from '../components/information-page/information-page.component';
import {LayoutComponent} from '../commons/components/layout/layout.component';
import { ProjectsProcessingComponent } from '../components/projects-processing/projects-processing.component';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule),
    data: { preload: true }
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeComponent,
        // canActivate: [PermissionGuard]  //TODO: David Carlos Marcos, cuando funcione el cors de la api descomenta esta línea
      },
      {
        path: 'new-project',
        loadChildren: () => import('../new-project/new-project.module').then(m => m.NewProjectModule)
      },
      {
        path: 'project-management',
        loadChildren: () => import('../project-management/project-management.module').then(m => m.ProjectManagementModule)
      },
      {
        path: 'projects-processing',
        component: ProjectsProcessingComponent,
        //canActivate: [PermissionGuard] //TODO: David Carlos Marcos, cuando funcione el cors de la api descomenta esta línea
      },
      {
        path: 'gallery',
        loadChildren: () => import('../gallery/gallery.module').then(m => m.GalleryModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'projects/:code',
        loadChildren: () => import('../view-project/view-project.module').then(m => m.ViewProjectModule)
      },
      {
        path: 'terms-of-use',
        component: InformationPageComponent,
        // canActivate: [PermissionGuard]//TODO: David Carlos Marcos, cuando funcione el cors de la api descomenta esta línea
      },
      {
        path: 'privacy-policy',
        component: InformationPageComponent,
        // canActivate: [PermissionGuard]//TODO: David Carlos Marcos, cuando funcione el cors de la api descomenta esta línea
      },
      {
        path: 'cookie-policy',
        component: InformationPageComponent,
        // canActivate: [PermissionGuard]//TODO: David Carlos Marcos, cuando funcione el cors de la api descomenta esta línea
      },
    ]
  },
  {
    path: '**', redirectTo: 'home'
  }
];

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Record} from '../../../../app/models/records/record';
import {interval, Subscription} from 'rxjs';
import { RecordRemoteService } from 'src/app/core/services/remote/record/record-remote.service';

@Component({
  selector: 'app-projects-processing-item',
  templateUrl: './projects-processing-item.component.html',
  styleUrls: ['./projects-processing-item.component.scss']
})
export class ProjectsProcessingItemComponent implements OnInit, OnDestroy {

  avatarSrc = '';
  subscription: Subscription = null;

  @Input() record: Record;
  @Output() itemClick = new EventEmitter<Record>();
  @Output() projectRefresh = new EventEmitter<Record>();

  constructor(private recordRemoteService: RecordRemoteService) { }

  ngOnInit() {
    if (this.record) {
      this.avatarSrc = this.record.avatarURL;
    }
    if (/* this.record.completion !== 100 ||
      (this.record.project_status_id !== 2 && this.record.project_status_id !== 4) */false) { // TODO Quitar esto cuando esté listo
      this.refreshProcessingRecords();
      const subscriptionObservable = interval(10000);
      this.subscription = subscriptionObservable.subscribe(() => this.refreshProcessingRecords());
    }
  }

  click(record: Record) {
    this.itemClick.emit(record);
  }

  setDefaultImage(event) {
    event.target.src = '/assets/images/blue-background.png';
  }

  setDefaultAvatar(event) {
    event.target.src = '/assets/images/gallery-item-default-avatar.png';
  }

  private refreshProcessingRecords() {
    this.recordRemoteService.get(this.record.id).subscribe(project => {
      const userName = this.record.userName;
      this.record = project;
      this.record.userName = userName;
      this.projectRefresh.emit(project);
      if (/* this.record.completion === 100 &&
        (this.record.project_status_id === 2 || this.record.project_status_id === 4) */false) { // TODO Quitar esto cuando esté listo
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

<div mat-dialog-content>
  <div class="header-container">
    <p></p>
    <a (click)="onNoClick()"><img src="assets/images/modal-close-ico.png" alt=""></a>
  </div>

  <div class="customer-support-container">
    <img class="header-ico" src="assets/images/profile/customer-support/customer-support-ico.png">
    <p>{{'customer_help_1' | i18n}}</p>
    <p class="mail"><a href="mailto:customercare@ecapture3d.com">customercare@ecapture3d.com</a></p>
    <p><span class="blue">eyes</span><span class="green">N</span><span class="blue">road</span> {{'customer_help_2' | i18n}}<br> {{'customer_help_3' | i18n}}</p>
    <div class="line"></div>
    <p>{{'customer_help_4' | i18n}}</p>
    <a class="button">{{'openOnlineChat' | i18n}}</a>
  </div>
</div>


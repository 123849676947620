<div class="information-container">
  <h1>{{currentOption.title}}</h1>
  <div class="information-content-container">
    <div class="information-menu">
      <div class="information-menu-item" *ngFor="let option of options; let last = last">
        <div class="information-menu-item-content" routerLink="{{option.url}}">
          <img class = "d-md-block d-lg-none" src="{{option.imageWhite}}">
          <img class = "d-none d-lg-block" src="{{option.imageBlue}}">
          <p [ngClass]="{'active': option.url === currentOption.url}">{{option.title}}</p>
        </div>
        <div *ngIf="!last" class="line"></div>
      </div>
    </div>
    <div class="information-content" [innerHTML]="text"></div>
  </div>
</div>
